/* eslint-disable */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import "./style.css";
import CompanyInfo from "./components/CompanyInfo";
import CompanyHours from "./components/CompanyHours";
import CompanyMembers from "./components/CompanyMembers";
import useCompanyProfileMain from "./services/useCompanyProfileMain";
import CompanyProfileLoading from "./components/CompanyProfileLoading";
import { useSelector } from "react-redux";

const CompanyProfileMain = ({ isDark, placement }) => {
  // data is managing in the business layer in services folder
  const { userProfile } = useSelector((state) => state?.profile);
  const { companyProfile, isCompanyProfileLoading } = useCompanyProfileMain();

  return (
    <div
      data-theme={isDark}
      className={`company-profile-main-wrapper ${
        placement === "profile" ? "p-0" : ""
      }`}
    >
      {isCompanyProfileLoading ? (
        <CompanyProfileLoading isDark={isDark} />
      ) : (
        <>
          <CompanyInfo isDark={isDark} companyProfile={companyProfile} placement={placement}/>
          <CompanyHours isDark={isDark} companyProfile={companyProfile} />
          <CompanyMembers
            isDark={isDark}
            members={companyProfile?.companyUsers}
            user={userProfile}
          />
        </>
      )}
    </div>
  );
};

export default CompanyProfileMain;

/* eslint-disable */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import API from "../../services/ClientApi";
import { ShimmerButton } from "react-shimmer-effects";
import { Icon } from "@iconify/react/dist/iconify.js";

function FAQMain({ props }) {
  const [load, setLoad] = useState(false);
  const [faqsData, setFaqsData] = useState(null);
  useEffect(() => {
    const getAllFaqs = async () => {
      setLoad(true);
      try {
        const faqsResponse = await API.get("users/faqs");
        setFaqsData(faqsResponse?.data?.data?.faqs);
        setLoad(false);
      } catch (error) {
        setLoad(false);
      } finally {
        setLoad(false);
      }
    };

    getAllFaqs();
  }, []);

  return (
    <div>
      {!load ? (
        <div className="d-flex w-100 flex-column mt-4 gap-4">
          {faqsData &&
            faqsData.map((item, index) => {
              return (
                <FAQCard
                  id={index}
                  question={item.question}
                  answers={item.answers}
                />
              );
            })}
        </div>
      ) : (
        <div className="w-100 d-flex flex-column justify-content-center global-spinner mt-5">
          <div className="w-100 mb-3 d-flex justify-content-between single-row-shimmer-in-form-full">
            <ShimmerButton size="sm" />
          </div>
          <div className="w-100 mb-3 d-flex justify-content-between single-row-shimmer-in-form-full">
            <ShimmerButton size="sm" />
          </div>
          <div className="w-100 mb-3 d-flex justify-content-between single-row-shimmer-in-form-full">
            <ShimmerButton size="sm" />
          </div>
          <div className="w-100 mb-3 d-flex justify-content-between single-row-shimmer-in-form-full">
            <ShimmerButton size="sm" />
          </div>
        </div>
      )}
    </div>
  );
}

export default FAQMain;

const FAQCard = ({ id, question, answers }) => {
  return (
    <Accordion defaultActiveKey="0" key={id}>
      <Accordion.Item className="w-full ">
        <Accordion.Header>
          {question} <Icon icon="bi:chevron-down" className="custom-icon" />
        </Accordion.Header>
        <Accordion.Body>{answers}</Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

/* eslint-disable */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import "./styles/advanceSearchModal.css";
import close from "../../../../assets/close.svg";
import closeFilter from "../../../../assets/close-filter.svg";
import { capitalizeFirstLetter } from "./../../../../helper/capitalizeFirstLetter";
import { MultiSelect } from "react-multi-select-component";
import { useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import Flatpickr from "react-flatpickr";
import { Icon } from "@iconify/react";

function AdvanceSearchModalMain({
  showModal,
  setShowModal,
  isDark,
  data,
  filters,
  setFilters,
}) {
  const datePickerRef = useRef(null);
  const [allDepartments, setAllDepartments] = useState([]);
  const [allRoles, setAllRoles] = useState([]);
  const [allUserNames, setAllUserNames] = useState([]);
  const [allEmails, setAllEmails] = useState([]);
  const [joinedDate, setJoinedDate] = useState(null);

  const { userInviteeList } = useSelector(
    (state) => state.userTeamInviteeListing
  );

  const teamDatePickerRef = useRef(null);
  const [allTeamsDepartments, setAllTeamsDepartments] = useState([]);
  const [allTeamsRoles, setAllTeamsRoles] = useState([]);
  const [allTeamsEmails, setAllTeamsEmails] = useState([]);
  const [inviteJoinedDate, setInviteJoinedDate] = useState(null);

  useEffect(() => {
    if (data) {
      const uniqueRoles = data.reduce((acc, currentItem) => {
        currentItem.roles.forEach((role) => {
          if (!acc.includes(role)) {
            acc.push(role);
          }
        });
        return acc;
      }, []);
      setAllRoles(uniqueRoles);
      setAllDepartments(
        data?.reduce((acc, currentItem) => {
          currentItem.departments.forEach((department) => {
            if (!acc.includes(department)) {
              acc.push(department.moduleName);
            }
          });
          return acc;
        }, [])
      );
      // Extract all user names
      const allUserNamesArray = data.map((item) => item.userName);
      // Filter out duplicates
      const uniqueUserNames = [...new Set(allUserNamesArray)];
      setAllUserNames(uniqueUserNames);

      // Extract all user emails
      const allEmailsArray = data.map((item) => item.userEmail);
      // Filter out duplicates
      const uniqueEmails = [...new Set(allEmailsArray)];
      setAllEmails(uniqueEmails);
    }
  }, [data]);

  useEffect(() => {
    if (userInviteeList) {
      const uniqueRolesSet = new Set();
      userInviteeList.forEach((currentItem) => {
        currentItem.roles.forEach((role) => {
          uniqueRolesSet.add(role);
        });
      });
      const uniqueRoles = Array.from(uniqueRolesSet);
      setAllTeamsRoles(uniqueRoles);

      const uniqueDepartmentsSet = new Set();
      userInviteeList.forEach((currentItem) => {
        currentItem.departments.forEach((department) => {
          uniqueDepartmentsSet.add(department.moduleName);
        });
      });
      const uniqueDepartments = Array.from(uniqueDepartmentsSet);
      setAllTeamsDepartments(uniqueDepartments);

      const allEmailsArray = userInviteeList.map((item) => item.userEmail);
      const uniqueEmails = [...new Set(allEmailsArray)];
      setAllTeamsEmails(uniqueEmails);
    }
  }, [userInviteeList]);

  const handleInputChange = (e, field) => {
    const { value } = e.target;
    setFilters({ ...filters, [field]: value });
  };

  const handleMultiSelectChange = (e, field) => {
    // const selectedOptions = Array.from(e.target.selectedOptions, option => option.value);
    if (filters !== null && filters.hasOwnProperty(field)) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        [field]: e,
      }));
    } else {
      setFilters((prevFilters) => ({
        ...prevFilters,
        [field]: e,
      }));
    }
  };

  const clearFilter = (field) => {
    const updatedFilters = { ...filters };
    delete updatedFilters[field];
    setJoinedDate(null);
    setFilters(updatedFilters);
  };

  const formatDate = (date) => {
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  };

  const handleSearch = () => {
    setShowModal(false);
  };

  const handleStatusClick = (status, field) => {
    setFilters({ ...filters, [field]: status });
    handleSearch();
    setShowModal(false); // Close modal after selecting status
  };

  useEffect(() => {
    if (filters?.joinedDate) {
      setJoinedDate(new Date(filters?.joinedDate));
    } else {
      setJoinedDate(null);
    }

    if (filters?.inviteJoinedDate) {
      setInviteJoinedDate(new Date(filters?.inviteJoinedDate));
    } else {
      setInviteJoinedDate(null);
    }
  }, [showModal]);

  const handleCalendarIconClick = (ref) => {
    if (ref.current) {
      ref.current.setOpen(true); // Programmatically open the calendar dropdown
    }
  };

  return (
    <Modal
      show={showModal}
      onHide={() => setShowModal(false)}
      centered
      size="xl"
      data-theme={isDark}
      keyboard={false}
      className="main-advance-search-modal"
    >
      <Modal.Body className="d-flex align-items-center justify-content-center text-center flex-column p-4 advance-search-modal-body">
        <div className="popup-advance-search-wrap w-100">
          <div className="popup-heading-quick-result">
            <div className="row">
              <div className="col-md-4 d-flex align-items-center justify-content-start">
                <h3>
                  {" "}
                  Advance <span>Search</span>{" "}
                  {filters &&
                    !Object.values(filters).every(
                      (value) =>
                        value === "" ||
                        (Array.isArray(value) && value.length === 0)
                    ) && (
                      <a
                        onClick={() => {
                          setFilters(null);
                          setJoinedDate(null);
                        }}
                      >
                        {" "}
                        <img src={close} /> Clear{" "}
                      </a>
                    )}
                </h3>
              </div>

              <div className="col-md-8  d-flex align-items-center justify-content-end w-max">
                <div className="quick-search-list-wrap">
                  <ul className="gap-md-0 gap-2">
                    <li>
                      {" "}
                      <a
                        onClick={() =>
                          handleStatusClick("Active", "userStatus")
                        }
                      >
                        {" "}
                        Active{" "}
                      </a>{" "}
                    </li>
                    <li>
                      {" "}
                      <a
                        onClick={() =>
                          handleStatusClick("InActive", "userStatus")
                        }
                      >
                        {" "}
                        Inactive{" "}
                      </a>{" "}
                    </li>

                    <li className="flex-1 form-group ">
                      <div
                        onClick={() => datePickerRef.current.flatpickr.open()}
                        data-theme={isDark}
                        className="form-control project-details-main-date-filter d-flex align-items-center justify-content-between advance-search-history-date"
                      >
                        <Flatpickr
                          data-theme={isDark}
                          className="project-details-task-table-flatpickr-container"
                          options={{
                            altInput: true,
                            altFormat: "M d, Y",
                            dateFormat: "Y-m-d",
                            static: true,
                            maxDate: new Date(),
                            disableMobile: true,
                          }}
                          placeholder="MMM DD, YYYY"
                          ref={datePickerRef}
                          value={joinedDate || null}
                          onChange={([date]) => {
                            setFilters({
                              ...filters,
                              joinedDate: formatDate(date),
                            });
                            setJoinedDate(date);
                          }}
                        />
                        <span>
                          <Icon
                            onClick={() =>
                              datePickerRef.current.flatpickr.open()
                            }
                            icon="uis:calender"
                          />
                        </span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="form-filed-advance-seach-wrap">
            <h4 className="text-start"> Team </h4>
            <form className="row">
              {/* user name */}
              <div className="col-md-6">
                <div className="form-group">
                  <MultiSelect
                    hideSelectAll={true}
                    options={allUserNames?.map((option, index) => {
                      return {
                        label: option,
                        value: option,
                      };
                    })}
                    value={filters?.userName || []}
                    onChange={(selected) =>
                      handleMultiSelectChange(selected, "userName")
                    }
                    labelledBy="Select"
                    className="multi-select"
                    valueRenderer={(selected) => (
                      <>
                        {selected.length > 0 ? (
                          <div className="selected-options-list-wrap">
                            {selected?.map((item, index) => (
                              <div key={index}>
                                {item.label}
                                <img
                                  src={closeFilter}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    const remainingOptions = selected.filter(
                                      (option) => option.value !== item.value
                                    );
                                    handleMultiSelectChange(
                                      remainingOptions,
                                      "userName"
                                    );
                                  }}
                                />
                              </div>
                            ))}
                          </div>
                        ) : (
                          <p className="multiselect-placeholder">
                            Select User Names
                          </p>
                        )}
                      </>
                    )}
                  />
                </div>
              </div>
              {/* user email */}
              <div className="col-md-6">
                <div className="form-group">
                  <MultiSelect
                    options={allEmails?.map((option, index) => {
                      return {
                        label: option,
                        value: option,
                      };
                    })}
                    value={filters?.userEmail || []}
                    onChange={(selected) =>
                      handleMultiSelectChange(selected, "userEmail")
                    }
                    labelledBy="Select"
                    className="multi-select"
                    valueRenderer={(selected) => (
                      <>
                        {selected.length > 0 ? (
                          <div className="selected-options-list-wrap">
                            {selected?.map((item, index) => (
                              <div key={index}>
                                {item.label}
                                <img
                                  src={closeFilter}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    const remainingOptions = selected.filter(
                                      (option) => option.value !== item.value
                                    );
                                    handleMultiSelectChange(
                                      remainingOptions,
                                      "userEmail"
                                    );
                                  }}
                                />
                              </div>
                            ))}
                          </div>
                        ) : (
                          <p className="multiselect-placeholder">
                            Select User Email
                          </p>
                        )}
                      </>
                    )}
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <MultiSelect
                    options={allDepartments?.map((option, index) => {
                      return {
                        label: option,
                        value: option,
                      };
                    })}
                    value={filters?.departments || []}
                    onChange={(selected) =>
                      handleMultiSelectChange(selected, "departments")
                    }
                    labelledBy="Select"
                    className="multi-select"
                    valueRenderer={(selected) => (
                      <>
                        {selected.length > 0 ? (
                          <div className="selected-options-list-wrap">
                            {selected?.map((item, index) => (
                              <div key={index}>
                                {item.label}
                                <img
                                  src={closeFilter}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    const remainingOptions = selected.filter(
                                      (option) => option.value !== item.value
                                    );
                                    handleMultiSelectChange(
                                      remainingOptions,
                                      "departments"
                                    );
                                  }}
                                />
                              </div>
                            ))}
                          </div>
                        ) : (
                          <p className="multiselect-placeholder">
                            Select Departments
                          </p>
                        )}
                      </>
                    )}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <MultiSelect
                    options={allRoles?.map((option, index) => {
                      return {
                        label: capitalizeFirstLetter(option),
                        value: capitalizeFirstLetter(option),
                      };
                    })}
                    value={filters?.roles || []}
                    onChange={(selected) =>
                      handleMultiSelectChange(selected, "roles")
                    }
                    labelledBy="Select"
                    className="multi-select"
                    valueRenderer={(selected) => (
                      <>
                        {selected.length > 0 ? (
                          <div className="selected-options-list-wrap">
                            {selected?.map((item, index) => (
                              <div key={index}>
                                {item.label}
                                <img
                                  src={closeFilter}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    const remainingOptions = selected.filter(
                                      (option) => option.value !== item.value
                                    );
                                    handleMultiSelectChange(
                                      remainingOptions,
                                      "roles"
                                    );
                                  }}
                                />
                              </div>
                            ))}
                          </div>
                        ) : (
                          <p className="multiselect-placeholder">
                            Select Roles
                          </p>
                        )}
                      </>
                    )}
                  />
                </div>
              </div>
            </form>
          </div>
          {userInviteeList && userInviteeList.length > 0 && (
            <>
              <div className="col-12 mt-3 d-flex align-items-center justify-content-end w-max">
                <div className="quick-search-list-wrap quick-search-wrap-two">
                  <ul className="mb-0 gap-md-0 gap-2">
                    <li>
                      {" "}
                      <a
                        onClick={() =>
                          handleStatusClick("Pending", "inviteUserStatus")
                        }
                      >
                        {" "}
                        Pending{" "}
                      </a>{" "}
                    </li>
                    <li>
                      {" "}
                      <a
                        onClick={() =>
                          handleStatusClick("Rejected", "inviteUserStatus")
                        }
                      >
                        {" "}
                        Rejected{" "}
                      </a>{" "}
                    </li>
                    <li className="flex-1 form-group ">
                      <div
                        onClick={() =>
                          teamDatePickerRef.current.flatpickr.open()
                        }
                        data-theme={isDark}
                        className="form-control project-details-main-date-filter d-flex align-items-center justify-content-between advance-search-history-date"
                      >
                        <Flatpickr
                          data-theme={isDark}
                          className="project-details-task-table-flatpickr-container"
                          options={{
                            altInput: true,
                            altFormat: "M d, Y",
                            dateFormat: "Y-m-d",
                            static: true,
                            maxDate: new Date(),
                            disableMobile: true,
                          }}
                          placeholder="MMM DD, YYYY"
                          ref={teamDatePickerRef}
                          value={inviteJoinedDate || null}
                          onChange={([date]) => {
                            setFilters({
                              ...filters,
                              inviteJoinedDate: formatDate(date),
                            });
                            setInviteJoinedDate(date);
                          }}
                        />
                        <span>
                          <Icon
                            onClick={() =>
                              teamDatePickerRef.current.flatpickr.open()
                            }
                            icon="uis:calender"
                          />
                        </span>
                      </div>
                    </li>
                   
                  </ul>
                </div>
              </div>
              <div className="form-filed-advance-seach-wrap">
                <h4 className="text-start"> Invitations </h4>

                <form className="row">
                  {/* user email */}
                  <div className="col-md-6">
                    <div className="form-group">
                      <MultiSelect
                        options={allTeamsEmails?.map((option, index) => {
                          return {
                            label: option,
                            value: option,
                          };
                        })}
                        value={filters?.inviteUserEmail || []}
                        onChange={(selected) =>
                          handleMultiSelectChange(selected, "inviteUserEmail")
                        }
                        labelledBy="Select"
                        className="multi-select"
                        valueRenderer={(selected) => (
                          <>
                            {selected.length > 0 ? (
                              <div className="selected-options-list-wrap">
                                {selected?.map((item, index) => (
                                  <div key={index}>
                                    {item.label}
                                    <img
                                      src={closeFilter}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        const remainingOptions =
                                          selected.filter(
                                            (option) =>
                                              option.value !== item.value
                                          );
                                        handleMultiSelectChange(
                                          remainingOptions,
                                          "inviteUserEmail"
                                        );
                                      }}
                                    />
                                  </div>
                                ))}
                              </div>
                            ) : (
                              <p className="multiselect-placeholder">
                                Select User Emails
                              </p>
                            )}
                          </>
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <MultiSelect
                        options={allTeamsDepartments?.map((option, index) => {
                          return {
                            label: option,
                            value: option,
                          };
                        })}
                        value={filters?.inviteDepartments || []}
                        onChange={(selected) =>
                          handleMultiSelectChange(selected, "inviteDepartments")
                        }
                        labelledBy="Select"
                        className="multi-select"
                        valueRenderer={(selected) => (
                          <>
                            {selected.length > 0 ? (
                              <div className="selected-options-list-wrap">
                                {selected?.map((item, index) => (
                                  <div key={index}>
                                    {item.label}
                                    <img
                                      src={closeFilter}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        const remainingOptions =
                                          selected.filter(
                                            (option) =>
                                              option.value !== item.value
                                          );
                                        handleMultiSelectChange(
                                          remainingOptions,
                                          "inviteDepartments"
                                        );
                                      }}
                                    />
                                  </div>
                                ))}
                              </div>
                            ) : (
                              <p className="multiselect-placeholder">
                                Select Departments
                              </p>
                            )}
                          </>
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <MultiSelect
                        options={allTeamsRoles?.map((option, index) => {
                          return {
                            label: capitalizeFirstLetter(option),
                            value: capitalizeFirstLetter(option),
                          };
                        })}
                        value={filters?.inviteRoles || []}
                        onChange={(selected) =>
                          handleMultiSelectChange(selected, "inviteRoles")
                        }
                        labelledBy="Select"
                        className="multi-select"
                        valueRenderer={(selected) => (
                          <>
                            {selected.length > 0 ? (
                              <div className="selected-options-list-wrap">
                                {selected?.map((item, index) => (
                                  <div key={index}>
                                    {item.label}
                                    <img
                                      src={closeFilter}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        const remainingOptions =
                                          selected.filter(
                                            (option) =>
                                              option.value !== item.value
                                          );
                                        handleMultiSelectChange(
                                          remainingOptions,
                                          "inviteRoles"
                                        );
                                      }}
                                    />
                                  </div>
                                ))}
                              </div>
                            ) : (
                              <p className="multiselect-placeholder">
                                Select Roles
                              </p>
                            )}
                          </>
                        )}
                      />
                    </div>
                  </div>
                </form>
              </div>
            </>
          )}

          <div className="btn-searc-result-wraper">
            <div className="row">
              <div className="col-md-6">
                <div className="more-filter-btn d-flex"></div>
              </div>
              <div className="col-md-6">
                <div className="main-search-btn">
                  <button onClick={() => setShowModal(false)}>
                    {" "}
                    Search Advance Filters{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default AdvanceSearchModalMain;

/* eslint-disable */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "./Header";
import SideBar from "./SideBar";
import Card from "react-bootstrap/Card";
import NotPerm from "../assets/403.png";
import API from "../services/ClientApi";
import ScreenLoader from "./ScreenLoader";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useDispatch } from "react-redux";
import { userProfilePage } from "../actions/userActions";
import { useSelector } from "react-redux";
import { Steps, Hints } from "intro.js-react";
import "intro.js/introjs.css";
import { useClearAllTasksCacheMutation } from "../slices/tasks/tasksSlice";
import { isTrialActive } from "../helper/subscriptionHelpers";
import SubscriptionModal from "./SubscriptionModal";
import PaymentModal from "./PaymentModal/PaymentModal";
import ContactModal from "./ContactModal/ContactModal";
import "./styles/protectedRoute.scss";

const ProtectedRoute = (props) => {
  const dispatch = useDispatch();
  const location = useLocation(); // Get the current location
  const { userProfile } = useSelector((state) => state?.profile);
  const [clearAllTasksCache] = useClearAllTasksCacheMutation();
  // const [userStatusUpdate] = useUserStatusUpdateMutation();
  const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_KEY}`);
  const navigate = useNavigate();
  const [sidebar, setSideBar] = useState(false);
  const [permitted, setPermitted] = useState(true);
  const [bannerCount, setBannerCount] = useState(0);
  const [options, setOptions] = useState([]);
  const [mainLoad, setMainLoad] = useState(true);
  const sectionRef = useRef(null); // Create a ref for the section
  const [mobileOpt, setMobileOpt] = useState(false);
  const [progress, setProgress] = useState(0);
  const [runTour, setRunTour] = useState(false);
  const [openSubscriptionModal, setOpenSubscriptionsModal] = useState(false);

  // Subscription Modal states
  const [showPackageModal, setShowPackageModal] = useState(false);
  const [summery, setSummery] = useState(false);
  const [openContact, setOpenContact] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [packageData, setPackageData] = useState({
    packageId: "",
    stripeProductId: "",
    totalAmount: 0,
    interval: "",
    seatCount: 0,
  });
  const [selectedUser, setSelectedUser] = useState([]);
  const [userCount, setUserCount] = useState(false);
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const [removedUserIds, setRemovedUserIds] = useState([]);
  const [users, setUsers] = useState([]);
  const [limit, setLimit] = useState(0);
  const [name, setName] = useState("");
  const [seat, setSeat] = useState(3);
  const [amt, setAmt] = useState(0);

  // Subscription associated handlers
  const handleShowPackageModal = () => {
    if (userProfile?.packageDetails?.packageName === "Free") {
      setShowPackageModal(true);
    } else {
      setSummery(true);
      setShowPackageModal(true);
    }
  };

  const handleClosePackageModal = () => {
    setOpenSubscriptionsModal(false);
    setShowPackageModal(false);
    getUsers();
    setSelectedUserIds([]);
    setRemovedUserIds([]);
  };

  const checkSeats = async () => {
    try {
      const response = await API.post("company/registered-user-count");
      setUserCount(response?.data?.registeredUserCount);
    } catch (error) {
    } finally {
    }
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const getUsers = async () => {
    // setLoad(true);
    try {
      const response = await API.post("company/registered-users", {
        searchTerm: "",
      });
      if (response?.data?.status === "success") {
        const fetchedUsers = response?.data?.data || [];
        setUsers(fetchedUsers);

        // Populate selectedUserIds with userIds from fetchedUsers
        const userIds = fetchedUsers.map((user) => user.userId);
        setSelectedUserIds(userIds);

        setLimit(userIds.length);
      }
    } catch (error) {
    } finally {
      // setLoad(false);
    }
  };

  useEffect(() => {
    if (openSubscriptionModal) {
      handleShowPackageModal();
      getUsers();
    }
  }, [openSubscriptionModal]);

  // Tour steps
  const steps = [
    {
      element: ".notification-badge-main",
      intro: "Receive important updates, reminders, and alerts in one place.",
      position: "bottom",
    },
    {
      element: ".sidebar-task-slug",
      intro:
        "View task listing to see its details and stay organized with ease.",
      position: "right",
    },
    {
      element: ".sidebar-project-slug",
      intro: "Easily view and manage all your projects in one place.",
      position: "right",
    },
    {
      element: ".profile-dash-btn",
      intro: "Easily access, update, and manage your profile information.",
      position: "left",
    },
    {
      element: ".invite-btn-wrap",
      intro: "Quickly create tasks, set deadlines, and add notes.",
      position: "left",
    },
  ];

  const getOptions = async () => {
    const resp = await API.get("users/left-sidebar-modules", {
      onDownloadProgress: (progressEvent) => {
        const total = progressEvent.total;
        const current = progressEvent.loaded;
        const percentCompleted = Math.round((current / total) * 100);
        setProgress(percentCompleted);
      },
    });
    if (resp?.data?.status === "success") {
      setOptions(resp?.data?.data);
    }
  };

  const checkPermissions = async () => {
    const currentRoute = window.location.pathname;
    let perm = [];
    if (localStorage.getItem("permissions")) {
      perm = JSON.parse(
        localStorage.getItem("permissions")
          ? localStorage.getItem("permissions")
          : []
      );

      const permWithCompany = perm.map(
        (str) => `${localStorage.getItem("company")}/${str}`
      );
      // const parts = currentRoute.substring(1).split("/");
      // const valueAfterSlash = parts.length >= 2 ? parts[1] : null;
      // const valid = await isValidUrl(valueAfterSlash);
      if (permWithCompany.includes(currentRoute.substring(1))) {
        setPermitted(true);
      } else {
        // need to fix it when performance system page is completed
        setPermitted(true); //TODO: Revert to false
        getOptions();
        getPermissions();
      }
    }
  };

  const getPermissions = async () => {
    try {
      if (!localStorage.getItem("permissions")) {
        const permissions = await API.get("users/permissions-urls");
        localStorage.setItem(
          "permissions",
          JSON.stringify(permissions?.data?.data)
        );
      }
    } catch (error) {}
  };

  useEffect(() => {
    setPermitted(true);
    if (!localStorage.getItem("access_token")) {
      const manageDomain = process.env.REACT_APP_AUTH_URL;
      window.location.href = `${manageDomain}/login`; // This reloads the page
    }
    // Get the current route from the web search tab's URL
    checkPermissions();
    // Scroll to top when pathname changes
    sectionRef?.current?.scrollTo({ top: 0, left: 0, behavior: "smooth" });

    // Log the current route to the console
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.getItem("access_token"), window?.location?.pathname]);

  useEffect(() => {
    if (!localStorage.getItem("access_token")) {
      const manageDomain = process.env.REACT_APP_AUTH_URL;
      window.location.href = `${manageDomain}/login`; // This reloads the page
    }
    localStorage.setItem("isDark", userProfile?.themeColor);
    getPermissions();
    if (userProfile && userProfile?.emailVerified === 0) {
      navigate(`/${localStorage.getItem("company")}/profile`);
    }

    const getUser = async () => {
      setProgress(25);
      setBannerCount(0);
      try {
        setMainLoad(true);
        const resp = await dispatch(userProfilePage());
        if (resp?.status === "success") {
          setProgress(50);
          setProgress(75);
          await getOptions();
        } else {
          // localStorage.removeItem("access_token");
          // navigate("/login");
        }
      } catch (error) {
      } finally {
        setProgress(100);
        setMainLoad(false);
      }
    };
    getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.getItem("access_token")]);

  const getBanner = async (user) => {
    if (user && user?.emailVerified === 0) {
      setBannerCount((prev) => prev + 1);
    }
    if (
      (user &&
        user?.packageDetails?.currentStatus !== false &&
        user?.roles.some((role) => role.roleType === "admin")) ||
      !isTrialActive(user)
    ) {
      setBannerCount((prev) => prev + 1);
    }
  };

  useEffect(() => {
    // if (!localStorage.getItem("access_token")) {
    //   navigate("/login");
    // }
    setBannerCount(0);
    getBanner(userProfile);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userProfile, localStorage.getItem("access_token")]);

  // Run tour journey
  useEffect(() => {
    if (
      location.pathname === `/${localStorage.getItem("company")}/profile` && // Ensure URL is /dashboard
      options &&
      options.length > 0 &&
      userProfile?.dashboardTour
    ) {
      setRunTour(true);
    }
  }, [options, userProfile, location]);

  useEffect(() => {
    // Add an event listener to handle clicks on the "Skip Tour" button
    const handlePrevClick = async (event) => {
      if (
        event.target.classList.contains("introjs-prevbutton") &&
        location.pathname === `/${localStorage.getItem("company")}/profile`
      ) {
        try {
          setRunTour(false); // Close the tour
          const response = await API.post("users/update-profile", {
            userId: userProfile?.userId,
            fieldName: "dashboardTour", // Add the field name for the specific input field
            fieldValues: false, // Add the corresponding state variable here
          });
          if (response?.status === 200) {
            await dispatch(userProfilePage());
          }
        } catch (error) {
          console.error("Error updating profile:", error);
        }
      }
    };

    // Attach the event listener to the document
    document.addEventListener("click", handlePrevClick);

    // Cleanup the event listener when the component unmounts
    return () => {
      document.removeEventListener("click", handlePrevClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return !mainLoad ? (
    <Elements stripe={stripePromise}>
      <section
        className="dashboard-main-wraper"
        data-theme={props.isDark}
        ref={sectionRef}
      >
        {permitted ? (
          <>
            {runTour && (
              <Steps
                enabled={runTour}
                steps={steps.filter((step) =>
                  document.querySelector(step.element)
                )}
                initialStep={0}
                options={{
                  showProgress: false,
                  showStepNumbers: false,
                  exitOnOverlayClick: false,
                  scrollToElement: false,
                  disableInteraction: true,
                  exitOnEsc: true,
                  nextLabel: "Next",
                  prevLabel: "Skip Tour",
                  tooltipClass: "tour-custom-tooltip", // Optional custom styling
                }}
                onExit={async () => {
                  if (localStorage.getItem("access_token")) {
                    try {
                      setRunTour(false); // Close the tour
                      const response = await API.post("users/update-profile", {
                        userId: userProfile?.userId,
                        fieldName: "dashboardTour", // Add the field name for the specific input field
                        fieldValues: false, // Add the corresponding state variable here
                      });
                      if (response?.status === 200) {
                        await dispatch(userProfilePage());
                      }
                    } catch (error) {
                      console.error("Error updating profile:", error);
                    }
                  }
                }} // Reset the tour state when exited
              />
            )}
            <Header
              sidebar={sidebar}
              collapse={props.collapse}
              setCollapse={props.setCollapse}
              setSideBar={setSideBar}
              user={userProfile}
              isDark={props?.isDark}
              mobileOpt={mobileOpt}
              setMobileOpt={setMobileOpt}
              setOpenSubscriptionsModal={setOpenSubscriptionsModal}
            />
            <section className="left-right-dashboard-wraper">
              <div
                className={`container-fluid ${
                  props.leads ? "no-pad-container" : ""
                } left-right-main`}
              >
                <SideBar
                  collapse={props.collapse}
                  sidebar={sidebar}
                  setSideBar={setSideBar}
                  options={options}
                  user={userProfile}
                  isDark={props?.isDark}
                  bannerCount={bannerCount}
                />

                <div
                  className={`dashboard-area-wraper ${
                    props.collapse ? "dashboard-area-collapse" : ""
                  } ${bannerCount === 2 && "banner-two"} ${
                    bannerCount === 1 && "banner-one"
                  } ${mobileOpt ? "dashboard-header-instance" : ""}`}
                >
                  {React.Children.map(props.children, (child) =>
                    React.cloneElement(child, {
                      options,
                      getOptions,
                      runTour,
                      setOpenSubscriptionsModal,
                      openSubscriptionModal,
                    })
                  )}
                </div>
              </div>
            </section>
            {showPackageModal && (
              <SubscriptionModal
                isDark={props.isDark}
                showPackageModal={showPackageModal}
                handleClosePackageModal={handleClosePackageModal}
                openModal={openModal}
                setPackageData={setPackageData}
                packageData={packageData}
                setOpenContact={setOpenContact}
                selectedUser={selectedUser}
                setSelectedUser={setSelectedUser}
                checkSeats={checkSeats}
                userCount={userCount}
                setUserCount={setUserCount}
                selectedUserIds={selectedUserIds}
                setSelectedUserIds={setSelectedUserIds}
                removedUserIds={removedUserIds}
                setRemovedUserIds={setRemovedUserIds}
                setUsers={setUsers}
                limit={limit}
                setLimit={setLimit}
                users={users}
                setName={setName}
                seat={seat}
                setSeat={setSeat}
                summery={summery}
                setSummery={setSummery}
                amt={amt}
                setAmt={setAmt}
              />
            )}
            <PaymentModal
              isOpen={isModalOpen}
              onClose={closeModal}
              packageData={packageData}
              selectedUser={selectedUser}
              checkSeats={checkSeats}
              packageName={name}
              seat={seat}
              amt={amt}
            />
            <ContactModal
              modal={openContact}
              handleClose={() => setOpenContact(false)}
              isDark={props.isDark}
            />
          </>
        ) : (
          <div className="verify-email-main" data-theme={props.isDark}>
            <Card
              style={{
                width: "100%",
                height: "100%",
                background: "transparent",
              }}
            >
              {/* <Card.Img src={NotIcon}></Card.Img> */}
              <Card.Body>
                <Card.Text>
                  <div className="d-flex flex-column justify-content-center align-items-center page-not-wrapper">
                    <img
                      src={NotPerm}
                      style={{ width: "60%" }}
                      alt="no-permission"
                    />
                    <div className="btn-group-not-found">
                      <button
                        onClick={() =>
                          navigate(
                            `/${localStorage.getItem("company")}/dashboard`
                          )
                        }
                      >
                        BACK TO HOME
                      </button>
                    </div>
                  </div>
                </Card.Text>
              </Card.Body>
            </Card>
            {/* Other components or logic related to the token */}
          </div>
        )}
      </section>
    </Elements>
  ) : (
    <ScreenLoader progress={progress} />
  );
};

export default ProtectedRoute;

/* eslint-disable */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Dropdown, ButtonGroup, Modal, Spinner } from "react-bootstrap";
import MenuLeft from "../assets/menu-left.svg";
import AddUser from "../assets/add-user.png";
import DeleteIcon from "../assets/infoMain.svg";
import More from "../assets/more.png";
import Cross from "../assets/cross.png";
import useLocalStorage from "use-local-storage";
import Banner from "./Banner";
import API from "../services/ClientApi";
import InviteModal from "./InviteModal/InviteModal";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import "../components/styles/header.css";
import NotificationSection from "./NotificationSection";
import NotifyIcon from "../assets/noty-bell.png";
import "./styles/header.scss";
import FAQModalOffset from "./FAQModal/FAQModalOffset";
import { notificationUnReadCount } from "../actions/notificationsActions";
import AddOnModal from "../pages/SubscriptionsUpdated/components/AddOnModal";
import ConfirmAddonModal from "./ConfirmAddonModal";
import {
  billingData,
  clearSubscriptionData,
  subscriptionData,
} from "../actions/paymentActions";
import { registeredUserCountAction } from "../actions/registeredUserCountActions";
import { companyModalDataListing } from "../actions/companyOverviewActions";
import { findObjectByContentTitle } from "../helper/getContentObject";
import { onMessageListener, requestForToken } from "../firebase-config";
import notificationSound from "../assets/system-notification.mp3";
import { getLogoFromPalette } from "../helper/getLogoByTheme";
import {
  clearTaskListAction,
  emptyTaskPrioritiesListAction,
  emptyTaskTypesListAction,
} from "../actions/tasksActions";
import {
  clearProjectListAction,
  emptyProjectFormListingAction,
  emptyProjectListingStatusAction,
  emptyProjectListingAllStatusAction,
  emptyProjectTypeListingAction,
  emptyProjectUserListingAction,
} from "../actions/projectActions";
import {
  clearOngoingTasksListAction,
  clearActiveProjectListAction,
  clearTimelineStats,
} from "../actions/timelineActions";
import { emptyTeamsListAction } from "../actions/teamsActions";
import { companiesListingAction } from "../actions/companiesAction";
import CompanyListModal from "./CompanyListModal/CompanyListModal";
import { clearCachedTasks } from "../actions/taskCachedActions";
import { clearProjectCache } from "../actions/projectListingCacheActions";
import { clearCompletedTask } from "../actions/completedTasksActions";
import { projectCompletedClearState } from "../actions/projectCompletedListingActions";
import { useClearAllCacheMutation } from "../slices/tasks/tasksSlice";
import { useClearAllCompletedTaskCacheMutation } from "../slices/tasks/completedTasks.Slice";
import chatApi from "../slices/chat/chatSlice";
import { Icon } from "@iconify/react/dist/iconify.js";
import EnableNotificationModal from "./EnableNotificationModal/EnableNotificationModal";
import { isTrialActive } from "../helper/subscriptionHelpers";
import saveLocalStorageToCookies from "../helper/saveLocalStorageToCookies";
import ChatModIcon from "../assets/chat_mod_item_icon.png";
import CRMMODIcon from "../assets/crm_mod_item_icon.png";
import TaskProModIcon from "../assets/task_pro_mod_item_icon.png";
import Cookies from "js-cookie";
const Header = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [clearAllCache] = useClearAllCacheMutation();
  const [clearAllCompletedTaskCache] = useClearAllCompletedTaskCacheMutation();
  const [notificationMain, setNotification] = useState({
    title: "",
    body: "",
    company: "",
  });
  const { billingDetail } = useSelector((state) => state.billing);
  const { subscriptionDetail } = useSelector((state) => state.subscription);
  const [show, setShow] = useState(false);
  const [showFaqModal, setShowFaqModal] = useState(false);
  const [switchCompany, setSwitchCompany] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { userProfile } = useSelector((state) => state?.profile);
  const { modalContents } = useSelector((state) => state?.modalData);
  const { companiesList } = useSelector((state) => state?.companyListData);
  const [showTaskDetail, setShowTaskDetail] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [isDark, setIsDark] = useLocalStorage(
    "isDark",
    userProfile?.themeColor
  );
  const [addonModal, setAddonModal] = useState(false);
  const [showVerifyEmailModal, setShowVerifyEmailModal] = useState(false);
  const [load, setLoad] = useState(false);
  const notification = useSelector((state) => state?.notificationUnReadCount);
  const [planData, setPlanData] = useState(null);
  const [showAddModal, setShowAddModal] = useState(false);
  const [notificationModalOpen, setNotificationModalOpen] = useState(false);
  const { registeredUserCount } = useSelector(
    (state) => state?.registeredUserCount
  );
  const verifyModalContent = findObjectByContentTitle(
    "Verify Email",
    modalContents?.data
  );

  const handleCloseCompanyModal = () => {
    setSwitchCompany(false);
  };

  const handleAddonClose = () => {
    setAddonModal(false);
  };

  const handleAddonShow = () => {
    setAddonModal(true);
  };

  const getPlanData = async () => {
    if (localStorage.getItem("access_token")) {
      try {
        await dispatch(subscriptionData());
      } catch (error) {}
    }
  };

  useEffect(() => {
    if (userProfile) {
      if (userProfile?.canInvite && !billingDetail) {
        dispatch(billingData());
      }
      if (!modalContents) {
        dispatch(companyModalDataListing());
      }
      if (!companiesList) {
        dispatch(companiesListingAction(userProfile?.emailAddress));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userProfile]);

  useEffect(() => {
    if (!subscriptionDetail && userProfile && userProfile?.canInvite) {
      getPlanData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userProfile]);

  useEffect(() => {
    if (subscriptionDetail) {
      setPlanData(subscriptionDetail?.packageDetails);
    }
  }, [subscriptionDetail]);

  const getNotificationUnreadCount = async () => {
    if (!notification) {
      await dispatch(notificationUnReadCount());
    }
    if (userProfile?.canInvite && !registeredUserCount) {
      await dispatch(registeredUserCountAction());
    }
  };

  const handleSaveTheme = async (value) => {
    try {
      await API.post("users/theme-color-update", { themeColor: value });
    } catch (error) {
    } finally {
    }
  };

  useEffect(() => {
    if (userProfile) {
      getNotificationUnreadCount();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userProfile]);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const resendEmail = async () => {
    try {
      setLoad(true);
      const response = await API.post("users/resend-email-verification");
      if (response?.data?.status === "success") {
        toast.success("Verification mail sent successfully", {
          position: toast.POSITION.TOP_CENTER,
        });
        setShowVerifyEmailModal(false);
      }
    } catch (error) {
    } finally {
      setLoad(false);
    }
  };

  const handleVerifyEmailModalClose = () => {
    setShowVerifyEmailModal(false);
  };

  const handleClose = () => setShow(false);
  const handleShow = () => {
    if (props?.user?.emailVerified === 0) {
      setShowVerifyEmailModal(true);
    } else {
      setShow(true);
    }
  };
  const colorVariations = [
    { light: "#94ABFF", dark: "#394b87", value: "default" }, // Original colors
    { light: "#75be6f", dark: "#44b237", value: "color1" }, // Pink variations
    // { light: "#ede151", dark: "#dece05", value: "color2" }, // Green variations
    { light: "#eead45", dark: "#d58500", value: "color3" }, // Yellow variations
    { light: "#5b89bd", dark: "#014596", value: "color4" }, // Orange variations
    { light: "#8a59b5", dark: "#500296", value: "color5" }, // Blue variations
    { light: "#d6293e", dark: "#a30013", value: "color6" }, // Blue variations
    // { light: "#282828", dark: "#000", value: "color7" }, // Dark variations
  ];

  // Notifications;
  // requestForToken();
  onMessageListener()
    .then((payload) => {
      if (
        payload?.data?.companySlug === userProfile?.companySlug &&
        payload?.data?.type !== "new-message"
      ) {
        setNotification({
          title: payload?.data?.title,
          body: payload?.data?.body,
          url: payload?.data?.url,
        });
        if (payload?.data?.type === "new-message") {
          dispatch(chatApi.util.invalidateTags(["AllChatUsers"]));
        }
      }
    })
    .catch((err) => console.log("failed: ", err));

  const navigateByType = (data) => {
    if (!data?.url) return;

    handleClose?.();

    if (data.url.includes("subscriptions")) {
      navigate(`/${localStorage.getItem("company")}/company-subscriptions`);
    } else if (data.url.includes("project")) {
      redirectByNotification(`/project?pid=${data.target_id}`);
    } else if (data.url.includes("task-")) {
      redirectByNotification(`/task-management-tasks`, data.target_id);
    }
  };

  function Message() {
    return (
      // notificationMain?.url && (
      <div
        onClick={() => navigateByType(notificationMain)}
        className="notifiy-snackbar"
      >
        <div>
          <p className="cloud-notification-text cloud-txt-1">
            <b>{notificationMain?.title}</b>
          </p>
          <p className="cloud-notification-text cloud-txt-2">
            {notificationMain?.body}
          </p>
        </div>
      </div>
      // )
    );
  }

  // Define a function to play the notification sound
  const playNotificationSound = () => {
    const audio = new Audio(notificationSound);
    audio.play();
  };

  const msg = () => {
    playNotificationSound();
    toast(<Message />, {
      position: "bottom-right", // Position the toast
      type: "info", // Set the toast type
      hideProgressBar: true,
      autoClose: 5000, // 5 seconds in milliseconds
    });
  };

  useEffect(() => {
    if (notificationMain?.title) {
      getNotificationUnreadCount();
      msg();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationMain]);

  useEffect(() => {
    requestForToken();
  }, []);

  // Enable notification check
  useEffect(() => {
    let intervalId;

    if (userProfile && !userProfile?.emailNotification) {
      setNotificationModalOpen(true);

      // Set an interval to recheck every minute
      intervalId = setInterval(() => {
        if (!userProfile?.emailNotification) {
          setNotificationModalOpen(true);
        }
      }, 3600000); // 3600000ms = 1 hour
    }

    // Cleanup function to clear the interval if the component unmounts
    return () => {
      clearInterval(intervalId);
    };
  }, [userProfile]);

  const ModuleDropdown = () => {
    return (
      <Dropdown as={ButtonGroup}>
        <Dropdown.Toggle
          variant="secondary"
          id="main-menu-dropdown"
          className="no-arrow-dd settings-header"
        >
          <div className="header-module-toggle-btn">
            {" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 14 14"
              fill="none"
            >
              <path
                d="M8.28613 3.27755H10.1809M10.1809 3.27755H12.0756M10.1809 3.27755V5.17229M10.1809 3.27755V1.38281"
                stroke="var(--selected-route-color-svg)"
                stroke-linecap="round"
              />
              <path
                d="M0.707031 3.27729C0.707031 2.08637 0.707031 1.49092 1.077 1.12095C1.44697 0.750977 2.04243 0.750977 3.23335 0.750977C4.42426 0.750977 5.01972 0.750977 5.38969 1.12095C5.75966 1.49092 5.75966 2.08637 5.75966 3.27729C5.75966 4.46821 5.75966 5.06367 5.38969 5.43364C5.01972 5.80361 4.42426 5.80361 3.23335 5.80361C2.04243 5.80361 1.44697 5.80361 1.077 5.43364C0.707031 5.06367 0.707031 4.46821 0.707031 3.27729Z"
                stroke="var(--selected-route-color-svg)"
              />
              <path
                d="M7.6543 10.2246C7.6543 9.03365 7.6543 8.4382 8.02428 8.06822C8.39425 7.69824 8.98971 7.69824 10.1806 7.69824C11.3715 7.69824 11.967 7.69824 12.3369 8.06822C12.7069 8.4382 12.7069 9.03365 12.7069 10.2246C12.7069 11.4155 12.7069 12.0109 12.3369 12.3809C11.967 12.7509 11.3715 12.7509 10.1806 12.7509C8.98971 12.7509 8.39425 12.7509 8.02428 12.3809C7.6543 12.0109 7.6543 11.4155 7.6543 10.2246Z"
                stroke="var(--selected-route-color-svg)"
              />
              <path
                d="M0.707031 10.2246C0.707031 9.03365 0.707031 8.4382 1.077 8.06822C1.44697 7.69824 2.04243 7.69824 3.23335 7.69824C4.42426 7.69824 5.01972 7.69824 5.38969 8.06822C5.75966 8.4382 5.75966 9.03365 5.75966 10.2246C5.75966 11.4155 5.75966 12.0109 5.38969 12.3809C5.01972 12.7509 4.42426 12.7509 3.23335 12.7509C2.04243 12.7509 1.44697 12.7509 1.077 12.3809C0.707031 12.0109 0.707031 11.4155 0.707031 10.2246Z"
                stroke="var(--selected-route-color-svg)"
              />
            </svg>
            <p>Modules</p>
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu style={{ width: "350px" }}>
          <h6 className="settings-dropdown-heading ps-3 pt-1">
            Iffra Products
          </h6>

          <>
            <Dropdown.Item
              onClick={() => {
                const manageDomain = process.env.REACT_APP_ADMIN_URL;

                window.location.href = `${manageDomain}/${localStorage.getItem(
                  "company"
                )}/dashboard`;
              }}
              className={`d-flex align-items-center gap-2 py-2 module-dropdown-item `}
            >
              <div className="module-dropdown-item-img-div col-1">
                <img
                  className="module-dropdown-item-img"
                  src={TaskProModIcon}
                  alt="chat-module-icon"
                  width="70px"
                  height="51px"
                />
              </div>
              <div>
                <div className="module-dropdown-item-task-title">
                  iffra - Task Pro
                </div>
                <div className="module-dropdown-item-descp">
                  Task management with powerful productivity tools
                </div>
              </div>
            </Dropdown.Item>

            <Dropdown.Item
              onClick={() => {
                // if (props?.user?.emailVerified === 0) {
                //   setShowVerifyEmailModal(true);
                // } else {
                //   saveLocalStorageToCookies(`/company-profile`);
                // }
              }}
              className={`d-flex align-items-center gap-2 py-2 module-dropdown-item `}
            >
              <div className="module-dropdown-item-img-div col-1">
                <img
                  className="module-dropdown-item-img"
                  src={CRMMODIcon}
                  alt="crm-module-icon"
                  width="70px"
                  height="51px"
                />
              </div>
              <div>
                <div className="module-dropdown-item-crm-title">
                  iffra - CRM
                </div>
                <div className="module-dropdown-item-descp">
                  Customer relationship and sales management.
                </div>
              </div>
            </Dropdown.Item>

            <Dropdown.Item
              onClick={() => {
                const manageDomain = process.env.REACT_APP_ADMIN_URL;

                window.location.href = `${manageDomain}/${localStorage.getItem(
                  "company"
                )}/dashboard?chats=true`;
              }}
              className={`d-flex align-items-center gap-2 py-2  `}
            >
              <div className="module-dropdown-item-img-div col-1">
                <img
                  className="module-dropdown-item-img"
                  src={ChatModIcon}
                  alt="crm-module-icon"
                  width="70px"
                  height="51px"
                />
              </div>
              <div>
                <div className="module-dropdown-item-chat-title">
                  iffra - Chat
                </div>
                <div className="module-dropdown-item-descp">
                  Simplify and manage all your conversations in one place.
                </div>
              </div>
            </Dropdown.Item>
          </>
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  return (
    <div className="dashboard-header">
      {((props?.user &&
        props?.user?.packageDetails?.currentStatus !== false &&
        props?.user?.roles[0]?.roleType === "admin") ||
        !isTrialActive(props.user)) && (
        <Banner
          isDark={props.isDark}
          message="sub"
          user={props?.user}
          inActive={!isTrialActive(props.user)}
          setOpenSubscriptionsModal={props.setOpenSubscriptionsModal}
        />
      )}
      {props?.user?.emailVerified === 0 && (
        <Banner
          isDark={props.isDark}
          message="Email is not verified. Please verify your email"
        />
      )}
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6 top-left-col">
            <div className="header-nav-list">
              <ul>
                <li>
                  {" "}
                  <a
                    onClick={() => props.setCollapse(!props.collapse)}
                    className="menu-icon"
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5 7H19"
                        stroke="var(--selected-route-color-svg)"
                        stroke-width="2"
                        stroke-linecap="round"
                      />
                      <path
                        d="M5 12H19"
                        stroke="var(--selected-route-color-svg)"
                        stroke-width="2"
                        stroke-linecap="round"
                      />
                      <path
                        d="M5 17H19"
                        stroke="var(--selected-route-color-svg)"
                        stroke-width="2"
                        stroke-linecap="round"
                      />
                    </svg>
                  </a>{" "}
                  <a
                    onClick={() => props.setSideBar(!props.sidebar)}
                    className="menu-icon-2"
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5 7H19"
                        stroke="var(--selected-route-color-svg)"
                        stroke-width="2"
                        stroke-linecap="round"
                      />
                      <path
                        d="M5 12H19"
                        stroke="var(--selected-route-color-svg)"
                        stroke-width="2"
                        stroke-linecap="round"
                      />
                      <path
                        d="M5 17H19"
                        stroke="var(--selected-route-color-svg)"
                        stroke-width="2"
                        stroke-linecap="round"
                      />
                    </svg>
                  </a>{" "}
                </li>
                <li>
                  {" "}
                  <a
                    onClick={() =>
                      navigate(`/${localStorage.getItem("company")}/profile`)
                    }
                  >
                    {" "}
                    <img
                      className="logo-dash"
                      src={getLogoFromPalette(isDark)}
                      alt="logo"
                    />{" "}
                  </a>{" "}
                </li>
              </ul>
            </div>
            <div className="mobile-options">
              <a onClick={() => props.setMobileOpt(!props.mobileOpt)}>
                {" "}
                <img src={More} alt="menu" className="more-img" />{" "}
              </a>{" "}
            </div>
          </div>
          <div className="col-md-6 top-right-col">
            <div className="header-top-right-nav">
              <ul>
                <li>
                  <ModuleDropdown />
                </li>
                <li>
                  <svg
                    onClick={() => setShowFaqModal(true)}
                    xmlns="http://www.w3.org/2000/svg"
                    width="23"
                    height="23"
                    viewBox="0 0 16 16"
                    fill="none"
                    style={{ marginTop: "4px" }}
                  >
                    <circle
                      cx="7.03613"
                      cy="7.26465"
                      r="6.375"
                      stroke="var(--selected-route-color-svg)"
                    />
                    <circle
                      cx="7.03678"
                      cy="11.5143"
                      r="0.354167"
                      fill="var(--selected-route-color-svg)"
                    />
                    <path
                      d="M7.03613 10.0977V9.49077C7.03613 8.98486 7.28897 8.51243 7.70991 8.2318L8.13383 7.94919C8.77563 7.52132 9.16113 6.80101 9.16113 6.02965V5.84766C9.16113 4.67405 8.20974 3.72266 7.03613 3.72266V3.72266C5.86253 3.72266 4.91113 4.67405 4.91113 5.84766V5.84766"
                      stroke="var(--selected-route-color-svg)"
                    />
                  </svg>
                </li>
                {props?.user?.canInvite === 1 && (
                  <>
                    {props?.user?.roles[0]?.roleType
                      ?.toString()
                      .toLowerCase() === "admin" ? (
                      <>
                        <li>
                          {" "}
                          <svg
                            onClick={() => {
                              if (props?.user?.emailVerified === 0) {
                                setShowVerifyEmailModal(true);
                              } else {
                                if (!isTrialActive(props.user)) {
                                  props.setOpenSubscriptionsModal(true);
                                } else {
                                  setShowModal(true);
                                }
                              }
                            }}
                            xmlns="http://www.w3.org/2000/svg"
                            width="22"
                            height="22"
                            viewBox="0 0 16 16"
                            fill="none"
                          >
                            <path
                              d="M8.48104 4.46431C8.23674 4.39736 7.98434 4.36455 7.73104 4.36681C7.2043 4.36627 6.6867 4.50443 6.2303 4.76739C5.77389 5.03035 5.39476 5.40884 5.13104 5.86481C4.86732 6.32078 4.7283 6.83814 4.72797 7.36488C4.72763 7.89162 4.86599 8.40917 5.12912 8.86547C5.39226 9.32178 5.7709 9.70076 6.22697 9.9643C6.68304 10.2278 7.20046 10.3667 7.7272 10.3668C8.25394 10.3669 8.77143 10.2284 9.22763 9.96507C9.68384 9.70176 10.0627 9.32298 10.326 8.86681"
                              stroke="var(--selected-route-color-svg)"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M11.4811 5.8667H14.4811M12.9811 4.3667V7.3667M7.73108 10.3667C6.7982 10.362 5.89708 10.7052 5.20367 11.3293C4.51026 11.9534 4.07436 12.8135 3.98108 13.7417C5.08638 14.4738 6.3828 14.8642 7.70858 14.8642C9.03436 14.8642 10.3308 14.4738 11.4361 13.7417C11.3436 12.8212 10.9141 11.9674 10.2302 11.3444C9.54626 10.7214 8.65619 10.3732 7.73108 10.3667Z"
                              stroke="var(--selected-route-color-svg)"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M14.0911 10.3666C13.7586 11.3161 13.215 12.1778 12.5011 12.8866C11.6231 13.7653 10.5197 14.3847 9.31234 14.6767C8.10498 14.9686 6.84047 14.9217 5.65804 14.5412C4.47561 14.1606 3.42112 13.4612 2.61066 12.5198C1.8002 11.5785 1.2652 10.4318 1.06458 9.20594C0.863954 7.98009 1.00548 6.72265 1.47357 5.57206C1.94167 4.42148 2.71817 3.42237 3.7176 2.68474C4.71703 1.94711 5.90062 1.49956 7.13805 1.39138C8.37549 1.28319 9.61879 1.51856 10.7311 2.07157"
                              stroke="var(--selected-route-color-svg)"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </li>
                      </>
                    ) : (
                      <>
                        {registeredUserCount &&
                          props?.user?.packageDetails?.seatCount -
                            (registeredUserCount || 0) >
                            0 && (
                            <li>
                              {" "}
                              <svg
                                onClick={() => {
                                  if (props?.user?.emailVerified === 0) {
                                    setShowVerifyEmailModal(true);
                                  } else {
                                    if (!isTrialActive(props.user)) {
                                      props.setOpenSubscriptionsModal(true);
                                    } else {
                                      setShowModal(true);
                                    }
                                  }
                                }}
                                xmlns="http://www.w3.org/2000/svg"
                                width="22"
                                height="22"
                                viewBox="0 0 16 16"
                                fill="none"
                              >
                                <path
                                  d="M8.48104 4.46431C8.23674 4.39736 7.98434 4.36455 7.73104 4.36681C7.2043 4.36627 6.6867 4.50443 6.2303 4.76739C5.77389 5.03035 5.39476 5.40884 5.13104 5.86481C4.86732 6.32078 4.7283 6.83814 4.72797 7.36488C4.72763 7.89162 4.86599 8.40917 5.12912 8.86547C5.39226 9.32178 5.7709 9.70076 6.22697 9.9643C6.68304 10.2278 7.20046 10.3667 7.7272 10.3668C8.25394 10.3669 8.77143 10.2284 9.22763 9.96507C9.68384 9.70176 10.0627 9.32298 10.326 8.86681"
                                  stroke="var(--selected-route-color-svg)"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M11.4811 5.8667H14.4811M12.9811 4.3667V7.3667M7.73108 10.3667C6.7982 10.362 5.89708 10.7052 5.20367 11.3293C4.51026 11.9534 4.07436 12.8135 3.98108 13.7417C5.08638 14.4738 6.3828 14.8642 7.70858 14.8642C9.03436 14.8642 10.3308 14.4738 11.4361 13.7417C11.3436 12.8212 10.9141 11.9674 10.2302 11.3444C9.54626 10.7214 8.65619 10.3732 7.73108 10.3667Z"
                                  stroke="var(--selected-route-color-svg)"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M14.0911 10.3666C13.7586 11.3161 13.215 12.1778 12.5011 12.8866C11.6231 13.7653 10.5197 14.3847 9.31234 14.6767C8.10498 14.9686 6.84047 14.9217 5.65804 14.5412C4.47561 14.1606 3.42112 13.4612 2.61066 12.5198C1.8002 11.5785 1.2652 10.4318 1.06458 9.20594C0.863954 7.98009 1.00548 6.72265 1.47357 5.57206C1.94167 4.42148 2.71817 3.42237 3.7176 2.68474C4.71703 1.94711 5.90062 1.49956 7.13805 1.39138C8.37549 1.28319 9.61879 1.51856 10.7311 2.07157"
                                  stroke="var(--selected-route-color-svg)"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </li>
                          )}
                      </>
                    )}
                  </>
                )}

                {/* <li className="position-relative">
                  {" "}
                  <a onClick={handleShow} className="notification-badge-main">
                    <img
                      src={NotifyIcon}
                      width={22}
                      height={22}
                      alt="notify-icon"
                    />
                    {notification?.unReadNotificationCount?.data > 0 && (
                      <span className=" notification-badge" pill bg="danger">
                        {notification?.unReadNotificationCount?.data}
                      </span>
                    )}
                  </a>{" "}
                </li> */}
                <li>
                  <Dropdown as={ButtonGroup}>
                    <Dropdown.Toggle
                      variant="secondary"
                      id="color-dropdown"
                      className="no-arrow-dd"
                    >
                      <a
                        onClick={async () => {
                          // localStorage.removeItem("access_token");
                          // navigate("/login");
                        }}
                      >
                        {" "}
                        {userProfile?.profileImage ? (
                          <img
                            src={userProfile?.profileImage}
                            alt="user profile"
                            className="userProfileImageHeader"
                          />
                        ) : (
                          <span>
                            {props?.user?.userName?.slice(0, 2).toUpperCase()}
                          </span>
                        )}
                      </a>
                    </Dropdown.Toggle>{" "}
                    <Dropdown.Menu className="profile-dropdown-menu">
                      <div className="profile-dropdown-profile px-3 py-2 d-flex align-items-center">
                        {props?.user?.profileImage ? (
                          <img
                            src={props?.user?.profileImage}
                            alt={props?.user?.userName}
                            className="profile-image-large"
                          />
                        ) : (
                          <span className="profile-dropdown-user-image-none">
                            {props?.user?.userName?.slice(0, 2).toUpperCase()}
                          </span>
                        )}
                        <div className="ms-3">
                          <div className="profile-dropdown-username">
                            {props?.user?.userName}
                          </div>
                          <div className="profile-dropdown-email">
                            {props?.user?.emailAddress}
                          </div>
                        </div>
                      </div>
                      <Dropdown.Item
                        onClick={() => {
                          if (props?.user?.emailVerified === 0) {
                            setShowVerifyEmailModal(true);
                          } else {
                            navigate(
                              `/${localStorage.getItem("company")}/profile`
                            );
                          }
                        }}
                        className={`d-flex align-items-center gap-2 py-2`}
                      >
                        <div className="profile-dropdown-item-icon-circle">
                          <Icon icon={"gg:profile"} width={20} height={20} />
                        </div>
                        <div>
                          <div className="profile-dropdown-item-title">
                            Profile
                          </div>
                          <div className="profile-dropdown-item-descp">
                            Customize your profile effortlessly
                          </div>
                        </div>
                      </Dropdown.Item>

                      {userProfile?.roles?.some(
                        (obj) => obj.roleType === "admin"
                      ) && (
                        <Dropdown.Item
                          onClick={() => {
                            if (props?.user?.emailVerified === 0) {
                              setShowVerifyEmailModal(true);
                            } else {
                              navigate(
                                `/${localStorage.getItem(
                                  "company"
                                )}/company-employees`
                              );
                            }
                          }}
                          className={`d-flex align-items-center gap-2 py-2`}
                        >
                          <div className="profile-dropdown-item-icon-circle">
                            <Icon
                              icon={"ph:users-three"}
                              width={20}
                              height={20}
                            />
                          </div>
                          <div>
                            <div className="profile-dropdown-item-title">
                              Members
                            </div>
                            <div className="profile-dropdown-item-descp">
                              Keep your members in sync
                            </div>
                          </div>
                        </Dropdown.Item>
                      )}
                      {/* {props?.user?.canInvite === 1 && (
                        <>
                          {props?.user?.roles[0]?.roleType
                            ?.toString()
                            .toLowerCase() === "admin" ? (
                            <>
                              <Dropdown.Item
                                onClick={() => {
                                  if (props?.user?.emailVerified === 0) {
                                    setShowVerifyEmailModal(true);
                                  } else {
                                    setShowModal(true);
                                  }
                                }}
                              >
                                Invite
                              </Dropdown.Item>
                            </>
                          ) : (
                            <>
                              {registeredUserCount &&
                                props?.user?.packageDetails?.seatCount -
                                  (registeredUserCount || 0) >
                                  0 && (
                                  <Dropdown.Item
                                    onClick={() => {
                                      if (props?.user?.emailVerified === 0) {
                                        setShowVerifyEmailModal(true);
                                      } else {
                                        setShowModal(true);
                                      }
                                    }}
                                  >
                                    Invite
                                  </Dropdown.Item>
                                )}
                            </> 
                          )}
                        </>
                      )} */}
                      {companiesList && companiesList?.length > 1 && (
                        <Dropdown.Item
                          onClick={() => {
                            setSwitchCompany(true);
                          }}
                          className={`d-flex align-items-center gap-2 py-2`}
                        >
                          <div className="profile-dropdown-item-icon-circle">
                            <Icon
                              icon={"fluent:arrow-swap-28-regular"}
                              width={20}
                              height={20}
                            />
                          </div>
                          <div>
                            <div className="profile-dropdown-item-title">
                              Switch Account
                            </div>
                            <div className="profile-dropdown-item-descp">
                              Switch between accounts in just a tap
                            </div>
                          </div>
                        </Dropdown.Item>
                      )}
                      <Dropdown.Item
                        onClick={async () => {
                          Cookies.remove("access_token");
                          Cookies.remove("permissions");
                          Cookies.remove("company");
                          await localStorage.removeItem("access_token");
                          await localStorage.removeItem("company");
                          await localStorage.clear();
                          await sessionStorage.clear();
                          dispatch(clearTaskListAction());
                          dispatch(clearProjectListAction());
                          dispatch(clearActiveProjectListAction());
                          dispatch(clearOngoingTasksListAction());
                          dispatch(clearTimelineStats());
                          dispatch(emptyTaskTypesListAction());
                          dispatch(emptyProjectFormListingAction());
                          dispatch(emptyProjectListingStatusAction());
                          dispatch(emptyProjectListingAllStatusAction());
                          dispatch(emptyProjectTypeListingAction());
                          dispatch(emptyProjectUserListingAction());
                          dispatch(emptyTaskPrioritiesListAction());
                          dispatch(emptyTeamsListAction());
                          dispatch(clearCachedTasks());
                          dispatch(clearProjectCache());
                          dispatch(clearCompletedTask());
                          dispatch(clearSubscriptionData());
                          dispatch(projectCompletedClearState());
                          await clearAllCache().unwrap();
                          await clearAllCompletedTaskCache().unwrap();
                          saveLocalStorageToCookies();
                        }}
                        className={`d-flex align-items-center gap-2 py-2`}
                      >
                        <div className="profile-dropdown-item-icon-circle">
                          <Icon
                            icon={"solar:logout-outline"}
                            width={20}
                            height={20}
                          />
                        </div>
                        <div>
                          <div className="profile-dropdown-item-title">
                            Logout
                          </div>
                        </div>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {props.mobileOpt && (
          <div className={`header-top-right-nav top-mobile-options`}>
            <hr className="hr-header" />
            <ul>
              <li>
                <ModuleDropdown />
              </li>
              <li>
                 <svg
                    onClick={() => setShowFaqModal(true)}
                    xmlns="http://www.w3.org/2000/svg"
                    width="23"
                    height="23"
                    viewBox="0 0 16 16"
                    fill="none"
                    style={{ marginTop: "4px" }}
                  >
                    <circle
                      cx="7.03613"
                      cy="7.26465"
                      r="6.375"
                      stroke="var(--selected-route-color-svg)"
                    />
                    <circle
                      cx="7.03678"
                      cy="11.5143"
                      r="0.354167"
                      fill="var(--selected-route-color-svg)"
                    />
                    <path
                      d="M7.03613 10.0977V9.49077C7.03613 8.98486 7.28897 8.51243 7.70991 8.2318L8.13383 7.94919C8.77563 7.52132 9.16113 6.80101 9.16113 6.02965V5.84766C9.16113 4.67405 8.20974 3.72266 7.03613 3.72266V3.72266C5.86253 3.72266 4.91113 4.67405 4.91113 5.84766V5.84766"
                      stroke="var(--selected-route-color-svg)"
                    />
                  </svg>
              </li>
              {props?.user?.canInvite === 1 && (
                <>
                  {props?.user?.roles[0]?.roleType?.toString().toLowerCase() ===
                  "admin" ? (
                    <>
                      <li>
                        {" "}
                        <svg
                          onClick={() => {
                            if (props?.user?.emailVerified === 0) {
                              setShowVerifyEmailModal(true);
                            } else {
                              if (!isTrialActive(props.user)) {
                                props.setOpenSubscriptionsModal(true);
                              } else {
                                setShowModal(true);
                              }
                            }
                          }}
                          xmlns="http://www.w3.org/2000/svg"
                          width="22"
                          height="22"
                          viewBox="0 0 16 16"
                          fill="none"
                        >
                          <path
                            d="M8.48104 4.46431C8.23674 4.39736 7.98434 4.36455 7.73104 4.36681C7.2043 4.36627 6.6867 4.50443 6.2303 4.76739C5.77389 5.03035 5.39476 5.40884 5.13104 5.86481C4.86732 6.32078 4.7283 6.83814 4.72797 7.36488C4.72763 7.89162 4.86599 8.40917 5.12912 8.86547C5.39226 9.32178 5.7709 9.70076 6.22697 9.9643C6.68304 10.2278 7.20046 10.3667 7.7272 10.3668C8.25394 10.3669 8.77143 10.2284 9.22763 9.96507C9.68384 9.70176 10.0627 9.32298 10.326 8.86681"
                            stroke="var(--selected-route-color-svg)"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M11.4811 5.8667H14.4811M12.9811 4.3667V7.3667M7.73108 10.3667C6.7982 10.362 5.89708 10.7052 5.20367 11.3293C4.51026 11.9534 4.07436 12.8135 3.98108 13.7417C5.08638 14.4738 6.3828 14.8642 7.70858 14.8642C9.03436 14.8642 10.3308 14.4738 11.4361 13.7417C11.3436 12.8212 10.9141 11.9674 10.2302 11.3444C9.54626 10.7214 8.65619 10.3732 7.73108 10.3667Z"
                            stroke="var(--selected-route-color-svg)"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M14.0911 10.3666C13.7586 11.3161 13.215 12.1778 12.5011 12.8866C11.6231 13.7653 10.5197 14.3847 9.31234 14.6767C8.10498 14.9686 6.84047 14.9217 5.65804 14.5412C4.47561 14.1606 3.42112 13.4612 2.61066 12.5198C1.8002 11.5785 1.2652 10.4318 1.06458 9.20594C0.863954 7.98009 1.00548 6.72265 1.47357 5.57206C1.94167 4.42148 2.71817 3.42237 3.7176 2.68474C4.71703 1.94711 5.90062 1.49956 7.13805 1.39138C8.37549 1.28319 9.61879 1.51856 10.7311 2.07157"
                            stroke="var(--selected-route-color-svg)"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </li>
                    </>
                  ) : (
                    <>
                      {registeredUserCount &&
                        props?.user?.packageDetails?.seatCount -
                          (registeredUserCount || 0) >
                          0 && (
                          <li>
                            {" "}
                            <svg
                              onClick={() => {
                                if (props?.user?.emailVerified === 0) {
                                  setShowVerifyEmailModal(true);
                                } else {
                                  if (!isTrialActive(props.user)) {
                                    props.setOpenSubscriptionsModal(true);
                                  } else {
                                    setShowModal(true);
                                  }
                                }
                              }}
                              xmlns="http://www.w3.org/2000/svg"
                              width="22"
                              height="22"
                              viewBox="0 0 16 16"
                              fill="none"
                            >
                              <path
                                d="M8.48104 4.46431C8.23674 4.39736 7.98434 4.36455 7.73104 4.36681C7.2043 4.36627 6.6867 4.50443 6.2303 4.76739C5.77389 5.03035 5.39476 5.40884 5.13104 5.86481C4.86732 6.32078 4.7283 6.83814 4.72797 7.36488C4.72763 7.89162 4.86599 8.40917 5.12912 8.86547C5.39226 9.32178 5.7709 9.70076 6.22697 9.9643C6.68304 10.2278 7.20046 10.3667 7.7272 10.3668C8.25394 10.3669 8.77143 10.2284 9.22763 9.96507C9.68384 9.70176 10.0627 9.32298 10.326 8.86681"
                                stroke="var(--selected-route-color-svg)"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M11.4811 5.8667H14.4811M12.9811 4.3667V7.3667M7.73108 10.3667C6.7982 10.362 5.89708 10.7052 5.20367 11.3293C4.51026 11.9534 4.07436 12.8135 3.98108 13.7417C5.08638 14.4738 6.3828 14.8642 7.70858 14.8642C9.03436 14.8642 10.3308 14.4738 11.4361 13.7417C11.3436 12.8212 10.9141 11.9674 10.2302 11.3444C9.54626 10.7214 8.65619 10.3732 7.73108 10.3667Z"
                                stroke="var(--selected-route-color-svg)"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M14.0911 10.3666C13.7586 11.3161 13.215 12.1778 12.5011 12.8866C11.6231 13.7653 10.5197 14.3847 9.31234 14.6767C8.10498 14.9686 6.84047 14.9217 5.65804 14.5412C4.47561 14.1606 3.42112 13.4612 2.61066 12.5198C1.8002 11.5785 1.2652 10.4318 1.06458 9.20594C0.863954 7.98009 1.00548 6.72265 1.47357 5.57206C1.94167 4.42148 2.71817 3.42237 3.7176 2.68474C4.71703 1.94711 5.90062 1.49956 7.13805 1.39138C8.37549 1.28319 9.61879 1.51856 10.7311 2.07157"
                                stroke="var(--selected-route-color-svg)"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </li>
                        )}
                    </>
                  )}
                </>
              )}
              {/* <li className="position-relative">
                {" "}
                <a onClick={handleShow} className="notification-badge-main">
                  <img
                    src={NotifyIcon}
                    width={22}
                    height={22}
                    alt="notify-icon"
                  />
                  {notification?.unReadNotificationCount?.data > 0 && (
                    <span className=" notification-badge" pill bg="danger">
                      {notification?.unReadNotificationCount?.data}
                    </span>
                  )}
                </a>{" "}
              </li> */}

              <li>
                <Dropdown as={ButtonGroup}>
                  <Dropdown.Toggle
                    variant="secondary"
                    id="color-dropdown"
                    className="no-arrow-dd"
                  >
                    <a
                      onClick={async () => {
                        // localStorage.removeItem("access_token");
                        // navigate("/login");
                      }}
                    >
                      {" "}
                      {userProfile?.profileImage ? (
                        <img
                          src={userProfile?.profileImage}
                          alt="user profile"
                          className="userProfileImageHeader"
                        />
                      ) : (
                        <span className="profile-dropdown-user-image-none">
                          {props?.user?.userName?.slice(0, 2).toUpperCase()}
                        </span>
                      )}
                    </a>
                  </Dropdown.Toggle>{" "}
                  <Dropdown.Menu className="profile-dropdown-menu">
                    <div className="profile-dropdown-profile px-3 py-2 d-flex align-items-center">
                      {props?.user?.profileImage ? (
                        <img
                          src={props?.user?.profileImage || "/placeholder.svg"}
                          alt={props?.user?.userName}
                          className="profile-image-large"
                        />
                      ) : (
                        <span>
                          {props?.user?.userName?.slice(0, 2).toUpperCase()}
                        </span>
                      )}
                      <div className="ms-3">
                        <div className="profile-dropdown-username">
                          {props?.user?.userName}
                        </div>
                        <div className="profile-dropdown-email">
                          {props?.user?.emailAddress}
                        </div>
                      </div>
                    </div>
                    <Dropdown.Item
                      onClick={() => {
                        if (props?.user?.emailVerified === 0) {
                          setShowVerifyEmailModal(true);
                        } else {
                          navigate(
                            `/${localStorage.getItem("company")}/profile`
                          );
                        }
                      }}
                      className={`d-flex align-items-center gap-2 py-2`}
                    >
                      <div className="profile-dropdown-item-icon-circle">
                        <Icon icon={"gg:profile"} width={20} height={20} />
                      </div>
                      <div>
                        <div className="profile-dropdown-item-title">
                          Profile
                        </div>
                        <div className="profile-dropdown-item-descp">
                          Customize your profile effortlessly
                        </div>
                      </div>
                    </Dropdown.Item>

                    {userProfile?.roles?.some(
                      (obj) => obj.roleType === "admin"
                    ) && (
                      <Dropdown.Item
                        onClick={() => {
                          if (props?.user?.emailVerified === 0) {
                            setShowVerifyEmailModal(true);
                          } else {
                            navigate(
                              `/${localStorage.getItem(
                                "company"
                              )}/company-employees`
                            );
                          }
                        }}
                        className={`d-flex align-items-center gap-2 py-2`}
                      >
                        <div className="profile-dropdown-item-icon-circle">
                          <Icon
                            icon={"ph:users-three"}
                            width={20}
                            height={20}
                          />
                        </div>
                        <div>
                          <div className="profile-dropdown-item-title">
                            Members
                          </div>
                          <div className="profile-dropdown-item-descp">
                            Keep your members in sync
                          </div>
                        </div>
                      </Dropdown.Item>
                    )}
                    {/* {props?.user?.canInvite === 1 && (
                        <>
                          {props?.user?.roles[0]?.roleType
                            ?.toString()
                            .toLowerCase() === "admin" ? (
                            <>
                              <Dropdown.Item
                                onClick={() => {
                                  if (props?.user?.emailVerified === 0) {
                                    setShowVerifyEmailModal(true);
                                  } else {
                                    setShowModal(true);
                                  }
                                }}
                              >
                                Invite
                              </Dropdown.Item>
                            </>
                          ) : (
                            <>
                              {registeredUserCount &&
                                props?.user?.packageDetails?.seatCount -
                                  (registeredUserCount || 0) >
                                  0 && (
                                  <Dropdown.Item
                                    onClick={() => {
                                      if (props?.user?.emailVerified === 0) {
                                        setShowVerifyEmailModal(true);
                                      } else {
                                        setShowModal(true);
                                      }
                                    }}
                                  >
                                    Invite
                                  </Dropdown.Item>
                                )}
                            </> 
                          )}
                        </>
                      )} */}
                    {companiesList && companiesList?.length > 1 && (
                      <Dropdown.Item
                        onClick={() => {
                          setSwitchCompany(true);
                        }}
                        className={`d-flex align-items-center gap-2 py-2`}
                      >
                        <div className="profile-dropdown-item-icon-circle">
                          <Icon
                            icon={"fluent:arrow-swap-28-regular"}
                            width={20}
                            height={20}
                          />
                        </div>
                        <div>
                          <div className="profile-dropdown-item-title">
                            Switch Account
                          </div>
                          <div className="profile-dropdown-item-descp">
                            Switch between accounts in just a tap
                          </div>
                        </div>
                      </Dropdown.Item>
                    )}
                    <Dropdown.Item
                      onClick={async () => {
                        Cookies.remove("access_token");
                        Cookies.remove("permissions");
                        Cookies.remove("company");
                        await localStorage.removeItem("access_token");
                        await localStorage.removeItem("company");
                        await localStorage.clear();
                        await sessionStorage.clear();
                        dispatch(clearTaskListAction());
                        dispatch(clearProjectListAction());
                        dispatch(clearActiveProjectListAction());
                        dispatch(clearOngoingTasksListAction());
                        dispatch(clearTimelineStats());
                        dispatch(emptyTaskTypesListAction());
                        dispatch(emptyProjectFormListingAction());
                        dispatch(emptyProjectListingStatusAction());
                        dispatch(emptyProjectListingAllStatusAction());
                        dispatch(emptyProjectTypeListingAction());
                        dispatch(emptyProjectUserListingAction());
                        dispatch(emptyTaskPrioritiesListAction());
                        dispatch(emptyTeamsListAction());
                        dispatch(clearCachedTasks());
                        dispatch(clearProjectCache());
                        dispatch(clearCompletedTask());
                        dispatch(clearSubscriptionData());
                        dispatch(projectCompletedClearState());
                        await clearAllCache().unwrap();
                        await clearAllCompletedTaskCache().unwrap();
                        saveLocalStorageToCookies();
                      }}
                      className={`d-flex align-items-center gap-2 py-2`}
                    >
                      <div className="profile-dropdown-item-icon-circle">
                        <Icon
                          icon={"solar:logout-outline"}
                          width={20}
                          height={20}
                        />
                      </div>
                      <div>
                        <div className="profile-dropdown-item-title">
                          Logout
                        </div>
                      </div>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </li>
            </ul>
          </div>
        )}
      </div>
      {show && (
        <NotificationSection
          show={show}
          handleClose={handleClose}
          isDark={props.isDark}
          setShowTaskDetail={setShowTaskDetail}
          setSelectedTask={setSelectedTask}
        />
      )}
      {showFaqModal && (
        <FAQModalOffset
          props={props}
          show={showFaqModal}
          setShow={setShowFaqModal}
        />
      )}
      {showModal && (
        <InviteModal
          isDark={props?.isDark}
          showModal={showModal}
          handleCloseModal={handleCloseModal}
          addonModal={addonModal}
          handleAddonClose={handleAddonClose}
          handleAddonShow={handleAddonShow}
          setShowAddModal={setShowAddModal}
          isAdmin={
            props?.user?.canInvite === 1 &&
            props?.user?.roles?.some((role) => role.roleType === "admin")
              ? true
              : false
          }
        />
      )}
      {showVerifyEmailModal && (
        <Modal
          show={showVerifyEmailModal}
          onHide={handleVerifyEmailModalClose}
          centered
          size="md"
          data-theme={props?.isDark}
          backdrop="static"
          keyboard={false}
          className="main-delete-modal"
        >
          {/* <Modal.Header className="border-0" closeButton></Modal.Header> */}

          <Modal.Body className="d-flex align-items-center justify-content-center text-center flex-column update-modal-body">
            <div className="del-modal-img-container">
              <img src={DeleteIcon} alt="info-icon" />
            </div>
            <h5>{verifyModalContent?.model_title}</h5>
            <p>
              {verifyModalContent?.short_description}{" "}
              <a onClick={resendEmail} className="email-verify-anchor">
                {load ? (
                  <Spinner
                    border="animation"
                    style={{ width: "1rem", height: "1rem" }}
                  />
                ) : (
                  "Resend"
                )}
              </a>{" "}
              {verifyModalContent?.long_description}
            </p>
          </Modal.Body>

          <Modal.Footer>
            <div className="w-100 delete-modal-btn-wrapper">
              <button
                className="w-100 delete-btn-1"
                onClick={handleVerifyEmailModalClose}
                disabled={load}
              >
                Close
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      )}
      {addonModal && (
        <AddOnModal
          modal={addonModal}
          handleClose={handleAddonClose}
          isDark={props.isDark}
          planData={planData}
          getPlanData={getPlanData}
        />
      )}
      {showAddModal && (
        <ConfirmAddonModal
          isDark={props.isDark}
          handleAddonShow={handleAddonShow}
          showAddModal={showAddModal}
          setShowAddModal={setShowAddModal}
          billingDetail={billingDetail}
          planData={planData}
        />
      )}
      {switchCompany && (
        <CompanyListModal
          showCompanyModal={switchCompany}
          handleCloseCompanyModal={handleCloseCompanyModal}
          user={userProfile}
          isDark={props.isDark}
          userCompanies={companiesList}
        />
      )}
      {notificationModalOpen && (
        <EnableNotificationModal
          showConfirmModal={notificationModalOpen}
          handleCloseConfirmModal={() => setNotificationModalOpen(false)}
          isDark={props.isDark}
          userProfile={userProfile}
        />
      )}
    </div>
  );
};

export default Header;

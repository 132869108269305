import { useEffect, useState, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import API from "../../../services/ClientApi";
import { userProfilePage } from "../../../actions/userActions";

const useTourAddPerformance = () => {
  const dispatch = useDispatch();
  const [runTour, setRunTour] = useState(false);
  const { userProfile } = useSelector((state) => state?.profile);
  const isUpdatingRef = useRef(false); // Prevent multiple API calls

  useEffect(() => {
    if (userProfile?.performanceDetailTour) {
      setTimeout(() => setRunTour(true), 1000); // Delay starting the tour
    }
  }, [userProfile]);

  const updateProfile = useCallback(async () => {
    if (isUpdatingRef.current) return; // Prevent duplicate calls
    isUpdatingRef.current = true;

    try {
      setRunTour(false);
      const response = await API.post("users/update-profile", {
        userId: userProfile?.userId,
        fieldName: "performanceDetailTour",
        fieldValues: false,
      });
      if (response?.status === 200) {
        await dispatch(userProfilePage());
      }
    } catch (error) {
      console.error("Error updating profile:", error);
    } finally {
      isUpdatingRef.current = false; // Reset flag
    }
  }, [dispatch, userProfile?.userId]);

  useEffect(() => {
    const handlePrevClick = async (event) => {
      if (event.target.classList.contains("introjs-prevbutton")) {
        await updateProfile();
      }
    };

    document.addEventListener("click", handlePrevClick);
    return () => {
      document.removeEventListener("click", handlePrevClick);
    };
  }, []);

  return { runTour, setRunTour, updateProfile };
};

export default useTourAddPerformance;

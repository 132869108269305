import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
// eslint-disable-next-line no-unused-vars
import ProtectedRoute from "./components/ProtectedRoute.jsx";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Profile from "./pages/Profile/Profile.jsx";
import LeadsNew from "./pages/LeadsNew/LeadsNew.jsx";
import Payment from "./pages/Payment/Payment.jsx";
import UserProfile from "./pages/UserProfile/UserProfile.jsx";
import Roles from "./pages/Roles/Roles.jsx";
// import Subscriptions from "./pages/Subscriptions/Subscriptions";
import SubscriptionsUpdated from "./pages/SubscriptionsUpdated/Subscriptions.jsx";
import PageNotFound from "./pages/PageNotFound/PageNotFound.jsx";
import ModulePage from "./pages/ModulePage/ModulePage.jsx";
import DayTimeStartModal from "./components/DayTimeStartModal/DayTimeStartModal.jsx";
import DayTimeEndModal from "./components/DayTimeEndModal/DayTimeEndModal.jsx";
import CompanyProfileMain from "./pages/CompanyProfile/CompanyProfileMain.jsx";
// import NotificationPermissionPopup from "./components/NotificationPermissionPopup.jsx";
import InternetConnectionCheck from "./components/InternetConnectionCheck.jsx";
import PerformanceSystem from "./pages/PerformanceSystem/PerformanceSystem.jsx";
import "./styles/auth.scss";
import "./styles/roles.css";
import "./styles/subscriptions.css";
import Cookies from "js-cookie";
function App() {
  const noop = () => {};
  const cookies_token = Cookies.get("access_token");
  const permissions = Cookies.get("permissions");
  const company = Cookies.get("company");

  if (cookies_token) {
    localStorage.setItem("access_token", cookies_token);
  }
  if (permissions) {
    localStorage.setItem("permissions", permissions);
  }
  if (company) {
    localStorage.setItem("company", company);
  }

  Cookies.remove("access_token");
  Cookies.remove("permissions");
  Cookies.remove("company");

 
  // // Disable specific console methods
  [
    "log",
    "warn",
    "error",
    "info",
    "debug",
    "table",
    "group",
    "groupEnd",
    "time",
    "timeEnd",
    "trace",
    "assert",
    "clear",
    "count",
    "countReset",
    "dir",
    "dirxml",
    "profile",
    "profileEnd",
  ].forEach((method) => {
    console[method] = noop;
  });

  const [companySlug, setCompanySlug] = useState("");
  const [collapse, setCollapse] = useState(false);
  const [user, setUser] = useState(
    sessionStorage.getItem("user")
      ? JSON.parse(sessionStorage.getItem("user"))
      : {
          full_name: "",
          email_address: "",
          password: "",
          passwordConfirm: "",
          company_name: "",
          industry_id: 0,
          package_id: "",
          modules_category_ids: [],
          login_token: "",
          login_type: "",
          seatCount: 3,
          interval: "",
        }
  );
  const [isDark, setIsDark] = useState(
    // localStorage.getItem("isDark")
    //   ? localStorage.getItem("isDark").replace(/["']/g, "") === "default"
    //     ? "default"
    //     : localStorage.getItem("isDark").replace(/["']/g, "") === "color1"
    //     ? "color1"
    //     : localStorage.getItem("isDark").replace(/["']/g, "") === "color2"
    //     ? "color2"
    //     : localStorage.getItem("isDark").replace(/["']/g, "") === "color3"
    //     ? "color3"
    //     : localStorage.getItem("isDark").replace(/["']/g, "") === "color4"
    //     ? "color4"
    //     : localStorage.getItem("isDark").replace(/["']/g, "") === "color5"
    //     ? "color5"
    //     : localStorage.getItem("isDark").replace(/["']/g, "") === "color6"
    //     ? "color6"
    //     : localStorage.getItem("isDark").replace(/["']/g, "") === "color7"
    //     ? "color7"
    //     : "default"
    //   : "default"
    "default"
  );
  useEffect(() => {
    const handleStorageChange = async () => {
      // setIsDark(
      // localStorage.getItem("isDark")
      //   ? localStorage.getItem("isDark").replace(/["']/g, "") === "default"
      //     ? "default"
      //     : localStorage.getItem("isDark").replace(/["']/g, "") === "color1"
      //     ? "color1"
      //     : localStorage.getItem("isDark").replace(/["']/g, "") === "color2"
      //     ? "color2"
      //     : localStorage.getItem("isDark").replace(/["']/g, "") === "color3"
      //     ? "color3"
      //     : localStorage.getItem("isDark").replace(/["']/g, "") === "color4"
      //     ? "color4"
      //     : localStorage.getItem("isDark").replace(/["']/g, "") === "color5"
      //     ? "color5"
      //     : localStorage.getItem("isDark").replace(/["']/g, "") === "color6"
      //     ? "color6"
      //     : localStorage.getItem("isDark").replace(/["']/g, "") === "color7"
      //     ? "color7"
      //     : "default"
      //   : "default"
      // );
      setIsDark("default");
      // Add your logic to handle the change here
    };

    // Add storage event listener
    window.addEventListener("storage", handleStorageChange);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {}, [localStorage.getItem("company")]);

  useEffect(() => {
    if (!localStorage.getItem("access_token")) {
      const manageDomain = process.env.REACT_APP_AUTH_URL;
      window.location.href = `${manageDomain}/login`; // This reloads the page
    }
  }, [localStorage.getItem("access_token")]); // Dependency is causing infinite re-renders


  return (
    <BrowserRouter>
      <ToastContainer />
      <InternetConnectionCheck isDark={isDark} />
      <DayTimeStartModal isDark={isDark} />
      <DayTimeEndModal isDark={isDark} />
      {/* <NotificationPermissionPopup isDark={isDark} /> */}

      <Routes>
        <Route
          path={`/`}
          element={
            <ProtectedRoute
              isDark={isDark}
              collapse={collapse}
              setCollapse={setCollapse}
              leads={true}
              children={<Profile isDark={isDark} />}
            />
          }
        />
        <Route
          path={`/${companySlug || localStorage.getItem("company")}/`}
          element={
            <ProtectedRoute
              isDark={isDark}
              collapse={collapse}
              setCollapse={setCollapse}
              leads={true}
              children={<Profile isDark={isDark} />}
            />
          }
        />
        <Route
          path={`/${
            companySlug || localStorage.getItem("company")
          }/company-profile`}
          element={
            <ProtectedRoute
              isDark={isDark}
              collapse={collapse}
              setCollapse={setCollapse}
              children={<CompanyProfileMain isDark={isDark} />}
            />
          }
        />

        <Route
          path={`/${
            companySlug || localStorage.getItem("company")
          }/performance-system`}
          element={
            <ProtectedRoute
              isDark={isDark}
              collapse={collapse}
              setCollapse={setCollapse}
              leads={true}
              children={<PerformanceSystem isDark={isDark} />}
            />
          }
        />
        <Route
          path={`/${
            companySlug || localStorage.getItem("company")
          }/company-employees`}
          element={
            <ProtectedRoute
              isDark={isDark}
              collapse={collapse}
              setCollapse={setCollapse}
              leads={true}
              children={<LeadsNew isDark={isDark} />}
            />
          }
        />

        <Route
          path={`/${companySlug || localStorage.getItem("company")}/profile`}
          element={
            <ProtectedRoute
              isDark={isDark}
              collapse={collapse}
              setCollapse={setCollapse}
              leads={true}
              children={<Profile isDark={isDark} />}
            />
          }
        />
        <Route
          path={`/${
            companySlug || localStorage.getItem("company")
          }/user-profile`}
          element={
            <ProtectedRoute
              isDark={isDark}
              collapse={collapse}
              setCollapse={setCollapse}
              leads={true}
              children={<UserProfile isDark={isDark} />}
            />
          }
        />
        <Route
          path={`/${
            companySlug || localStorage.getItem("company")
          }/company-roles`}
          element={
            <ProtectedRoute
              isDark={isDark}
              collapse={collapse}
              setCollapse={setCollapse}
              leads={true}
              children={<Roles isDark={isDark} />}
            />
          }
        />
        <Route
          path={`/${
            companySlug || localStorage.getItem("company")
          }/company-subscriptions`}
          element={
            <ProtectedRoute
              isDark={isDark}
              collapse={collapse}
              setCollapse={setCollapse}
              leads={true}
              children={<SubscriptionsUpdated isDark={isDark} />}
            />
          }
        />
        <Route
          path={`/${companySlug || localStorage.getItem("company")}/plugins`}
          element={
            <ProtectedRoute
              isDark={isDark}
              collapse={collapse}
              setCollapse={setCollapse}
              leads={true}
              children={<ModulePage isDark={isDark} />}
            />
          }
        />
        <Route path="/payment" element={<Payment isDark={isDark} />} />
        <Route path={`*`} element={<PageNotFound isDark={isDark} />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;

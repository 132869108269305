/* eslint-disable */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Modal, Spinner } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useState } from "react";
import NoInternetIcon from "../../assets/day-start-icon.png";
import { toast } from "react-toastify";
import "./style.css";
import { useSelector } from "react-redux";
import { usePostUpdateDailyLogsMutation } from "../../slices/user/userSlice";
import { userProfilePage } from "../../actions/userActions";
import { useDispatch } from "react-redux";

function DayTimeStartModal({ isDark }) {
  const dispatch = useDispatch();

  const [isShow, setIsShow] = useState(false);
  const { userProfile, loading } = useSelector((state) => state?.profile);
  const [updateDailyLogs, { isLoading }] = usePostUpdateDailyLogsMutation();

  useEffect(() => {
    const currentDate = new Date();
    const currentHour = currentDate.getHours();
    const currentDay = currentDate.toISOString().split("T")[0];
    const currentDayName = currentDate.toLocaleString('en-US', { weekday: 'long' }); // Get the current day as a string

    // Get the last shown date from local storage
    const lastShownDate =
      userProfile?.dayStartTime?.length > 0
        ? new Date(userProfile?.dayStartTime)?.toISOString()?.split("T")[0]
        : null;
    console.log("daystartmodal", [
      currentHour,
      currentDay,
      lastShownDate,
      currentHour >= 9 && currentHour <= 18 && lastShownDate !== currentDay,
    ]);

    // Check if it's past 9 AM and if the modal hasn't been shown today
    if (
      userProfile?.shiftStartTime &&
      userProfile?.shiftEndTime &&
      userProfile?.workingDays
    ) {
      const workingDaysArray = userProfile?.workingDays?.split(",") || [];
      if (
        currentHour >= parseInt(userProfile?.shiftStartTime?.split(":")[0]) &&
        currentHour <= parseInt(userProfile?.shiftEndTime?.split(":")[0]) &&
        lastShownDate !== currentDay &&
        workingDaysArray.includes(currentDayName) &&
        userProfile &&
        !loading
      ) {
        setIsShow(true); // Show the modal
        // Update the last shown date
      }
    }
  }, [userProfile]);

  const handleButtonClick = async () => {
    try {
      const currentDate = new Date().toISOString(); // Get the current date in YYYY-MM-DD format

      const response = await updateDailyLogs({ dayStart: currentDate }); // Call your API
      if (response.error) {
        throw new Error(response.error?.data?.message); // Handle error response
      }
      if (response) {
        const currentDate = new Date();
        const currentDay = currentDate.toISOString().split("T")[0];
        localStorage.setItem("lastShownStartDate", currentDay);
        toast.success(
          response?.data?.message || "Daily logs updated successfully!"
        ); // Show success toast
        setIsShow(false); // Close the modal
        await dispatch(userProfilePage());
      }
    } catch (error) {
      toast.error(`${error.message || "Failed to update daily logs."}`); // Show error toast
    }
  };

  return (
    <Modal
      show={isShow}
      className="day-time-start-modal"
      data-theme={isDark}
      centered
    >
      <Modal.Body className="d-flex align-items-center justify-content-center text-center flex-column update-modal-body">
        <div className="del-modal-img-container">
          <img
            src={NoInternetIcon}
            alt="no-internet-icon"
            width={220}
            height={220}
          />
        </div>
        <h5 className="day-time-start-modal-heading">Get Tasks Rolling</h5>
        <p className="mt-2 ps-4 pe-4">
          Stay organized, prioritize with ease, and make your day productive!
        </p>
        <div className="w-100 delete-modal-btn-wrapper">
          <button
            style={{ borderRadius: "4px" }}
            className="w-100 delete-btn-1 rounded-lg mt-4"
            onClick={handleButtonClick}
            disabled={isLoading}
          >
            Go to my today’s tasks {isLoading && <Spinner size="sm" />}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default DayTimeStartModal;

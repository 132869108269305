import moment from "moment-timezone";

/**
 * Formats a given date string into various formats based on the type provided.
 *
 * @param {string} dateString - The date string to be formatted (e.g., "2024-10-22T12:01:00").
 * @param {number} type - The format type to be applied:
 *                        1: "Oct 22, 2024"
 *                        2: "Oct 22, 2024" (with zeroed time added)
 *                        3: "Oct 22, 2024, 12:01 PM"
 *                        4: "Oct 22, 2024 - 12:01 PM"
 * @returns {string} - The formatted date string.
 */
export const dateFormatter = (dateString, type) => {
  // Default case: For all types except 2, 3, and 4, use a simple date format.
  if (type !== 2 && type !== 3 && type !== 4) {
    // Define options for formatting the date as "Oct 22, 2024".
    const options = { day: "numeric", month: "short", year: "numeric" };

    // Format the given date string and return the result.
    return new Date(dateString).toLocaleDateString("en-US", options);
  }
  // Type 2: Same date format as above, but ensures the time is zeroed out (UTC time).
  else if (type === 2) {
    // Define the same date format options as above.
    const options = { day: "numeric", month: "short", year: "numeric" };

    // Add "T00:00:00Z" to the date string to ensure the time is set to midnight in UTC.
    return new Date(`${dateString}T00:00:00Z`).toLocaleDateString(
      "en-US",
      options
    );
  }
  // Type 3: Format the date with both date and time (e.g., "Oct 22, 2024, 12:01 PM").
  else if (type === 3) {
    // Define options to include date along with hour and minute.
    const options = {
      day: "numeric",
      month: "short",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
    };

    // Format the given date with the specified options and return it.
    return new Date(dateString).toLocaleDateString("en-US", options);
  }
  // Type 4: Custom format "Oct 22, 2024 - 12:01 PM".
  else if (type === 4) {
    // Separate options for the date part.
    const dateOptions = { day: "numeric", month: "short", year: "numeric" };

    // Separate options for the time part (12-hour format with minutes).
    const timeOptions = { hour: "numeric", minute: "numeric" };

    // Format the date part as "Oct 22, 2024".
    const datePart = new Date(dateString).toLocaleDateString(
      "en-US",
      dateOptions
    );

    // Format the time part as "12:01 PM", ensuring 12-hour format.
    const timePart = new Date(dateString).toLocaleTimeString("en-US", {
      ...timeOptions,
      hour12: true, // Enforces 12-hour time format with AM/PM.
    });

    // Combine the formatted date and time parts with a dash separator and return it.
    return `${datePart} - ${timePart}`;
  }
};

// ISO conversion
export const convertToISO = (dateString) => {
  // Parse the date string
  const date = new Date(dateString);

  // Convert to ISO 8601 format with UTC (Z)
  return date.toISOString();
};

// Helper function to format the deadline date
export const formatDeadlineDate = (dateString) => {
  const options = { year: "numeric", month: "short", day: "numeric" };
  return new Date(dateString).toLocaleDateString("en-US", options);
};

// "2024-11-14" format
export const formatDateToYYYYMMDD = (date) => {
  if (!date) return ""; // Handle null or undefined values gracefully
  const d = new Date(date);
  const year = d.getFullYear();
  const month = String(d.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const day = String(d.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

// Convert to default date formatt of flatpickr
export function formatDefaultDate(dateString) {
  // Parse the input date
  const date = new Date(dateString);

  // Define options for formatting
  const options = {
    weekday: "short", // Short weekday name (e.g., "Wed")
    year: "numeric", // Full numeric year (e.g., "2024")
    month: "short", // Short month name (e.g., "Nov")
    day: "numeric", // Numeric day (e.g., "27")
    hour: "2-digit", // Hour with 2 digits (e.g., "00")
    minute: "2-digit", // Minute with 2 digits (e.g., "00")
    second: "2-digit", // Second with 2 digits (e.g., "00")
    timeZone: "Asia/Karachi", // Time zone for Pakistan Standard Time
    timeZoneName: "short", // Short time zone name (e.g., "PKT")
  };

  // Format the date
  const formattedDate = new Intl.DateTimeFormat("en-US", options).formatToParts(
    date
  );

  // Combine parts into the desired format
  const dateParts = formattedDate.reduce((acc, part) => {
    acc[part.type] = part.value;
    return acc;
  }, {});

  // Construct the desired string
  return `${dateParts.weekday} ${dateParts.month} ${dateParts.day} ${dateParts.year} ${dateParts.hour}:${dateParts.minute}:${dateParts.second} GMT+0500 (Pakistan Standard Time)`;
}

// Output: Dec 30, 2024, 2:00 pm
export function formatCustomDate(dateString, time) {
  const date = moment(dateString).toISOString();

  // Get the month, day, year, hours, and minutes
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const month = months[parseInt(date?.split("T")[0]?.split("-")[1])-1]; // Get month (0-11)
  const day = date?.split("T")[0]?.split("-")[2]; // Get day (1-31)
  const year = date?.split("T")[0]?.split("-")[0]; // Get full year (YYYY)
  
  let hours = parseInt(time?.split(":")[0]); // Get hours (0-23)
  const minutes = parseInt(time?.split(":")[1]); // Get minutes (0-59)

  // Determine AM/PM and adjust hours for 12-hour format
  const ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours >= 12 ? hours - 12: hours; // Convert to 12-hour format
  hours = hours ? hours : 12; // If hours is 0, set it to 12
  hours = hours < 10 ? `0${hours}`: hours;

  // Format minutes to always have two digits
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  
  // Format the final string
  return `${month} ${day}, ${year} - ${hours}:${formattedMinutes} ${ampm}`;
}


/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useMemo } from "react";
import countryList from "react-select-country-list";
import API from "../../../services/ClientApi";
import Select, { components } from "react-select";
import { IoIosArrowDown } from "react-icons/io";
import Timezone from "./Timezone";

const Language = (props) => {
  const [language, setLanguage] = useState(
    props?.user?.updatedProfileDetail?.language
      ? props?.user?.updatedProfileDetail?.language
      : "English"
  );
  const [country, setCountry] = useState(
    props?.user?.updatedProfileDetail?.country
      ? props?.user?.updatedProfileDetail?.country
      : "Pakistan"
  );
  const options = useMemo(() => countryList().getData(), []);
  const countryLabels = useMemo(
    () => options.map((country) => country.label),
    [options]
  );

  // Extract labels with both language and country in the format "English, UK"
  const languageLabels = [
    { label: "English, UK", value: "English" },
    { label: "Urdu, UR", value: "Urdu" },
    { label: "French, FR", value: "French" },
    { label: "Arabic, AR", value: "Arabic" },
    { label: "German, DE", value: "German" },
  ];

  const handleLanguageChange = async (value, field) => {
    setLanguage(value);
    try {
      await API.post("users/update-profile", {
        userId: props?.user?.userInfo?.userId,
        fieldName: field, // Add the field name for the specific input field
        fieldValues: value, // Add the corresponding state variable here
      });
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };

  const handleCountryChange = async (value, field) => {
    setCountry(value);
    try {
      await API.post("users/update-profile", {
        userId: props?.user?.userInfo?.userId,
        fieldName: field, // Add the field name for the specific input field
        fieldValues: value, // Add the corresponding state variable here
      });
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };

  return (
    <div className="listing-info-profile-wrap lang-info-profile-wrap">
      <h4>
        Language <span style={{ fontWeight: 300 }}>& Region</span>
      </h4>
      <ul className="row password-row-info-main">
        <li className="col-md-9">
          <div className="overview-profile overview-language">
            <a>
              <div className="overview-profile-image">
                <svg
                  width="25"
                  height="25"
                  viewBox="0 0 11 9"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M0 5.16653C0 4.0821 0 3.53989 0.104468 3.0918C0.450287 1.60849 1.60849 0.450287 3.0918 0.104468C3.53989 0 4.0821 0 5.16653 0H6.31465L6.34753 2.61533e-05C8.54537 0.00797392 10.3251 1.7877 10.333 3.98554L10.3331 4.01842L10.333 4.0513C10.3251 6.24913 8.54537 8.02886 6.34753 8.03681L6.31465 8.03683H2.71045C2.44523 8.03683 2.19088 8.14219 2.00334 8.32972L1.62369 8.70938C1.02451 9.30856 0 8.8842 0 8.03683V5.16653ZM3.44436 2.37028C3.16821 2.37028 2.94436 2.59414 2.94436 2.87028C2.94436 3.14642 3.16821 3.37028 3.44436 3.37028H6.88871C7.16486 3.37028 7.38871 3.14642 7.38871 2.87028C7.38871 2.59414 7.16486 2.37028 6.88871 2.37028H3.44436ZM3.44436 4.66652C3.16821 4.66652 2.94436 4.89037 2.94436 5.16652C2.94436 5.44266 3.16821 5.66652 3.44436 5.66652H5.16653C5.44268 5.66652 5.66653 5.44266 5.66653 5.16652C5.66653 4.89037 5.44268 4.66652 5.16653 4.66652H3.44436Z"
                    fill="currentColor"
                  />
                </svg>
              </div>
              <div className="number-overview-wrap lang-info-num-wrap">
                <strong>Default:</strong>
                {
                  <input
                    value="English, UK"
                    disabled
                    style={{ border: "none" }}
                    //   type="password"
                  />
                }
              </div>
              <div className="clearfix"></div>
            </a>
          </div>
        </li>
        <li className="col-md-9">
          <div className="overview-profile overview-language">
            <a>
              <div className="overview-profile-image">
                <svg
                  width="25"
                  height="25"
                  viewBox="0 0 11 9"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M0 5.16653C0 4.0821 0 3.53989 0.104468 3.0918C0.450287 1.60849 1.60849 0.450287 3.0918 0.104468C3.53989 0 4.0821 0 5.16653 0H6.31465L6.34753 2.61533e-05C8.54537 0.00797392 10.3251 1.7877 10.333 3.98554L10.3331 4.01842L10.333 4.0513C10.3251 6.24913 8.54537 8.02886 6.34753 8.03681L6.31465 8.03683H2.71045C2.44523 8.03683 2.19088 8.14219 2.00334 8.32972L1.62369 8.70938C1.02451 9.30856 0 8.8842 0 8.03683V5.16653ZM3.44436 2.37028C3.16821 2.37028 2.94436 2.59414 2.94436 2.87028C2.94436 3.14642 3.16821 3.37028 3.44436 3.37028H6.88871C7.16486 3.37028 7.38871 3.14642 7.38871 2.87028C7.38871 2.59414 7.16486 2.37028 6.88871 2.37028H3.44436ZM3.44436 4.66652C3.16821 4.66652 2.94436 4.89037 2.94436 5.16652C2.94436 5.44266 3.16821 5.66652 3.44436 5.66652H5.16653C5.44268 5.66652 5.66653 5.44266 5.66653 5.16652C5.66653 4.89037 5.44268 4.66652 5.16653 4.66652H3.44436Z"
                    fill="currentColor"
                  />
                </svg>
              </div>

              <div className="number-overview-wrap lang-info-num-wrap">
                <strong className="lang-strong">Choose Language:</strong>
                <Select
                  className="basic-single profile-single-select"
                  classNamePrefix="select"
                  value={{ label: language, value: language }}
                  isDisabled={false}
                  isLoading={false}
                  isClearable={false}
                  isRtl={false}
                  isSearchable={true}
                  menuPlacement="auto"
                  name="color"
                  options={languageLabels}
                  components={{ Control: CustomControl }}
                  onChange={(e) => {
                    handleLanguageChange(e.value, "language");
                  }}
                />
              </div>
              <div className="clearfix"></div>
            </a>
          </div>
        </li>
        <li className="col-md-9">
          <div className="overview-profile overview-language">
            <a>
              <div className="overview-profile-image">
                <svg
                  width="25"
                  height="25"
                  viewBox="0 0 13 13"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M6.89726 10.6301C7.86943 10.0926 10.2923 8.4983 10.2923 5.95835C10.2923 3.86427 8.59473 2.16669 6.50065 2.16669C4.40657 2.16669 2.70898 3.86427 2.70898 5.95835C2.70898 8.4983 5.13187 10.0926 6.10404 10.6301C6.35333 10.768 6.64797 10.768 6.89726 10.6301ZM6.50065 7.58335C7.39811 7.58335 8.12565 6.85582 8.12565 5.95835C8.12565 5.06089 7.39811 4.33335 6.50065 4.33335C5.60319 4.33335 4.87565 5.06089 4.87565 5.95835C4.87565 6.85582 5.60319 7.58335 6.50065 7.58335Z"
                    fill="currentColor"
                  />
                </svg>
              </div>
              <div className="number-overview-wrap lang-info-num-wrap">
                <strong className="lang-strong">Choose Country:</strong>
                <Select
                  className="basic-single profile-single-select"
                  classNamePrefix="select"
                  value={{ label: country, value: country }}
                  isDisabled={false}
                  isLoading={false}
                  isClearable={false}
                  isRtl={false}
                  isSearchable={true}
                  menuPlacement="auto"
                  name="color"
                  options={countryLabels.map((res) => ({
                    label: res,
                    value: res,
                  }))}
                  components={{ Control: CustomControl }}
                  onChange={(e) => {
                    handleCountryChange(e.value, "country");
                  }}
                />
              </div>
              <div className="clearfix"></div>
            </a>
          </div>
        </li>
        <Timezone user={props.user} />
      </ul>
    </div>
  );
};

const CustomControl = ({ children,menuIsOpen, ...props }) => (
  <components.Control {...props}>
    {children}
    <div className="select-icon me-1" style={{
      transform: menuIsOpen ? "rotate(180deg)" : "rotate(0deg)",
      transition: "transform 0.3s ease",
    }}>
      <IoIosArrowDown />
    </div>
  </components.Control>
);

export default Language;

import React, { useEffect, useState } from "react";
import {
  useGetCompanyPerformanceTemplateQuery,
  useGetPerformanceTemplateDetailsQuery,
} from "../../../slices/performance/performanceSlice";
import Select, { components } from "react-select";
import { IoIosArrowDown } from "react-icons/io";
import API from "../../../services/ClientApi";
import { ShimmerBadge, ShimmerTitle } from "react-shimmer-effects";

const UserSettings = ({ user, isAdmin, getUser = () => {} }) => {
  const [performanceOptions, setPerformanceOptions] = useState([]);
  const [templateDetail, setTemplateDetail] = useState(null);
  const [performance, setPerformance] = useState(null);
  const { data: companyPerformanceTemplates, isLoading } =
    useGetCompanyPerformanceTemplateQuery();
  const {
    data: templateDetails,
    isLoading: isLoadingDetails,
    error: detailsError,
  } = useGetPerformanceTemplateDetailsQuery(performance?.value, {
    skip: !performance?.value,
  });

  const handleLanguageChange = async (value, label) => {
    setPerformance({ label: label, value: value });
    try {
      await API.post("users/update-profile", {
        userId: user?.userInfo?.userId,
        fieldName: "performanceId", // Add the field name for the specific input field
        fieldValues: value, // Add the corresponding state variable here
      });
      await getUser(false);
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };

  useEffect(() => {
    if (companyPerformanceTemplates?.data?.length > 0) {
      const options = companyPerformanceTemplates.data.map((item) => ({
        label: item?.performanceTemplate?.templateName,
        value: item?.performanceTemplate?.id,
      }));
      setPerformanceOptions(options);

      // Set default performance state from user's performanceId
      const defaultPerformance = options.find(
        (option) => option.value === user?.userInfo?.performanceId
      );
      if (defaultPerformance) {
        setPerformance(defaultPerformance);
      }
    }
  }, [companyPerformanceTemplates, user]);

  useEffect(() => {
    setTemplateDetail(templateDetails?.data);
  }, [templateDetails]);

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      // color:
      // state.data.value.toString().toLowerCase() === "critical"
      //   ? "#FB3F3F"
      //   : state.data.value.toString().toLowerCase() === "highest"
      //   ? "#56BAEC"
      //   : state.data.value.toString().toLowerCase() === "medium"
      //   ? "#394B84"
      //   : state.data.value.toString().toLowerCase() === "low"
      //   ? "#2C2D2F"
      //   : state.data.value.toString().toLowerCase() === "in progress"
      //   ? "#2098D1"
      //   : state.data.value.toString().toLowerCase() === "not started"
      //   ? "black"
      //   : state.data.value.toString().toLowerCase() === "completed"
      //   ? "#2DBF64"
      //   : state.data.value.toString().toLowerCase() === "on hold"
      //   ? "#E8910D"
      //   : state.data.value.toString().toLowerCase() === "submit for review"
      //   ? "#EDA900"
      //   : state.data.value.toString().toLowerCase() === "deleted"
      //   ? "#ff0000"
      //   : " black",
      fontSize: "14px",
      fontWeight: 300,
    }),
    singleValue: (provided, state) => ({
      ...provided,
      fontSize: "14px",
      fontWeight: 300,
    }),
    input: (provided, state) => ({
      ...provided,
      fontSize: "14px",
      fontWeight: 300,
    }),
    placeholder: (provided, state) => ({
      ...provided,
      fontSize: "14px",
      fontWeight: 300,
    }),
    menuPortal: (base) => ({
      ...base,
      zIndex: 9999, // Ensure dropdown appears above other elements
    }),
  };

  return (
    <div className="listing-info-profile-wrap">
      <h4 className="mb-3"> Settings </h4>
      <div className="user-performance-template-settings">
        <div className="mb-3 d-flex align-items-center gap-2 flex-wrap user-performance-template-settings-header">
          {isAdmin ? (
            <>
              <h4>Performance Modal:</h4>
              <Select
                className="basic-single profile-single-select user-performance-template-settings-select"
                classNamePrefix="select"
                placeholder="Select User Time Slot"
                value={performance}
                isDisabled={false}
                isLoading={false}
                isClearable={false}
                isRtl={false}
                isSearchable={true}
                menuPlacement="auto"
                name="color"
                styles={customStyles}
                options={performanceOptions}
                components={{ Control: CustomControl }}
                onChange={(e) => {
                  handleLanguageChange(e.value, e.label);
                }}
              />
            </>
          ) : (
            <h4>{performance?.label}</h4>
          )}
        </div>
        {isLoadingDetails ? (
          <div>
            <ShimmerBadge width={200} />
            <ShimmerTitle line={3} variant="secondary" />
          </div>
        ) : (
          templateDetail && (
            <div className="user-performance-template-settings-detail row">
              <div className="col-md-6">
                <div className="user-performance-template-settings-detail-col">
                  <h5 className="mb-2">Points for Task Assignee</h5>
                  <div className="d-flex align-items-center justify-content-between flex-wrap gap-2 py-2 user-performance-template-points-border">
                    <p>Task completion on time or before given time:</p>
                    <p className="user-performance-template-points">
                      {templateDetail.taskAssigneeCompletionPoints || 0}
                    </p>
                  </div>
                  <div className="d-flex align-items-center justify-content-between flex-wrap gap-2 py-2">
                    <p>Task completion after due date:</p>
                    <p className="user-performance-template-points">
                      {templateDetail.taskAssigneeDeductedPerDay || 0}
                    </p>
                  </div>
                  <h5 className="my-2">Attendance Points</h5>
                  <div className="d-flex align-items-center justify-content-between flex-wrap gap-2 py-2 user-performance-template-points-border">
                    <p>On time (check-in & check-out):</p>
                    <p className="user-performance-template-points">
                      {templateDetail.assigneeOnTimePoints || 0}
                    </p>
                  </div>
                  <div className="d-flex align-items-center justify-content-between flex-wrap gap-2 py-2 user-performance-template-points-border">
                    <p>Late check-in:</p>
                    <p className="user-performance-template-points">
                      {templateDetail.assigneeLateCheckinPoints || 0}
                    </p>
                  </div>
                  <div className="d-flex align-items-center justify-content-between flex-wrap gap-2 py-2 user-performance-template-points-border">
                    <p>Early check-out:</p>
                    <p className="user-performance-template-points">
                      {templateDetail.assigneeEarlyCheckoutPoints || 0}
                    </p>
                  </div>
                  <div className="d-flex align-items-center justify-content-between flex-wrap gap-2 py-2 user-performance-template-points-border">
                    <p>Leave:</p>
                    <p className="user-performance-template-points">
                      {templateDetail.assigneeLeavePoints || 0}
                    </p>
                  </div>
                  <div className="d-flex align-items-center justify-content-between flex-wrap gap-2 py-2">
                    <p>Absent:</p>
                    <p className="user-performance-template-points">
                      {templateDetail.assigneeAbsentPoints || 0}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="user-performance-template-settings-detail-col">
                  <h5 className="mb-2">Points for Task Owner</h5>
                  <div className="d-flex align-items-center justify-content-between flex-wrap gap-2 py-2 user-performance-template-points-border">
                    <p>Task completion on time or before given time:</p>
                    <p className="user-performance-template-points">
                      {templateDetail.taskOwnerCompletionPoints || 0}
                    </p>
                  </div>
                  <div className="d-flex align-items-center justify-content-between flex-wrap gap-2 py-2">
                    <p>Task completion after due date:</p>
                    <p className="user-performance-template-points">
                      {templateDetail.taskOwnerPointsDeductedPerDay || 0}
                    </p>
                  </div>
                  <h5 className="my-2">Attendance Points</h5>
                  <div className="d-flex align-items-center justify-content-between flex-wrap gap-2 py-2 user-performance-template-points-border">
                    <p>On time (check-in & check-out):</p>
                    <p className="user-performance-template-points">
                      {templateDetail.ownerOnTimePoints || 0}
                    </p>
                  </div>
                  <div className="d-flex align-items-center justify-content-between flex-wrap gap-2 py-2 user-performance-template-points-border">
                    <p>Late check-in:</p>
                    <p className="user-performance-template-points">
                      {templateDetail.ownerLateCheckinPoints || 0}
                    </p>
                  </div>
                  <div className="d-flex align-items-center justify-content-between flex-wrap gap-2 py-2 user-performance-template-points-border">
                    <p>Early check-out:</p>
                    <p className="user-performance-template-points">
                      {templateDetail.ownerEarlyCheckoutPoints || 0}
                    </p>
                  </div>
                  <div className="d-flex align-items-center justify-content-between flex-wrap gap-2 py-2 user-performance-template-points-border">
                    <p>Leave:</p>
                    <p className="user-performance-template-points">
                      {templateDetail.ownerLeavePoints || 0}
                    </p>
                  </div>
                  <div className="d-flex align-items-center justify-content-between flex-wrap gap-2 py-2">
                    <p>Absent:</p>
                    <p className="user-performance-template-points">
                      {templateDetail.ownerAbsentPoints || 0}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )
        )}
      </div>
    </div>
  );
};

const CustomControl = ({ children,menuIsOpen, ...props }) => (
  <components.Control {...props}>
    {children}
    <div className="select-icon me-1" style={{
      transform: menuIsOpen ? "rotate(180deg)" : "rotate(0deg)",
      transition: "transform 0.3s ease",
    }}>
      <IoIosArrowDown />
    </div>
  </components.Control>
);

export default UserSettings;

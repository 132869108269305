import React from "react";
import ModulePageMain from "./components/ModulePageMain";
import ProductsPage from "./components/ProductPageMain";

const ModulePage = (props) => {
  return (
    <>
      <ProductsPage />
     {/* <ModulePageMain isDark={props.isDark} getOptions={props.getOptions} /> */}
    </>
  );
};

export default ModulePage;

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import API from "../services/ClientApi";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { toast } from "react-toastify";
import { Modal, Spinner } from "react-bootstrap";
import DeleteIcon from "../assets/infoMain.svg";
import { Icon } from "@iconify/react";
import { getIconCode } from "../helper/iconGeneratorSidebar";
import { useDispatch, useSelector } from "react-redux";
import { findObjectByContentTitle } from "../helper/getContentObject";
import CompanyListModal from "./CompanyListModal/CompanyListModal";
import Cookies from "js-cookie";
import {
  clearTaskListAction,
  emptyTaskPrioritiesListAction,
  emptyTaskTypesListAction,
} from "../actions/tasksActions";
import {
  clearProjectListAction,
  emptyProjectFormListingAction,
  emptyProjectListingStatusAction,
  emptyProjectListingAllStatusAction,
  emptyProjectTypeListingAction,
  emptyProjectUserListingAction,
} from "../actions/projectActions";
import {
  clearOngoingTasksListAction,
  clearActiveProjectListAction,
  clearTimelineStats,
} from "../actions/timelineActions";
import { emptyTeamsListAction } from "../actions/teamsActions";
import { clearCachedTasks } from "../actions/taskCachedActions";
import { clearProjectCache } from "../actions/projectListingCacheActions";
import { clearCompletedTask } from "../actions/completedTasksActions";
import { projectCompletedClearState } from "../actions/projectCompletedListingActions";
import { useClearAllCacheMutation } from "../slices/tasks/tasksSlice";
import { useClearAllCompletedTaskCacheMutation } from "../slices/tasks/completedTasks.Slice";
import { clearSubscriptionData } from "../actions/paymentActions";
import saveLocalStorageToCookies from "../helper/saveLocalStorageToCookies";

const SideBar = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [clearAllCache] = useClearAllCacheMutation();
  const [clearAllCompletedTaskCache] = useClearAllCompletedTaskCacheMutation();
  // const { data: chatUsers, isLoading: chatLoading } = useGetAllChatUsersQuery();
  const [currentRoute, setCurrentRoute] = useState("");
  const [showVerifyEmailModal, setShowVerifyEmailModal] = useState(false);
  const [load, setLoad] = useState(false);
  const { modalContents } = useSelector((state) => state?.modalData);
  const [switchCompany, setSwitchCompany] = useState(false);
  const { companiesList } = useSelector((state) => state?.companyListData);

  const verifyModalContent = findObjectByContentTitle(
    "Verify Email",
    modalContents?.data
  );

  const handleCloseCompanyModal = () => {
    setSwitchCompany(false);
  };

  const resendEmail = async () => {
    try {
      setLoad(true);
      const response = await API.post("users/resend-email-verification");
      if (response?.data?.status === "success") {
        toast.success("Verification mail sent successfully", {
          position: toast.POSITION.TOP_CENTER,
        });
        setShowVerifyEmailModal(false);
      }
    } catch (error) {
    } finally {
      setLoad(false);
    }
  };

  const handleVerifyEmailModalClose = () => {
    setShowVerifyEmailModal(false);
  };

  const handleOutsideClick = (e) => {
    // Check if the click is outside of the sidebar and the sidebar is currently open
    const isMenuIconClick = e.target.closest(".menu-icon");
    const isMenuIconClick2 = e.target.closest(".menu-icon-2");
    if (
      props.sidebar &&
      !e.target.closest(".vertical-navbar") &&
      !isMenuIconClick &&
      !isMenuIconClick2
    ) {
      props.setSideBar(false);
    }
  };
  useEffect(() => {
    // Add event listener to the document body
    document.body.addEventListener("click", handleOutsideClick);

    // Clean up the event listener when the component unmounts
    return () => {
      document.body.removeEventListener("click", handleOutsideClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.sidebar]);

  useEffect(() => {
    const currentRoute = window.location.pathname;
    setCurrentRoute(currentRoute.substring(1));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname]);

  return (
    <>
      <div className={`vertical-navbar ${props.sidebar ? "open" : ""}`}>
        {/* Navbar content goes here */}
        <ul>
          <li>
            <div className="d-flex gap-2 align-items-center sidebar-user-profile pb-3">
              {props?.user?.profileImage ? (
                <img
                  src={props?.user?.profileImage}
                  alt={props?.user?.userName}
                  className="profile-image-large"
                />
              ) : (
                <span
                  className="profile-dropdown-user-image-none p-0 m-0"
                  style={{ color: "#fff" }}
                >
                  {props?.user?.userName?.slice(0, 2).toUpperCase()}
                </span>
              )}
              <div className="d-flex flex-column gap-1">
                <h4 className="sidebar-header-truncate-txt">
                  {props.user?.userName}
                </h4>
                <p className="sidebar-header-truncate-txt">
                  {props.user?.emailAddress}
                </p>
              </div>
            </div>
          </li>
          <li className="category-li d-flex flex-column gap-1">
            <span>profile</span>{" "}
            <a
              onClick={() => {
                if (props?.user?.emailVerified !== 0) {
                  navigate(`/${localStorage.getItem("company")}/profile`);
                  sessionStorage.removeItem("tab");
                  sessionStorage.removeItem("userTab");
                  sessionStorage.removeItem("substate");
                } else {
                  setShowVerifyEmailModal(true);
                }
              }}
              className={`sidebar-icon-wrap ${
                currentRoute === `${localStorage.getItem("company")}/profile`
                  ? "selected-route"
                  : ""
              }`}
            >
              {" "}
              <Icon icon="gg:profile"></Icon> Profile{" "}
            </a>{" "}
            {props?.user?.roles[0]?.roleType?.toString().toLowerCase() ===
              "admin" && (
              <a
                onClick={() => {
                  if (props?.user?.emailVerified !== 0) {
                    navigate(
                      `/${localStorage.getItem("company")}/company-employees`
                    );
                    sessionStorage.removeItem("tab");
                    sessionStorage.removeItem("userTab");
                    sessionStorage.removeItem("substate");
                  } else {
                    setShowVerifyEmailModal(true);
                  }
                }}
                className={`sidebar-icon-wrap ${
                  currentRoute ===
                  `${localStorage.getItem("company")}/company-employees`
                    ? "selected-route"
                    : ""
                }`}
              >
                {" "}
                <Icon icon="ph:users-three"></Icon> Members{" "}
              </a>
            )}
            <a
              onClick={() => {
                if (props?.user?.emailVerified !== 0) {
                  setSwitchCompany(true);
                  sessionStorage.removeItem("tab");
                  sessionStorage.removeItem("userTab");
                  sessionStorage.removeItem("substate");
                } else {
                  setShowVerifyEmailModal(true);
                }
              }}
              className={`sidebar-icon-wrap ${
                switchCompany ? "selected-route" : ""
              }`}
            >
              {" "}
              <Icon icon="fluent:arrow-swap-28-regular"></Icon> Switch Account{" "}
            </a>{" "}
          </li>
          <li className="category-li d-flex flex-column gap-1">
            <span>Settings</span>
            {props?.user?.roles[0]?.roleType?.toString().toLowerCase() ===
              "admin" && (
              <>
                <a
                  onClick={() => {
                    if (props?.user?.emailVerified !== 0) {
                      navigate(
                        `/${localStorage.getItem("company")}/performance-system`
                      );
                      sessionStorage.removeItem("tab");
                      sessionStorage.removeItem("userTab");
                      sessionStorage.removeItem("substate");
                      props.setSideBar(false);
                    } else {
                      setShowVerifyEmailModal(true);
                    }
                  }}
                  className={`sidebar-icon-wrap ${
                    currentRoute ===
                    `${localStorage.getItem("company")}/performance-system`
                      ? "selected-route"
                      : ""
                  }`}
                >
                  {" "}
                  <Icon icon="nimbus:stats"></Icon> Performance System{" "}
                </a>{" "}
                <a
                  onClick={() => {
                    if (props?.user?.emailVerified !== 0) {
                      navigate(
                        `/${localStorage.getItem("company")}/company-profile`
                      );
                      sessionStorage.removeItem("tab");
                      sessionStorage.removeItem("userTab");
                      sessionStorage.removeItem("substate");
                      props.setSideBar(false);
                    } else {
                      setShowVerifyEmailModal(true);
                    }
                  }}
                  className={`sidebar-icon-wrap ${
                    currentRoute ===
                    `${localStorage.getItem("company")}/company-profile`
                      ? "selected-route"
                      : ""
                  }`}
                >
                  {" "}
                  <Icon icon="mdi:company"></Icon> Company Profile{" "}
                </a>{" "}
                <a
                  onClick={() => {
                    if (props?.user?.emailVerified !== 0) {
                      navigate(
                        `/${localStorage.getItem("company")}/company-roles`
                      );
                      sessionStorage.removeItem("tab");
                      sessionStorage.removeItem("userTab");
                      sessionStorage.removeItem("substate");
                      props.setSideBar(false);
                    } else {
                      setShowVerifyEmailModal(true);
                    }
                  }}
                  className={`sidebar-icon-wrap ${
                    currentRoute ===
                    `${localStorage.getItem("company")}/company-roles`
                      ? "selected-route"
                      : ""
                  }`}
                >
                  {" "}
                  <Icon icon="carbon:user-role"></Icon> User Roles{" "}
                </a>{" "}
                <a
                  onClick={() => {
                    if (props?.user?.emailVerified !== 0) {
                      navigate(
                        `/${localStorage.getItem(
                          "company"
                        )}/company-subscriptions`
                      );
                      sessionStorage.removeItem("tab");
                      sessionStorage.removeItem("userTab");
                      sessionStorage.removeItem("substate");
                      props.setSideBar(false);
                    } else {
                      setShowVerifyEmailModal(true);
                    }
                  }}
                  className={`sidebar-icon-wrap ${
                    currentRoute ===
                    `${localStorage.getItem("company")}/company-subscriptions`
                      ? "selected-route"
                      : ""
                  }`}
                >
                  {" "}
                  <Icon icon="material-symbols-light:unsubscribe-outline-sharp"></Icon>{" "}
                  My Subscription{" "}
                </a>{" "}
                <a
                  onClick={() => {
                    if (props?.user?.emailVerified !== 0) {
                      navigate(`/${localStorage.getItem("company")}/plugins`);
                      sessionStorage.removeItem("tab");
                      sessionStorage.removeItem("userTab");
                      sessionStorage.removeItem("substate");
                      props.setSideBar(false);
                    } else {
                      setShowVerifyEmailModal(true);
                    }
                  }}
                  className={`sidebar-icon-wrap ${
                    currentRoute ===
                    `${localStorage.getItem("company")}/plugins`
                      ? "selected-route"
                      : ""
                  }`}
                >
                  {" "}
                  <Icon icon="clarity:plugin-outline-badged"></Icon> Plug-ins{" "}
                </a>{" "}
              </>
            )}
          </li>
          <li className="category-li d-flex flex-column gap-1 pt-1">
            <a
              onClick={async () => {
                Cookies.remove("access_token");
                Cookies.remove("permissions");
                Cookies.remove("company");
                localStorage.removeItem("access_token");
                localStorage.removeItem("company");
                localStorage.clear();
                sessionStorage.clear();
                dispatch(clearTaskListAction());
                dispatch(clearProjectListAction());
                dispatch(clearActiveProjectListAction());
                dispatch(clearOngoingTasksListAction());
                dispatch(clearTimelineStats());
                dispatch(emptyTaskTypesListAction());
                dispatch(emptyProjectFormListingAction());
                dispatch(emptyProjectListingStatusAction());
                dispatch(emptyProjectListingAllStatusAction());
                dispatch(emptyProjectTypeListingAction());
                dispatch(emptyProjectUserListingAction());
                dispatch(emptyTaskPrioritiesListAction());
                dispatch(emptyTeamsListAction());
                dispatch(clearCachedTasks());
                dispatch(clearProjectCache());
                dispatch(clearCompletedTask());
                dispatch(clearSubscriptionData());
                dispatch(projectCompletedClearState());
                await clearAllCache().unwrap();
                await clearAllCompletedTaskCache().unwrap();
                saveLocalStorageToCookies();
              }}
              className={`sidebar-icon-wrap`}
            >
              {" "}
              <Icon
                icon="solar:logout-outline"
                style={{ transform: "rotate(180deg)" }}
              ></Icon>{" "}
              Logout
            </a>{" "}
          </li>
        </ul>
      </div>
      <div className={`left-nav-wrap ${props.collapse ? "collapse-nav" : ""}`}>
        {!props.collapse ? (
          <div
            className={`left-nav-main ${
              props?.bannerCount === 1 ? "left-nav-main-one" : ""
            } ${props?.bannerCount === 2 ? "left-nav-main-two" : ""}`}
          >
            <ul>
              <li>
                <div className="d-flex gap-2 align-items-center sidebar-user-profile pb-3">
                  {props?.user?.profileImage ? (
                    <img
                      src={props?.user?.profileImage}
                      alt={props?.user?.userName}
                      className="profile-image-large"
                    />
                  ) : (
                    <span
                      className="profile-dropdown-user-image-none p-0 m-0"
                      style={{ color: "#fff" }}
                    >
                      {props?.user?.userName?.slice(0, 2).toUpperCase()}
                    </span>
                  )}
                  <div className="d-flex flex-column gap-1">
                    <h4 className="sidebar-header-truncate-txt">
                      {props.user?.userName}
                    </h4>
                    <p className="sidebar-header-truncate-txt">
                      {props.user?.emailAddress}
                    </p>
                  </div>
                </div>
              </li>
              <li className="category-li d-flex flex-column gap-1">
                <span>profile</span>{" "}
                <a
                  onClick={() => {
                    if (props?.user?.emailVerified !== 0) {
                      navigate(`/${localStorage.getItem("company")}/profile`);
                      sessionStorage.removeItem("tab");
                      sessionStorage.removeItem("userTab");
                      sessionStorage.removeItem("substate");
                    } else {
                      setShowVerifyEmailModal(true);
                    }
                  }}
                  className={`sidebar-icon-wrap ${
                    currentRoute ===
                    `${localStorage.getItem("company")}/profile`
                      ? "selected-route"
                      : ""
                  }`}
                >
                  {" "}
                  <Icon icon="gg:profile"></Icon> Profile{" "}
                </a>{" "}
                {props?.user?.roles[0]?.roleType?.toString().toLowerCase() ===
                  "admin" && (
                  <a
                    onClick={() => {
                      if (props?.user?.emailVerified !== 0) {
                        navigate(
                          `/${localStorage.getItem(
                            "company"
                          )}/company-employees`
                        );
                        sessionStorage.removeItem("tab");
                        sessionStorage.removeItem("userTab");
                        sessionStorage.removeItem("substate");
                      } else {
                        setShowVerifyEmailModal(true);
                      }
                    }}
                    className={`sidebar-icon-wrap ${
                      currentRoute ===
                      `${localStorage.getItem("company")}/company-employees`
                        ? "selected-route"
                        : ""
                    }`}
                  >
                    {" "}
                    <Icon icon="ph:users-three"></Icon> Members{" "}
                  </a>
                )}
                <a
                  onClick={() => {
                    if (props?.user?.emailVerified !== 0) {
                      setSwitchCompany(true);
                      sessionStorage.removeItem("tab");
                      sessionStorage.removeItem("userTab");
                      sessionStorage.removeItem("substate");
                    } else {
                      setShowVerifyEmailModal(true);
                    }
                  }}
                  className={`sidebar-icon-wrap ${
                    switchCompany ? "selected-route" : ""
                  }`}
                >
                  {" "}
                  <Icon icon="fluent:arrow-swap-28-regular"></Icon> Switch
                  Account{" "}
                </a>{" "}
              </li>
              <li className="category-li d-flex flex-column gap-1">
                <span>Settings</span>
                {props?.user?.roles[0]?.roleType?.toString().toLowerCase() ===
                  "admin" && (
                  <>
                    <a
                      onClick={() => {
                        if (props?.user?.emailVerified !== 0) {
                          navigate(
                            `/${localStorage.getItem(
                              "company"
                            )}/performance-system`
                          );
                          sessionStorage.removeItem("tab");
                          sessionStorage.removeItem("userTab");
                          sessionStorage.removeItem("substate");
                          props.setSideBar(false);
                        } else {
                          setShowVerifyEmailModal(true);
                        }
                      }}
                      className={`sidebar-icon-wrap ${
                        currentRoute ===
                        `${localStorage.getItem("company")}/performance-system`
                          ? "selected-route"
                          : ""
                      }`}
                    >
                      {" "}
                      <Icon icon="nimbus:stats"></Icon> Performance System{" "}
                    </a>{" "}
                    <a
                      onClick={() => {
                        if (props?.user?.emailVerified !== 0) {
                          navigate(
                            `/${localStorage.getItem(
                              "company"
                            )}/company-profile`
                          );
                          sessionStorage.removeItem("tab");
                          sessionStorage.removeItem("userTab");
                          sessionStorage.removeItem("substate");
                          props.setSideBar(false);
                        } else {
                          setShowVerifyEmailModal(true);
                        }
                      }}
                      className={`sidebar-icon-wrap ${
                        currentRoute ===
                        `${localStorage.getItem("company")}/company-profile`
                          ? "selected-route"
                          : ""
                      }`}
                    >
                      {" "}
                      <Icon icon="mdi:company"></Icon> Company Profile{" "}
                    </a>{" "}
                    <a
                      onClick={() => {
                        if (props?.user?.emailVerified !== 0) {
                          navigate(
                            `/${localStorage.getItem("company")}/company-roles`
                          );
                          sessionStorage.removeItem("tab");
                          sessionStorage.removeItem("userTab");
                          sessionStorage.removeItem("substate");
                          props.setSideBar(false);
                        } else {
                          setShowVerifyEmailModal(true);
                        }
                      }}
                      className={`sidebar-icon-wrap ${
                        currentRoute ===
                        `${localStorage.getItem("company")}/company-roles`
                          ? "selected-route"
                          : ""
                      }`}
                    >
                      {" "}
                      <Icon icon="carbon:user-role"></Icon> User Roles{" "}
                    </a>{" "}
                    <a
                      onClick={() => {
                        if (props?.user?.emailVerified !== 0) {
                          navigate(
                            `/${localStorage.getItem(
                              "company"
                            )}/company-subscriptions`
                          );
                          sessionStorage.removeItem("tab");
                          sessionStorage.removeItem("userTab");
                          sessionStorage.removeItem("substate");
                          props.setSideBar(false);
                        } else {
                          setShowVerifyEmailModal(true);
                        }
                      }}
                      className={`sidebar-icon-wrap ${
                        currentRoute ===
                        `${localStorage.getItem(
                          "company"
                        )}/company-subscriptions`
                          ? "selected-route"
                          : ""
                      }`}
                    >
                      {" "}
                      <Icon icon="material-symbols-light:unsubscribe-outline-sharp"></Icon>{" "}
                      My Subscription{" "}
                    </a>{" "}
                    <a
                      onClick={() => {
                        if (props?.user?.emailVerified !== 0) {
                          navigate(
                            `/${localStorage.getItem("company")}/plugins`
                          );
                          sessionStorage.removeItem("tab");
                          sessionStorage.removeItem("userTab");
                          sessionStorage.removeItem("substate");
                          props.setSideBar(false);
                        } else {
                          setShowVerifyEmailModal(true);
                        }
                      }}
                      className={`sidebar-icon-wrap ${
                        currentRoute ===
                        `${localStorage.getItem("company")}/plugins`
                          ? "selected-route"
                          : ""
                      }`}
                    >
                      {" "}
                      <Icon icon="clarity:plugin-outline-badged"></Icon>{" "}
                      Plug-ins{" "}
                    </a>{" "}
                  </>
                )}
              </li>
              <li className="category-li d-flex flex-column gap-1 pt-1">
                <a
                  onClick={async () => {
                    localStorage.removeItem("access_token");
                    localStorage.removeItem("company");
                    localStorage.clear();
                    sessionStorage.clear();
                    Cookies.remove("access_token");
                    Cookies.remove("permissions");
                    Cookies.remove("company");
                    dispatch(clearTaskListAction());
                    dispatch(clearProjectListAction());
                    dispatch(clearActiveProjectListAction());
                    dispatch(clearOngoingTasksListAction());
                    dispatch(clearTimelineStats());
                    dispatch(emptyTaskTypesListAction());
                    dispatch(emptyProjectFormListingAction());
                    dispatch(emptyProjectListingStatusAction());
                    dispatch(emptyProjectListingAllStatusAction());
                    dispatch(emptyProjectTypeListingAction());
                    dispatch(emptyProjectUserListingAction());
                    dispatch(emptyTaskPrioritiesListAction());
                    dispatch(emptyTeamsListAction());
                    dispatch(clearCachedTasks());
                    dispatch(clearProjectCache());
                    dispatch(clearCompletedTask());
                    dispatch(clearSubscriptionData());
                    dispatch(projectCompletedClearState());
                    await clearAllCache().unwrap();
                    await clearAllCompletedTaskCache().unwrap();
                    saveLocalStorageToCookies();
                  }}
                  className={`sidebar-icon-wrap`}
                >
                  {" "}
                  <Icon
                    icon="solar:logout-outline"
                    style={{ transform: "rotate(180deg)" }}
                  ></Icon>{" "}
                  Logout
                </a>{" "}
              </li>
            </ul>
          </div>
        ) : (
          <div
            className={`left-nav-main left-nav-main-collapse ${
              props?.bannerCount === 1 ? "left-nav-main-one" : ""
            } ${props?.bannerCount === 2 ? "left-nav-main-two" : ""}`}
          >
            <ul className="ul-collapsed">
              <li>
                <OverlayTrigger
                  placement="right"
                  overlay={
                    <Tooltip id="button-tooltip">
                      {props.user?.userName}
                    </Tooltip>
                  }
                >
                  <div className="d-flex gap-2 align-items-center sidebar-user-profile pb-3">
                    {props?.user?.profileImage ? (
                      <img
                        src={props?.user?.profileImage}
                        alt={props?.user?.userName}
                        className="profile-image-large"
                      />
                    ) : (
                      <span
                        className="profile-dropdown-user-image-none p-0 m-0"
                        style={{ color: "#fff" }}
                      >
                        {props?.user?.userName?.slice(0, 2).toUpperCase()}
                      </span>
                    )}
                  </div>
                </OverlayTrigger>
              </li>
              <li className="pt-1">
                {" "}
                <OverlayTrigger
                  placement="right"
                  overlay={<Tooltip id="button-tooltip">Profile</Tooltip>}
                >
                  <a
                    onClick={() => {
                      if (props?.user?.emailVerified !== 0) {
                        navigate(`/${localStorage.getItem("company")}/profile`);
                        sessionStorage.removeItem("tab");
                        sessionStorage.removeItem("userTab");
                        sessionStorage.removeItem("substate");
                      } else {
                        setShowVerifyEmailModal(true);
                      }
                    }}
                    className={`sidebar-icon-wrap ${
                      currentRoute ===
                      `${localStorage.getItem("company")}/profile`
                        ? "selected-route"
                        : ""
                    }`}
                  >
                    <Icon icon="gg:profile"></Icon>
                  </a>
                </OverlayTrigger>
              </li>
              {props?.user?.roles[0]?.roleType?.toString().toLowerCase() ===
                "admin" && (
                <li className="pt-1">
                  {" "}
                  <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip id="button-tooltip">Members</Tooltip>}
                  >
                    <a
                      onClick={() => {
                        if (props?.user?.emailVerified !== 0) {
                          navigate(
                            `/${localStorage.getItem(
                              "company"
                            )}/company-employees`
                          );
                          sessionStorage.removeItem("tab");
                          sessionStorage.removeItem("userTab");
                          sessionStorage.removeItem("substate");
                        } else {
                          setShowVerifyEmailModal(true);
                        }
                      }}
                      className={`sidebar-icon-wrap ${
                        currentRoute ===
                        `${localStorage.getItem("company")}/company-employees`
                          ? "selected-route"
                          : ""
                      }`}
                    >
                      {" "}
                      <Icon icon="ph:users-three"></Icon>
                    </a>
                  </OverlayTrigger>
                </li>
              )}
              <li className="pt-1">
                {" "}
                <OverlayTrigger
                  placement="right"
                  overlay={
                    <Tooltip id="button-tooltip">Switch Account</Tooltip>
                  }
                >
                  <a
                    onClick={() => {
                      if (props?.user?.emailVerified !== 0) {
                        setSwitchCompany(true);
                        sessionStorage.removeItem("tab");
                        sessionStorage.removeItem("userTab");
                        sessionStorage.removeItem("substate");
                      } else {
                        setShowVerifyEmailModal(true);
                      }
                    }}
                    className={`sidebar-icon-wrap ${
                      switchCompany ? "selected-route" : ""
                    }`}
                  >
                    {" "}
                    <Icon icon="fluent:arrow-swap-28-regular"></Icon>
                  </a>
                </OverlayTrigger>
              </li>

              {props?.user?.roles[0]?.roleType?.toString().toLowerCase() ===
                "admin" && (
                <>
                  <li className="pt-1">
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip id="button-tooltip">
                          Performance System
                        </Tooltip>
                      }
                    >
                      <a
                        onClick={() => {
                          if (props?.user?.emailVerified !== 0) {
                            navigate(
                              `/${localStorage.getItem(
                                "company"
                              )}/performance-system`
                            );
                            sessionStorage.removeItem("tab");
                            sessionStorage.removeItem("userTab");
                            sessionStorage.removeItem("substate");
                            props.setSideBar(false);
                          } else {
                            setShowVerifyEmailModal(true);
                          }
                        }}
                        className={`sidebar-icon-wrap ${
                          currentRoute ===
                          `${localStorage.getItem(
                            "company"
                          )}/performance-system`
                            ? "selected-route"
                            : ""
                        }`}
                      >
                        {" "}
                        <Icon icon="nimbus:stats"></Icon>
                      </a>
                    </OverlayTrigger>
                  </li>
                  <li className="pt-1">
                    {" "}
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip id="button-tooltip">Company Profile</Tooltip>
                      }
                    >
                      <a
                        onClick={() => {
                          if (props?.user?.emailVerified !== 0) {
                            navigate(
                              `/${localStorage.getItem(
                                "company"
                              )}/company-profile`
                            );
                            sessionStorage.removeItem("tab");
                            sessionStorage.removeItem("userTab");
                            sessionStorage.removeItem("substate");
                            props.setSideBar(false);
                          } else {
                            setShowVerifyEmailModal(true);
                          }
                        }}
                        className={`sidebar-icon-wrap ${
                          currentRoute ===
                          `${localStorage.getItem("company")}/company-profile`
                            ? "selected-route"
                            : ""
                        }`}
                      >
                        {" "}
                        <Icon icon="mdi:company"></Icon>
                      </a>
                    </OverlayTrigger>
                  </li>
                  <li className="pt-1">
                    {" "}
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip id="button-tooltip">User Roles</Tooltip>
                      }
                    >
                      <a
                        onClick={() => {
                          if (props?.user?.emailVerified !== 0) {
                            navigate(
                              `/${localStorage.getItem(
                                "company"
                              )}/company-roles`
                            );
                            sessionStorage.removeItem("tab");
                            sessionStorage.removeItem("userTab");
                            sessionStorage.removeItem("substate");
                            props.setSideBar(false);
                          } else {
                            setShowVerifyEmailModal(true);
                          }
                        }}
                        className={`sidebar-icon-wrap ${
                          currentRoute ===
                          `${localStorage.getItem("company")}/company-roles`
                            ? "selected-route"
                            : ""
                        }`}
                      >
                        {" "}
                        <Icon icon="carbon:user-role"></Icon>
                      </a>
                    </OverlayTrigger>
                  </li>
                  <li className="pt-1">
                    {" "}
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip id="button-tooltip">My Subscription</Tooltip>
                      }
                    >
                      <a
                        onClick={() => {
                          if (props?.user?.emailVerified !== 0) {
                            navigate(
                              `/${localStorage.getItem(
                                "company"
                              )}/company-subscriptions`
                            );
                            sessionStorage.removeItem("tab");
                            sessionStorage.removeItem("userTab");
                            sessionStorage.removeItem("substate");
                            props.setSideBar(false);
                          } else {
                            setShowVerifyEmailModal(true);
                          }
                        }}
                        className={`sidebar-icon-wrap ${
                          currentRoute ===
                          `${localStorage.getItem(
                            "company"
                          )}/company-subscriptions`
                            ? "selected-route"
                            : ""
                        }`}
                      >
                        {" "}
                        <Icon icon="material-symbols-light:unsubscribe-outline-sharp"></Icon>
                      </a>
                    </OverlayTrigger>
                  </li>
                  <li className="pt-1">
                    {" "}
                    <OverlayTrigger
                      placement="right"
                      overlay={<Tooltip id="button-tooltip">Plug-ins</Tooltip>}
                    >
                      <a
                        onClick={() => {
                          if (props?.user?.emailVerified !== 0) {
                            navigate(
                              `/${localStorage.getItem("company")}/plugins`
                            );
                            sessionStorage.removeItem("tab");
                            sessionStorage.removeItem("userTab");
                            sessionStorage.removeItem("substate");
                            props.setSideBar(false);
                          } else {
                            setShowVerifyEmailModal(true);
                          }
                        }}
                        className={`sidebar-icon-wrap ${
                          currentRoute ===
                          `${localStorage.getItem("company")}/plugins`
                            ? "selected-route"
                            : ""
                        }`}
                      >
                        {" "}
                        <Icon icon="clarity:plugin-outline-badged"></Icon>
                      </a>
                    </OverlayTrigger>
                  </li>
                </>
              )}
              <li className="pt-1">
                {" "}
                <OverlayTrigger
                  placement="right"
                  overlay={<Tooltip id="button-tooltip">Logout</Tooltip>}
                >
                  <a
                    onClick={async () => {
                      localStorage.removeItem("access_token");
                      localStorage.removeItem("company");
                      localStorage.clear();
                      sessionStorage.clear();
                      dispatch(clearTaskListAction());
                      dispatch(clearProjectListAction());
                      dispatch(clearActiveProjectListAction());
                      dispatch(clearOngoingTasksListAction());
                      dispatch(clearTimelineStats());
                      dispatch(emptyTaskTypesListAction());
                      dispatch(emptyProjectFormListingAction());
                      dispatch(emptyProjectListingStatusAction());
                      dispatch(emptyProjectListingAllStatusAction());
                      dispatch(emptyProjectTypeListingAction());
                      dispatch(emptyProjectUserListingAction());
                      dispatch(emptyTaskPrioritiesListAction());
                      dispatch(emptyTeamsListAction());
                      dispatch(clearCachedTasks());
                      dispatch(clearProjectCache());
                      dispatch(clearCompletedTask());
                      dispatch(clearSubscriptionData());
                      dispatch(projectCompletedClearState());
                      await clearAllCache().unwrap();
                      await clearAllCompletedTaskCache().unwrap();
                      saveLocalStorageToCookies();
                    }}
                    className={`sidebar-icon-wrap`}
                  >
                    {" "}
                    <Icon
                      icon="solar:logout-outline"
                      style={{ transform: "rotate(180deg)" }}
                    ></Icon>
                  </a>
                </OverlayTrigger>
              </li>
            </ul>
          </div>
        )}
      </div>
      {showVerifyEmailModal && (
        <Modal
          show={showVerifyEmailModal}
          onHide={handleVerifyEmailModalClose}
          centered
          size="md"
          data-theme={props?.isDark}
          backdrop="static"
          keyboard={false}
          className="main-delete-modal"
        >
          <Modal.Body className="d-flex align-items-center justify-content-center text-center flex-column update-modal-body">
            <div className="del-modal-img-container">
              <img src={DeleteIcon} alt="verify-icon" />
            </div>
            <h5>{verifyModalContent?.model_title}</h5>
            <p>
              {verifyModalContent?.short_description}{" "}
              <a onClick={resendEmail} className="email-verify-anchor">
                {load ? (
                  <Spinner
                    border="animation"
                    style={{ width: "1rem", height: "1rem" }}
                  />
                ) : (
                  "Resend"
                )}
              </a>{" "}
              {verifyModalContent?.long_description}
            </p>
          </Modal.Body>

          <Modal.Footer>
            <div className="w-100 delete-modal-btn-wrapper">
              <button
                className="w-100 delete-btn-1"
                onClick={handleVerifyEmailModalClose}
                disabled={load}
              >
                Close
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      )}
      {switchCompany && (
        <CompanyListModal
          showCompanyModal={switchCompany}
          handleCloseCompanyModal={handleCloseCompanyModal}
          user={props.user}
          isDark={props.isDark}
          userCompanies={companiesList}
        />
      )}
    </>
  );
};

export default SideBar;

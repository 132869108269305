/* eslint-disable */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import Check from "../../assets/check.svg";
import "../styles/selectusermodal.css";
import API from "../../services/ClientApi";

const SelectUserModal = (props) => {
  const [load, setLoad] = useState(false);
  const [procLoad, setProcLoad] = useState(false);
  const [next, setNext] = useState(false);
  const [search, setSearch] = useState("");

  useEffect(() => {
    const getUsers = async () => {
      setLoad(true);
      try {
        const response = await API.post("company/registered-users", {
          searchTerm: search,
        });
        if (response?.data?.status === "success") {
          const fetchedUsers = response?.data?.data || [];
          props.setUsers(fetchedUsers);

          // Populate selectedUserIds with userIds from fetchedUsers
          // const userIds = fetchedUsers.map((user) => user.userId);
          // props.setSelectedUserIds(userIds);

          // props.setLimit(userIds.length);
        }
      } catch (error) {
      } finally {
        setLoad(false);
      }
    };
    getUsers();
  }, [search]);
  const handleCheckboxChange = (userId) => {
    if (props.selectedUserIds.includes(userId)) {
      const updatedSelectedUserIds = props.selectedUserIds.filter(
        (id) => id !== userId
      );
      props.setLimit((prev) => prev - 1);
      props.setSelectedUserIds(updatedSelectedUserIds);
      props.setRemovedUserIds([...props.removedUserIds, userId]);
    }
    if (!props.selectedUserIds.includes(userId)) {
      const updatedSelectedUserIds = props.removedUserIds.filter(
        (id) => id !== userId
      );
      props.setLimit((prev) => prev + 1);
      props.setRemovedUserIds(updatedSelectedUserIds);
      props.setSelectedUserIds([...props.selectedUserIds, userId]);
    }
  };

  return (
    <div className="contact-body-wrapper select-user-body-wrapper">
      {!props.userpro ? (
        <>
          <div className="contact-body-wrapper">
            <div className="w-100 d-flex text-center contact-para select-user-para">
              <p>
                Please choose team members for your new subscription,
                unmarked/not selected shall be removed from your company
                account.
              </p>
            </div>
            <div className="search-down-grade-wrap mb-3">
              <div className="row">
                <div className="col-md-6">
                  <div className="search-down-field">
                    <input
                      type="email"
                      className="form-control"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                      placeholder="Search for Employees"
                      autoComplete="off"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="select-seat-wraper">
                    {" "}
                    <span>
                      {" "}
                      Selected Seats{" "}
                      <strong> {props.selectedUserIds.length} </strong>{" "}
                      <img src={Check} alt="checked-users" />{" "}
                    </span>
                    {props?.seat < props.limit && (
                      <div className="seat-limit-exceed">
                        {" "}
                        Seats limit exceed{" "}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`${
                props.users.length > 0
                  ? "employee-listing-wraper custom-horizental-scrollbar"
                  : ""
              }`}
            >
              {load ? (
                <div className="w-100 d-flex justify-content-center align-items-center">
                  <Spinner animation="border" className="global-spinner" />
                </div>
              ) : (
                <div className="row">
                  {props.users?.map((u, id) => (
                    <div className="col-md-6" key={id}>
                      <div className="employee-box-wraper">
                        <div className="funkyradio-user">
                          <div className="funkyradio-default-user">
                            <input
                              type="checkbox"
                              name="checkbox"
                              id={`checkbox${u.userId}`}
                              checked={props.selectedUserIds.includes(u.userId)}
                              onChange={() => handleCheckboxChange(u.userId)}
                              disabled={u?.roles?.includes("admin")}
                            />
                            <label
                              for={`checkbox${u.userId}`}
                              className="mt-0 mb-3"
                            >
                              {`${u?.userName} ${
                                u?.roles?.includes("admin") ? "(Admin)" : ""
                              }`}
                              <a href="#"> {u?.userEmail} </a>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
          <div className="choose-continue-btn choose-continue-btn-margin">
            <div className="next-button-wraper module-btn-wrapper">
              <button
                type="submit"
                disabled={load || props?.seat < props.limit}
                onClick={() => {
                  props.setUserPro(true);
                }}
                className="module-modal-btn module-main-btn remove-package-modal-btn"
              >
                Continue
              </button>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="contact-body-wrapper">
            <div className="w-100 d-flex text-center contact-para select-user-para">
              <p>
                Please confirm list of selected team to go with your new
                subscription plan.
              </p>
            </div>

            <div
              className={`${
                props.users.length > 0
                  ? "employee-listing-wraper custom-horizental-scrollbar"
                  : ""
              }`}
            >
              <div className="row selected-user-final-row">
                {props.users
                  ?.filter((u) => props.selectedUserIds.includes(u.userId))
                  ?.map((u, id) => (
                    <div className="col-md-6 selected-user-final" key={id}>
                      <div className="d-flex flex-column employee-box-wraper">
                        <label
                          for={`checkbox${u.userId}`}
                          className="mt-0 mb-3"
                        >
                          {" "}
                          {`${u?.userName} ${
                            u?.roles?.includes("admin") ? "(Admin)" : ""
                          }`}
                        </label>
                        <a href="#"> {u?.userEmail} </a>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <div className="choose-continue-btn choose-continue-btn-margin">
            <div className="next-button-wraper module-btn-wrapper proceed-btn-wrap">
              <button
                type="submit"
                disabled={load}
                onClick={async () => {
                  props.setSelectedUser(props.removedUserIds);
                  props.setSummery(true);
                  props.setUserPro(false);
                  props.setUserModal(false);
                }}
                className="module-modal-btn module-main-btn remove-package-modal-btn"
              >
                {procLoad ? <Spinner /> : "Proceed"}
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default SelectUserModal;

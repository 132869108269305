import { applyMiddleware } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import {
  authReducers,
  userProfileReducer,
  authOnBoardingScreenContentReducer,
} from "./reducers/userReducers.js";
import { textReducer } from "./reducers/textReducer.js";
import {
  companyModalDataReducer,
  userInviteeListingReducer,
  userListingReducer,
} from "./reducers/companyOverviewReducer.js";
import {
  billingReducer,
  paymentReducers,
  subscriptionReducer,
} from "./reducers/paymentReducers.jsx";
import { notificationUnReadCountReducer } from "./reducers/notifcationReducer.js";
import { registeredUserCountReducer } from "./reducers/registeredUserCountReducer.js";
import {
  createProjectReducer,
  deleteProjectReducer,
  editProjectReducer,
  projectDetailReducer,
  projectListingReducer,
  projectListingStatusReducer,
  projectTypeListingReducer,
  projectUserListingReducer,
  shareProjectReducer,
  projectFormListingReducer,
  projectListingAllStatusReducer,
} from "./reducers/projectReducers.js";
import {
  createProjectPhaseReducer,
  getProjectsPhasesReducer,
  updateProjectPhaseReducer,
} from "./reducers/projectPhaseReducer.js";
import {
  taskListReducer,
  taskPrioritiesListReducer,
  taskTypesListReducer,
} from "./reducers/tasksReducers.js";
import { teamsListReducer } from "./reducers/teamsReducer.js";
import {
  timeActiveProjectsReducer,
  timelineOngoingTaskReducer,
  timelineStatsReducer,
} from "./reducers/timelineReducers.js";
import { companiesListingReducer } from "./reducers/companiesReducer.js";
import tasksCachedReducer from "./reducers/taskCachedReducer.js";
import projectListingCacheReducer from "./reducers/projectListingCacheReducers.js";
import { projectCompletedListingReducer } from "./reducers/projectCompletedListingReducer.js";
import tasksCachedCompletedReducer from "./reducers/completedTaskReducers.js";
import projectDetailTasksCachedReducer from "./reducers/projectDetailTasksReducers.js";
import { setupListeners } from "@reduxjs/toolkit/query";
import { PMBaseApi } from "./services/PMBaseApi.js";
import { ChatBaseApi } from "./services/ChatBaseApi.js";
import { retryMiddleware } from "./middlewares/retryMiddleware.js";
import { ADMBaseApi } from "./services/ADMBaseAPi.js";

const reducer = {
  auth: authReducers,
  profile: userProfileReducer,
  text: textReducer,
  userListing: userListingReducer,
  userTeamInviteeListing: userInviteeListingReducer,
  paymentHistory: paymentReducers,
  billing: billingReducer,
  subscription: subscriptionReducer,
  notificationUnReadCount: notificationUnReadCountReducer,
  registeredUserCount: registeredUserCountReducer,
  authOnBoardingScreenContent: authOnBoardingScreenContentReducer,
  modalData: companyModalDataReducer,
  projectListingData: projectListingReducer,
  projectDetailData: projectDetailReducer,
  createProjectData: createProjectReducer,
  editProjectData: editProjectReducer,
  shareProjectData: shareProjectReducer,
  deleteProjectData: deleteProjectReducer,
  createProjectUsers: projectUserListingReducer,
  createProjectTypes: projectTypeListingReducer,
  projectStatusListMain: projectListingStatusReducer,
  projectAllStatusListMain: projectListingAllStatusReducer,
  createProjectPhaseData: createProjectPhaseReducer,
  projectPhasesListingData: getProjectsPhasesReducer,
  updateProjectPhaseData: updateProjectPhaseReducer,
  tasksPrioritiesListData: taskPrioritiesListReducer,
  tasksTypesListData: taskTypesListReducer,
  teamsListData: teamsListReducer,
  projectFormListData: projectFormListingReducer,
  taskListData: taskListReducer,
  timelineStatsData: timelineStatsReducer,
  timelineActiveProjectsData: timeActiveProjectsReducer,
  timelineOngoingTaskData: timelineOngoingTaskReducer,
  companyListData: companiesListingReducer,
  taskCachedList: tasksCachedReducer,
  taskCachedCompletedList: tasksCachedCompletedReducer,
  projectCachedList: projectListingCacheReducer,
  projectCompletedListData: projectCompletedListingReducer,
  projectDetailTaskList: projectDetailTasksCachedReducer,
};

let initialState = {};
const middleware = [thunk];

const store = configureStore(
  {
    reducer: {
      ...reducer,
      [PMBaseApi.reducerPath]: PMBaseApi.reducer,
      [ChatBaseApi.reducerPath]: ChatBaseApi.reducer,
      [ADMBaseApi.reducerPath]: ADMBaseApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(
        PMBaseApi.middleware,
        ChatBaseApi.middleware,
        ADMBaseApi.middleware,
        retryMiddleware
      ),
  },
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);
setupListeners(store.dispatch);
export default store;


/* eslint-disable */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React from "react";
import LeadsNewMain from "./components/LeadsNewMain";

const LeadsNew = (props) => {
  return (
    <LeadsNewMain
      isDark={props.isDark}
      collapse={props.collapse}
      setCollapse={props.setCollapse}
      setOpenSubscriptionsModal={props.setOpenSubscriptionsModal}
    />
  );
};

export default LeadsNew;

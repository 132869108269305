/* eslint-disable */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Modal, Spinner } from "react-bootstrap";
import DeleteIcon from "../../../assets/delete-circle.svg";
import useDeleteScheduleModal from "../services/useDeleteScheduleModal";

const DeleteScheduleModal = ({ data, show, handleCloseModal, isDark }) => {
  const { isDeleting, handleDeleteSchedule } =
    useDeleteScheduleModal(handleCloseModal); // Use the custom hook

  // Function to run delete hook
  const deleteWorkingHour = async () => {
    try {
      await handleDeleteSchedule(data?.id); // Call the delete handler with the schedule ID
    } catch (error) {
      console.error("Error deleting schedule:", error);
    }
  };

  return (
    <Modal
      show={show}
      onHide={handleCloseModal}
      className="main-delete-modal"
      data-theme={isDark}
      centered
    >
      <Modal.Body className="d-flex align-items-center justify-content-center text-center flex-column update-modal-body">
        <div className="del-modal-img-container">
          <img src={DeleteIcon} alt="delete-icon" />
        </div>
        <h5>Do you want to delete schedule?</h5>
        <p className="del-modal-name">{data?.shiftTitle}</p>
      </Modal.Body>
      <Modal.Footer>
        <div className="w-100 delete-modal-btn-wrapper">
          <button
            className="w-50 delete-btn-1"
            onClick={deleteWorkingHour}
            disabled={isDeleting} // Disable the button while deleting
          >
            {isDeleting ? (
              <Spinner
                style={{ width: "1rem", height: "1rem" }}
                animation="border"
              />
            ) : (
              "Remove"
            )}
          </button>
          <button className="w-50 delete-btn-2" onClick={handleCloseModal}>
            Cancel
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteScheduleModal;

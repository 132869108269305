/* eslint-disable */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  ShimmerText,
  ShimmerCircularImage,
  ShimmerTitle,
  ShimmerButton,
} from "react-shimmer-effects";

export default function CompanyProfileLoading({ isDark }) {
  return (
    <div data-theme={isDark} className="container-fluid py-4 bg-transparent">
      {/* Company Info Section */}
      <div className="mb-4">
        <div style={{
                    borderColor: "var(--divider-color) !important",
                  }}  className="card p-4 bg-transparent">
          <div className="d-flex gap-4 w-100">
            {/* Company Logo */}
            <div
              className="bg-light rounded-circle overflow-hidden d-flex justify-content-center align-items-center"
              style={{ width: "140px", height: "140px" }}
            >
              <ShimmerCircularImage size={140} />
            </div>

            {/* Company Details */}
            <div>
              <ShimmerTitle line={1} gap={10} className="mb-3" />
              <div>
                <ShimmerText line={0.2} gap={10} className="mb-2" />
                <ShimmerText line={1} gap={10} className="mb-2" />
                <ShimmerText line={1} gap={10} />
              </div>
            </div>
          </div>

          <div className="mt-4">
            <ShimmerTitle line={1} gap={10} width={80} className="mb-2" />
            <ShimmerText line={3} gap={10} />
          </div>
        </div>
      </div>

      {/* Working Hours Section */}
      <div className="mb-4">
        <div style={{
                    borderColor: "var(--divider-color) !important",
                  }} className="card p-4 bg-transparent">
          {[1, 2].map((shift) => (
            <div
              key={shift}
              style={{
                borderColor: "var(--divider-color) !important",
              }}
              className="d-flex justify-content-between align-items-center py-2 border-bottom"
            >
              <div className="d-flex gap-4 align-items-center">
                <ShimmerCircularImage size={48} className="me-2" />
                <div className="flex-1">
                  <ShimmerText line={1} gap={10} width={200} className="mb-1" />
                  <ShimmerText line={1} gap={10} width={140} />
                </div>
              </div>
              <div className="d-flex gap-2">
                <ShimmerButton size="sm" width={80} />
                <ShimmerButton size="sm" width={80} />
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Company Members Section */}
      <div>
        <div style={{
                    borderColor: "var(--divider-color) !important",
                  }} className="card p-4 bg-transparent">
          <div className="row g-3">
            {[...Array(12)].map((_, i) => (
              <div key={i} className="col-6 col-md-3">
                <div
                  style={{
                    borderColor: "var(--divider-color) !important",
                  }}
                  className="d-flex gap-2 align-items-center p-2 border rounded"
                >
                  <div
                    className="bg-light rounded-circle d-flex justify-content-center align-items-center"
                    style={{ width: "48px", height: "48px" }}
                  >
                    <ShimmerCircularImage size={48} />
                  </div>
                  <div>
                    <ShimmerTitle
                      line={1}
                      gap={10}
                      width={100}
                      className="mb-1"
                      variant="secondary"
                    />
                    <ShimmerText line={1} gap={10} width={80} />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

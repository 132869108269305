import { useEffect, useState, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import API from "../../../services/ClientApi";
import { userProfilePage } from "../../../actions/userActions";

const useTour = () => {
  const dispatch = useDispatch();
  const [runTour, setRunTour] = useState(false);
  const { userProfile } = useSelector((state) => state?.profile);
  const isUpdatingRef = useRef(false); // Prevent multiple API calls

  useEffect(() => {
    if (userProfile?.performanceListingTour) {
      setRunTour(true);
    }
  }, [userProfile]);

  const updateProfile = useCallback(async () => {
    if (isUpdatingRef.current) return; // Prevent duplicate calls
    isUpdatingRef.current = true;

    try {
      setRunTour(false);
      const response = await API.post("users/update-profile", {
        userId: userProfile?.userId,
        fieldName: "performanceListingTour",
        fieldValues: false,
      });
      if (response?.status === 200) {
        await dispatch(userProfilePage());
      }
    } catch (error) {
      console.error("Error updating profile:", error);
    } finally {
      isUpdatingRef.current = false; // Reset flag
    }
  }, [dispatch, userProfile?.userId]);

  return { runTour, setRunTour, updateProfile };
};

export default useTour;

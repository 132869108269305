

import { Icon } from "@iconify/react/dist/iconify.js";
import React from "react";

const Button = ({
  icon = null,
  text,
  handleClick,
  margin,
  width,
  type,
  border,
  disabled,
  padding,
  iconify = null,
}) => {
  const buttonStyle = {
    margin: margin || 0,
    width: width || "100%",
  };
  return (
    <button
      type={type}
      className={`custom-button-main profile-custom-button-main ${
        border ? "custom-border-btn" : ""
      }`}
      style={buttonStyle}
      onClick={handleClick}
      disabled={disabled ? disabled : false}
    >
      {iconify && <Icon icon={iconify} className="me-1" width={18} height={18}/>}
      {icon && <i className={`button-icon fa ${icon}`} aria-hidden="true"></i>}
      {text && <span className="button-text">{text}</span>}
    </button>
  );
};

export default Button;

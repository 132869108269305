/* eslint-disable */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import LogoAnim from "../assets/iffra-logo.png";
import "./styles/screenLoader.css";
import { useSelector } from "react-redux";
import ProgressBar from "@ramonak/react-progress-bar";

const ScreenLoader = (props) => {
  const { applicationName } = useSelector((state) => state.text);

  return (
    // <div className="gif-wraper">
    <div className="d-flex align-items-center justify-content-center progress-state initial-progress-wrap">
      <div className="d-flex flex-column align-items-center justify-content-end progress-wrap-container">
        <img
          src={LogoAnim}
          alt={`${applicationName} animated logo`}
          className="screenloader-image"
        />
        {/* <p className="progress-bar-text" style={{ color: "#94abff" }}>
        Project Management Partner
      </p> */}
        <ProgressBar
          className="progressbar-wrapper"
          completed={props.progress}
        />
      </div>
    </div>
  );
};

export default ScreenLoader;

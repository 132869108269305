/* eslint-disable */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import userProfileBlank from "../../../assets/blank_profile.svg";
import { useNavigate } from "react-router-dom";

const CompanyMemberCard = ({ member }) => {
  const navigate = useNavigate();

  return (
    <div
      className="company-member-item"
      onClick={() =>
        navigate(
          `/${localStorage.getItem("company")}/user-profile?id=${member?.id}`
        )
      }
    >
      <div className="company-member-image-container">
        <img
          src={member?.profileImage ? member?.profileImage : userProfileBlank}
          alt="Profile_icon"
          className="company-member-item-profile-img"
        />
      </div>
      <div className="company-member-item-team-info">
        <h4 className="company-member-item-name">{member?.fullName}</h4>
        <p className="company-member-item-role">{member?.designation}</p>
      </div>
    </div>
  );
};

export default CompanyMemberCard;

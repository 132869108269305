/* eslint-disable */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import "./paymentmodal.css";
import API from "../../services/ClientApi";
import { useDispatch } from "react-redux";
import Group from "../../assets/group_cards.png";
import { toast } from "react-toastify";
import { OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import { userProfilePage } from "../../actions/userActions";
import { billingData, paymentListing } from "../../actions/paymentActions";
import { Icon } from "@iconify/react";
import { dateFormatter } from "../../helper/dateFormatter";

const options = {
  style: {
    base: {
      color: "#333333",
      margin: "10px 0 20px 0",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

const PaymentModal = ({
  isOpen,
  onClose,
  user,
  transaction,
  amountTask,
  packageData,
  getPackageName = null,
  selectedUser,
  checkSeats,
  packageName,
  seat,
  amt,
}) => {
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const [load, setLoad] = useState(false);
  const [data, setData] = useState({
    amount: amountTask ? amountTask : "",
  });
  const [errors, setErrors] = useState({
    // amount: "",
    cardNumber: "",
    cardExpiry: "",
    cardCvc: "",
  });

  if (!isOpen) {
    return null;
  }

  const setField = (field, value) => {
    setData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleInputChange = (field, value) => {
    // Clear the error for the field when its value changes
    setErrors((prevErrors) => ({
      ...prevErrors,
      [field]: "",
    }));

    setField(field, value);
  };

  const paymentHandler = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    setLoad(true);

    const cardElement = elements.getElement(CardNumberElement);
    const cardExpiryElement = elements.getElement(CardExpiryElement);
    const cardCvcElement = elements.getElement(CardCvcElement);
    const { token, error } = await stripe.createToken(cardElement);

    const emptyFields = {
      cardNumber: cardElement._empty,
      cardExpiry: cardExpiryElement._empty,
      cardCvc: cardCvcElement._empty,
    };

    const invalidFields = {
      cardNumber: cardElement._invalid,
      cardExpiry: cardExpiryElement._invalid,
      cardCvc: cardCvcElement._invalid,
    };

    const newErrors = {};
    // if (data?.amount === "") {
    //   newErrors["amount"] = "Field is required";
    // }
    // if (data?.amount < 25) {
    //   newErrors["amount"] = "Minimum transaction amount is $25";
    // }

    // Check for empty and invalid fields
    Object.keys(emptyFields).forEach((field) => {
      if (emptyFields[field]) {
        newErrors[field] = "Field is required";
      }
    });

    Object.keys(invalidFields).forEach((field) => {
      if (invalidFields[field]) {
        newErrors[field] = "Field is invalid";
      }
    });

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setLoad(false);
      return;
    }
    if (Object.keys(newErrors).length <= 0) {
      if (token === undefined) {
        console.log("New errors : ", error);
        onClose();
        toast.error(error?.message || "Error occured", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
      } else {
        try {
          setLoad(true);
          if (selectedUser.length > 0) {
            const response = await API.post("company/bulk-users-deleted", {
              userIds: selectedUser,
            });
            if (response?.data?.status === "success") {
              const config = {
                headers: {
                  "Content-Type": "application/json",
                },
              };
              const res = await API.post(
                `company/process-payment`,
                {
                  ...packageData,
                  totalAmount: parseFloat(amt),
                  token: token?.id,
                },
                config
              );

              // const clientSecret = res?.data?.client_secret;

              if (!stripe || !elements) {
                return;
              }
              if (res.error) {
                setLoad(false);
                onClose();
                toast.error("Error occured", {
                  position: toast.POSITION.TOP_CENTER,
                  autoClose: 3000,
                });
              } else {
                getPackageName !== null && (await getPackageName());
                setLoad(false);
                setData({ ...data, amount: "" });
                onClose();
                toast.success("Payment successfull", {
                  position: toast.POSITION.TOP_CENTER,
                  autoClose: 3000,
                });
                dispatch(userProfilePage());
                dispatch(paymentListing());
                dispatch(billingData());
                localStorage.setItem("show", true);
                transaction();
              }
            }
            checkSeats();
          } else {
            const config = {
              headers: {
                "Content-Type": "application/json",
              },
            };
            const res = await API.post(
              `company/process-payment`,
              {
                ...packageData,
                totalAmount: parseFloat(amt),
                token: token?.id,
                seatCount: parseInt(seat),
              },
              config
            );

            // const clientSecret = res?.data?.client_secret;

            if (!stripe || !elements) {
              return;
            }
            if (res.error) {
              setLoad(false);
              onClose();
              toast.error("Error occured", {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000,
              });
            } else {
              getPackageName !== null && (await getPackageName());
              setLoad(false);
              setData({ ...data, amount: "" });
              onClose();
              toast.success("Payment successfull", {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000,
              });
              dispatch(userProfilePage());
              dispatch(paymentListing());
              dispatch(billingData());
              localStorage.setItem("show", true);
              transaction();
            }
          }
        } catch (error) {
          onClose();
          // toast.error("Error occured", {
          //   position: toast.POSITION.TOP_CENTER,
          //   autoClose: 3000,
          // });
          setLoad(false);
        }
      }
    }
  };

  const mapIntervalToText = (interval) => {
    switch (interval) {
      case "month":
        return "Monthly";
      case "year":
        return "Yearly";
      default:
        return "";
    }
  };

  const currentDate = new Date();
  const nextYearDate = new Date(currentDate);
  nextYearDate.setFullYear(currentDate.getFullYear() + 1);

  const nextMonthDate = new Date(currentDate);
  nextMonthDate.setMonth(currentDate.getMonth() + 1);

  return (
    <div
      className="modal-overlay-funds"
      onClick={() => {
        setErrors({
          //   amount: "",
          cardNumber: "",
          cardExpiry: "",
          cardCvc: "",
        });
      }}
    >
      <div
        className="noneed-toadd-wrap"
        style={{ width: "800px", margin: "0 auto" }}
      >
        <div className="popup-new-stripe-wrap">
          <div className="row">
            <div className="col-md-6">
              <div className="listing-popup-plan-summary">
                <h4>
                  {" "}
                  Plan <span style={{ fontWeight: 300 }}>
                    Payment Summary
                  </span>{" "}
                </h4>
                <ul>
                  <li>
                    {" "}
                    Package{" "}
                    <strong className="stripe-package-name">
                      {" "}
                      {packageName}{" "}
                    </strong>{" "}
                  </li>
                  <li>
                    {" "}
                    Seats <strong> {seat} </strong>{" "}
                  </li>
                  <li>
                    {" "}
                    Duration{" "}
                    <strong>
                      {" "}
                      {mapIntervalToText(packageData?.interval)}{" "}
                    </strong>{" "}
                  </li>
                  <li>
                    {" "}
                    Activation Date{" "}
                    <strong> {dateFormatter(new Date())} </strong>{" "}
                  </li>
                  <li>
                    {" "}
                    Expiary Date{" "}
                    <strong>
                      {" "}
                      {dateFormatter(
                        packageData?.interval === "year"
                          ? nextYearDate
                          : nextMonthDate
                      )}{" "}
                    </strong>{" "}
                  </li>
                  <li className="total-amount-wrap">
                    {" "}
                    Total{" "}
                    {packageData?.interval === "year" ? (
                      <strong>
                        <span
                          style={{ fontSize: "12px" }}
                        >{`(12 months X $${Math.round(amt / 12)})`}</span>{" "}
                        ${amt}
                      </strong>
                    ) : (
                      <strong>${amt}</strong>
                    )}
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-6">
              <div className="stripe-main-form-wraper">
                <div className="w-100 stripe-logo-wrap d-flex justify-content-center">
                  {" "}
                  <img
                    className="stripe-logo-main"
                    src={Group}
                    alt="strip-logo"
                  />{" "}
                </div>
                <form className="row" onSubmit={!load && paymentHandler}>
                  <div className="col-md-12">
                    <div className="fund-group form-group fund-info-relative">
                      <label htmlFor="card_num_field">Card Number </label>
                      <div
                        className={`cn-wrapper ${
                          errors?.cardNumber ? "payment-modal-field-err" : ""
                        }`}
                      >
                        <CardNumberElement
                          type="text"
                          id="card_num_field"
                          options={options}
                          onChange={(e) =>
                            handleInputChange(
                              "cardNumber",
                              e.complete ? e.error : ""
                            )
                          }
                        />
                      </div>
                      {errors.cardNumber && (
                        <p className="fund-info">{errors.cardNumber}</p>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="fund-group form-group fund-info-relative">
                      <label htmlFor="card_exp_field">Card Expiry </label>
                      <div
                        className={`cn-wrapper ${
                          errors?.cardExpiry ? "payment-modal-field-err" : ""
                        }`}
                      >
                        <CardExpiryElement
                          type="text"
                          id="card_exp_field"
                          options={options}
                          onChange={(e) =>
                            handleInputChange(
                              "cardExpiry",
                              e.complete ? e.error : ""
                            )
                          }
                        />
                      </div>
                      {errors.cardExpiry && (
                        <p className="fund-info">{errors.cardExpiry}</p>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="fund-group form-group fund-info-relative">
                      <label htmlFor="card_cvc_field">
                        Card CVC{" "}
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id="button-tooltip">
                              The Card Verification Code (CVC), also known as
                              Card Verification Value (CVV), is a security
                              feature on credit cards. It is a three- or
                              four-digit number printed on the card, separate
                              from the card number
                            </Tooltip>
                          }
                        >
                          <span className="funds-fields-info">
                            <i className="las la-info-circle"></i>
                          </span>
                        </OverlayTrigger>
                      </label>
                      <div
                        className={`cn-wrapper ${
                          errors?.cardCvc ? "payment-modal-field-err" : ""
                        }`}
                      >
                        <CardCvcElement
                          type="text"
                          id="card_cvc_field"
                          options={options}
                          onChange={(e) =>
                            handleInputChange(
                              "cardCvc",
                              e.complete ? e.error : ""
                            )
                          }
                        />
                      </div>
                      {errors.cardCvc && (
                        <p className="fund-info">{errors.cardCvc}</p>
                      )}
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="buy-plan-button">
                      <button disabled={load}>
                        {" "}
                        {load ? (
                          <Spinner
                            border="animation"
                            style={{
                              width: "1rem !important",
                              height: "1rem !important",
                            }}
                          />
                        ) : (
                          "Buy Plan"
                        )}{" "}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <Icon
            icon="akar-icons:cross"
            className="payment-modal-close-btn"
            onClick={onClose}
          />
        </div>
      </div>
    </div>
  );
};

export default PaymentModal;

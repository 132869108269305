/* eslint-disable */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from "react";
import { IoMdClose } from "react-icons/io";
import { Icon } from "@iconify/react";

const TimePicker = ({
  time,
  setTime,
  isDark,
  placeholder,
  minValue,
  maxValue,
  timeDifference = 30,
  isInvalid,
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [position, setPosition] = useState("bottom");
  const pickerRef = useRef(null);
  const dropdownRef = useRef(null);

  const handleIconClick = () => {
    setShowDropdown(!showDropdown);
  };

  const handleClickOutside = (e) => {
    if (
      pickerRef.current &&
      !pickerRef.current.contains(e.target) &&
      dropdownRef.current &&
      !dropdownRef.current.contains(e.target)
    ) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    window.addEventListener("click", handleClickOutside);
    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (showDropdown && pickerRef.current && dropdownRef.current) {
      const { bottom, height } = pickerRef.current.getBoundingClientRect();
      const dropdownHeight = dropdownRef.current.offsetHeight;
      const windowHeight = window.innerHeight;

      if (bottom + dropdownHeight > windowHeight) {
        setPosition("top");
      } else {
        setPosition("bottom");
      }
    }
  }, [showDropdown]);

  const generateTimeSlots = () => {
    const slots = [];
    const interval = Math.max(1, Math.min(60, timeDifference)); // Ensure timeDifference is between 1 and 60
    let currentHour = 0;
    let currentMinute = 0;

    while (currentHour < 24) {
      const slot = `${currentHour.toString().padStart(2, "0")}:${currentMinute
        .toString()
        .padStart(2, "0")}`;

      if ((!minValue || slot > minValue) && (!maxValue || slot < maxValue)) {
        slots.push(slot);
      }

      currentMinute += interval;
      if (currentMinute >= 60) {
        currentMinute = 0;
        currentHour++;
      }
    }

    return slots;
  };

  const timeSlots = generateTimeSlots();

  return (
    <div
      data-theme={isDark}
      className="project-details-main-date-filter d-flex align-items-center justify-content-between position-relative"
      ref={pickerRef}
      onClick={handleIconClick}
    >
      <div
        className={`d-flex align-items-center set-hour-input form-control ${
          isInvalid ? "is-invalid" : ""
        }`}
      >
        <p className="p-0 m-0" style={{ fontWeight: 300, fontSize: "14px" }}>
          {time ? time : placeholder}
        </p>
        {time && (
          <div
            className="select-icon ms-1 select-icon-date"
            style={{
              border: "1px solid lightgray",
              borderRadius: "4px",
              padding: "0px 3px",
            }}
            onClick={(e) => {
              e.stopPropagation();
              setTime(null);
              setShowDropdown(false);
            }}
          >
            <IoMdClose
              style={{ cursor: "pointer", color: "var(--font-color)" }}
              className="me-0"
            />
          </div>
        )}
        <div
          className="select-icon select-hour-icon-date"
          onClick={(e) => {
            e.stopPropagation();
            handleIconClick();
          }}
        >
          <Icon icon="famicons:time-outline" className="me-2" />
        </div>
      </div>

      {showDropdown && (
        <div
          className={`select-hour-time-picker-dropdown select-hour-time-picker-${position}`}
          ref={dropdownRef}
          style={{
            ...(position === "bottom" && { top: "100%" }),
            ...(position === "top" && { bottom: "100%" }),
          }}
        >
          {timeSlots.map((slot) => (
            <button
              key={slot}
              className={`select-hour-time-picker-slot ${
                time === slot ? "active" : ""
              }`}
              onClick={(e) => {
                e.stopPropagation();
                setTime(slot);
                setShowDropdown(false);
              }}
              style={{
                width: "100%",
                padding: "10px",
                textAlign: "left",
                background: time === slot ? "#f0f0f0" : "#fff",
                border: "none",
                cursor: "pointer",
                transition: "background-color 0.3s ease",
              }}
            >
              {slot}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default TimePicker;

import ProductHeader from "./ProductHeader";
import ProductCard from "./ProductCard";
import "../styles/product.css";
import iffraLogo from "../../../assets/iffra-task-pro-logo.png";
import iffraCRMLogo from "../../../assets/iffra-crm-logo.png";
import iffraChatLogo from "../../../assets/iffra-chat-logo.png";
import iffraHRLogo from "../../../assets/iffra-hr-logo.png";
import iffraBookingSystemLogo from "../../../assets/iffra-booking-system-logo.png";

const ProductsPage = () => {
  const products = [
    {
      icon: iffraLogo,
      title: "iffra - Task Pro",
      description:
        "Organize tasks, enhance collaboration, and track progress seamlessly with our task management software.",
      isComingSoon: false,
    },
    {
      icon: iffraCRMLogo,
      title: "iffra - CRM",
      description:
        "Streamline customer interactions, boost sales, and manage relationships effortlessly with our CRM system.",
      isComingSoon: false,
    },
    {
      icon: iffraChatLogo,
      title: "iffra - Chats",
      description:
        "Engage in seamless, real-time conversations, where every message flows effortlessly, providing quick answers, personalized insights.",
      isComingSoon: false,
    },
    {
      icon: iffraHRLogo,
      title: "iffra - HR",
      description:
        "Streamline your workforce management with an all-in-one HR system for efficiency and growth.",
      isComingSoon: true,
    },
    {
      icon: iffraBookingSystemLogo,
      title: "iffra - Booking System",
      description:
        "Seamlessly book your desired services in just a few simple steps, ensuring a smooth and hassle-free experience.",
      isComingSoon: true,
    },
  ];

  return (
    <div className="iffra-plugins-products-container p-4">
      <div className=" p-3 iffra-plugins-products-container-wrap">
        <h1 className="iffra-plugins-page-title mb-5">Products</h1>
        <ProductHeader />
        <div className="row g-4">
          {products.map((product, index) => (
            <div key={index} className="col-md-6">
              <ProductCard {...product} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProductsPage;

import React from "react";
import { Steps } from "intro.js-react";
import useTour from "../services/useTour";

function PSMainHeader({ handleUpdateIsShowAddNewKPI }) {
  const { runTour, setRunTour, updateProfile } = useTour();

  const steps = [
    {
      element: ".add-kpi-btn",
      intro:
        "Create your own performance model as per the company policy and apply to the users from their profile settings. Otherwise, default shall be applied to every user.",
      position: "left",
    },
  ];

  return (
    <div className="d-flex justify-content-between align-items-center mb-4 flex-wrap gap-2 projects-detail-heading-wrap">
      {runTour && (
        <Steps
          enabled={runTour}
          steps={steps.filter((step) => document.querySelector(step.element))}
          initialStep={0}
          options={{
            showProgress: false,
            showStepNumbers: false,
            exitOnOverlayClick: false,
            scrollToElement: false,
            exitOnEsc: true,
            nextLabel: "Next",
            prevLabel: "Skip Tour",
            tooltipClass: "tour-custom-tooltip",
            disableInteraction: true,
          }}
          onExit={async () => {
            if (localStorage.getItem("access_token")) {
              await updateProfile();
            }
          }}
        />
      )}
      <h4 className="projects-detail-heading-wrap mb-0">
        Performance <span>System</span>
      </h4>
      <div className="d-flex align-items-center justify-content-center gap-2">
        <button
          className="ms-0 top-0 create-btn-2 add-kpi-btn"
          onClick={() => handleUpdateIsShowAddNewKPI(true)}
        >
          Add Another KPI
        </button>
      </div>
    </div>
  );
}

export default PSMainHeader;

import React from "react";
import PointInput from "./PointInput";
import usePSMainService from "../services/usePSMainService";
import {
  IoIosArrowDown,
  IoIosArrowUp,
  IoIosInformationCircleOutline,
} from "react-icons/io";
import { Tooltip } from "react-tooltip";
import useTourAddPerformance from "../services/useTourAddPerformance";
import { Steps } from "intro.js-react";

function TaskAssigneeCardMain({
  formik,
  isExpanded,
  toggleExpanded,
  MotionWrapper,
  animationVariants,
  removeError,
}) {
  const { runTour, setRunTour, updateProfile } = useTourAddPerformance();

  const steps = [
    {
      element: ".tour-point-one",
      intro:
        "Define max points against each task, it will be consider as the value of one task.",
      position: "top",
    },
    {
      element: ".tour-point-two",
      intro: "Assign how many points one user can get out of the maximum.",
      position: "top",
    },
    {
      element: ".tour-point-three",
      intro:
        "If there are any deduction if not completed on time, this deduction shall be repeated after every day passed after due date.",
      position: "top",
    },
  ];

  return (
    <div className="card main-card pb-3 border-0">
      <Tooltip id="pskpi-canvas-assigne-card-my-tooltip" />
      {runTour && (
        <Steps
          enabled={runTour}
          steps={steps}
          initialStep={0}
          options={{
            showProgress: false,
            showStepNumbers: false,
            exitOnOverlayClick: false,
            scrollToElement: false,
            exitOnEsc: true,
            nextLabel: "Next",
            prevLabel: "Skip Tour",
            tooltipClass: "tour-custom-tooltip",
            disableInteraction: true,
          }}
          onExit={async () => {
            if (localStorage.getItem("access_token")) {
              await updateProfile();
            }
          }}
        />
      )}
      <div className="card-body">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <div className="d-flex align-items-center">
            <h2 className="section-title mb-0">Points for Task Assignee</h2>
            <button className="info-icon ms-2">
              <IoIosInformationCircleOutline
                size={20}
                data-tooltip-id="pskpi-canvas-assigne-card-my-tooltip"
                data-tooltip-content="Team/employees having tasks to be completed will be judged on these points"
              />
            </button>
          </div>
          <button
            className="btn btn-link p-0 chevron-btn"
            onClick={toggleExpanded}
          >
            {isExpanded ? (
              <IoIosArrowUp size={22} color="var(--font-color)" />
            ) : (
              <IoIosArrowDown size={22} color="var(--font-color)" />
            )}
          </button>
        </div>
        <p className="text-secondary description-text">
          The person responsible for completing the task will earn or lose
          points based on timely completion or delays.
        </p>
        {isExpanded && (
          <MotionWrapper
            initial="hidden"
            animate={isExpanded ? "visible" : "hidden"}
            variants={animationVariants}
          >
            <div className="row g-4 pt-3">
              <div className="col-md-4">
                <PointInput
                  label="Task Points (Maximum points for a task)"
                  value={formik.values.taskAssigneePoints.maxPoints}
                  onChange={(value, e) => {
                    formik.setFieldValue("taskAssigneePoints.maxPoints", value);
                    removeError("taskAssigneePoints.maxPoints");
                  }}
                  error={formik.errors.taskAssigneePoints?.maxPoints}
                  isZeroValue={false}
                  className="total-point-unique-layout tour-point-one"
                />
              </div>
              <div className="col-md-4">
                <PointInput
                  label="Task completion on time/before given time"
                  value={formik.values.taskAssigneePoints.onTimeCompletion}
                  onChange={(value) => {
                    formik.setFieldValue(
                      "taskAssigneePoints.onTimeCompletion",
                      value
                    );
                    removeError("taskAssigneePoints.onTimeCompletion");
                  }}
                  error={formik.errors.taskAssigneePoints?.onTimeCompletion}
                  className="tour-point-two"
                />
              </div>
              <div className="col-md-4">
                <PointInput
                  label="Task completion after due date"
                  value={formik.values.taskAssigneePoints.lateCompletion}
                  onChange={(value) => {
                    formik.setFieldValue(
                      "taskAssigneePoints.lateCompletion",
                      value
                    );
                    removeError("taskAssigneePoints.lateCompletion");
                  }}
                  error={formik.errors.taskAssigneePoints?.lateCompletion}
                  className="tour-point-three"
                />
              </div>
            </div>

            <h2 className="section-title-second mt-4 mb-4">
              Points for Attendance
            </h2>
            <div className="row g-4">
              <div className="col-md-4">
                <PointInput
                  label="Daily Attandance Points"
                  value={formik.values.taskAssigneePoints.dailyAttendancePoints}
                  onChange={(value) => {
                    formik.setFieldValue(
                      "taskAssigneePoints.dailyAttendancePoints",
                      value
                    );
                    removeError("taskAssigneePoints.dailyAttendancePoints");
                  }}
                  error={
                    formik.errors.taskAssigneePoints?.dailyAttendancePoints
                  }
                  isZeroValue={false}
                />
              </div>
              <div className="col-md-4">
                <PointInput
                  label="On time (check-in & check-out)"
                  value={formik.values.taskAssigneePoints.onTimeAttendance}
                  onChange={(value) => {
                    formik.setFieldValue(
                      "taskAssigneePoints.onTimeAttendance",
                      value
                    );
                    removeError("taskAssigneePoints.onTimeAttendance");
                  }}
                  error={formik.errors.taskAssigneePoints?.onTimeAttendance}
                />
              </div>
              <div className="col-md-4">
                <PointInput
                  label="Late check in"
                  value={formik.values.taskAssigneePoints.lateCheckIn}
                  onChange={(value) => {
                    formik.setFieldValue(
                      "taskAssigneePoints.lateCheckIn",
                      value
                    );
                    removeError("taskAssigneePoints.lateCheckIn");
                  }}
                  error={formik.errors.taskAssigneePoints?.lateCheckIn}
                />
              </div>
              <div className="col-md-4">
                <PointInput
                  label="Early check out"
                  value={formik.values.taskAssigneePoints.earlyCheckOut}
                  onChange={(value) => {
                    formik.setFieldValue(
                      "taskAssigneePoints.earlyCheckOut",
                      value
                    );
                    removeError("taskAssigneePoints.earlyCheckOut");
                  }}
                  error={formik.errors.taskAssigneePoints?.earlyCheckOut}
                />
              </div>
              <div className="col-md-4">
                <PointInput
                  label="Leave"
                  value={formik.values.taskAssigneePoints.leave}
                  onChange={(value) => {
                    formik.setFieldValue("taskAssigneePoints.leave", value);
                    removeError("taskAssigneePoints.leave");
                  }}
                  error={formik.errors.taskAssigneePoints?.leave}
                />
              </div>
              <div className="col-md-4">
                <PointInput
                  label="Absent"
                  value={formik.values.taskAssigneePoints.absent}
                  onChange={(value) => {
                    formik.setFieldValue("taskAssigneePoints.absent", value);
                    removeError("taskAssigneePoints.absent");
                  }}
                  error={formik.errors.taskAssigneePoints?.absent}
                />
              </div>
            </div>
          </MotionWrapper>
        )}
      </div>
    </div>
  );
}

export default TaskAssigneeCardMain;

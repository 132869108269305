/* eslint-disable */
/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import API from "../../../services/ClientApi";
import { calculateDiscountedPrice } from "./../../../helper/getDiscountedPrice";
import { calculateMonthDifference } from "./../../../helper/getMonthDifference";
import { dateFormatter } from "./../../../helper/dateFormatter";
import BackIcon from "../../../assets/back.svg";
import { useDispatch } from "react-redux";
import { billingData } from "../../../actions/paymentActions";

const AddOnModal = (props) => {
  const dispatch = useDispatch();
  const [loadbtn, setLoadBtn] = useState(false);
  const [seat, setSeat] = useState(1);
  const [summery, setSummery] = useState(false);

  const handleSubmit = async (price) => {
    setLoadBtn(true);
    try {
      const response = await API.post("company/seats-addons", {
        seatCount: parseInt(seat),
        paymentBy: 1,
        chargedPrice: price.price,
      });
      if (response?.data?.status === "success") {
        toast.success(response?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
        await props.getPlanData();
        await dispatch(billingData());
        props.handleClose();
        setSummery(false);
        setSeat(1);
      }

      if (response === undefined) {
        toast.error("Payment not successful", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
      }
    } catch (error) {
      console.log("Error : ", error);
    } finally {
      setLoadBtn(false);
    }
  };

  return (
    <Modal
      show={props.modal}
      onHide={async () => {
        props.handleClose();
        setSeat(1);
        setSummery(false);
      }}
      className={`main-update-modal main-module-modal add-on-modal ${
        summery ? "summery-section" : ""
      }`}
      data-theme={props.isDark}
      centered
    >
      <Modal.Header className="" closeButton>
        {!summery ? (
          <div className="add-on-popup-wraper">
            <h2 className="d-flex">
              {" "}
              <strong>
                Add<span>-On</span>
              </strong>{" "}
              <span>
                <div className="select-seat-wrap">
                  <select
                    className="form-control"
                    placeholder="Select Seats"
                    value={seat}
                    onChange={(e) => setSeat(e.target.value)}
                  >
                    <option value={1}>1 seat</option>
                    <option value={2}>2 seats</option>
                    <option value={4}>4 seats</option>
                    <option value={6}>6 seats</option>
                    <option value={8}>8 seats</option>
                  </select>
                </div>
              </span>
              <div className="clearfix"></div>
            </h2>
          </div>
        ) : (
          <div className="d-flex align-items-center add-on-popup-wraper">
            <div className="go-back" onClick={() => setSummery(false)}>
              <img src={BackIcon} alt="back-icon" />
            </div>
            <h2>
              Add-on <span>Payment Summary</span>
            </h2>
          </div>
        )}
      </Modal.Header>

      <Modal.Body className="update-modal-body">
        {summery && <hr />}
        {!summery ? (
          <div className="add-on-popup-wraper">
            <div className="package-info-wraper-singal">
              <div className="row">
                <div className="col-md-6">
                  <div className="basic-package-listing-wrap">
                    <h3>Looking for add more team members?</h3>
                    <ul>
                      <li> Simple, easy and hassle free. </li>
                      <li> Same package will continue. </li>
                      <li>There will ne no change in existing team. </li>
                      {props?.planData?.subscriptionPeriod === "year" && (
                        <li>
                          No additional charges: You will be charged only for
                          remaining subscription period.
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="plan-listing-amount-wraper">
                    {" "}
                    <span className="plan-info-wrap">
                      {" "}
                      {props?.planData?.packageName}{" "}
                    </span>{" "}
                    {props?.planData?.subscriptionPeriod === "year" ? (
                      <strong className="price-amount">
                        {" "}
                        <strong>$</strong>
                        {parseFloat(
                          calculateDiscountedPrice(
                            props?.planData?.perSeatCharged,
                            props?.planData?.yearlyDiscountedPercentage
                          ) * seat
                        ).toFixed(2)}
                      </strong>
                    ) : (
                      <strong className="price-amount">
                        {" "}
                        <strong>$</strong>
                        {parseFloat(
                          props?.planData?.perSeatCharged * seat
                        ).toFixed(2)}{" "}
                      </strong>
                    )}
                    <span>Seat / month </span>{" "}
                  </div>
                </div>
              </div>
            </div>
            <div className="choose-continue-btn choose-continue-btn-margin">
              <div className="next-button-wraper module-btn-wrapper">
                <button
                  onClick={() => {
                    if (!summery) {
                      setSummery(true);
                    } else {
                      handleSubmit();
                    }
                  }}
                  disabled={loadbtn}
                  className="module-modal-btn module-main-btn remove-package-modal-btn add-onn-btn"
                >
                  {loadbtn ? <Spinner animation="border" /> : "Continue"}{" "}
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className="add-on-popup-wraper">
            <div className="w-100 d-flex justify-content-between align-items-center summery-wrapper">
              <div className="w-100 package-info-data">
                <div className="w-100 d-flex justify-content-between plan-wrap">
                  <p>Package</p>
                  <p>{props?.planData?.packageName}</p>
                </div>
                <div className="w-100 d-flex justify-content-between plan-wrap">
                  <p>Additional {`${seat === 1 ? "Seat" : "Seats"}`}</p>
                  <p>{seat}</p>
                </div>
                <div className="w-100 d-flex justify-content-between plan-wrap">
                  <p>New Seat Count</p>
                  <p>{`${
                    parseInt(seat) + parseInt(props?.planData?.seatCount)
                  } = ${props?.planData?.seatCount} + ${seat}`}</p>
                </div>
                <div className="w-100 d-flex justify-content-between plan-wrap">
                  <p>Duration</p>
                  <p>{props?.planData?.subscriptionPeriod}</p>
                </div>
                <div className="w-100 d-flex justify-content-between plan-wrap">
                  <p>Effective Date</p>
                  <p>{dateFormatter(new Date())}</p>
                </div>
              </div>
            </div>
            {props?.planData?.subscriptionPeriod === "year" && (
              <p className="add-on-modal-info">
                <span style={{ fontWeight: "500" }}>Note: </span>You will be
                charged only for remaining subscription period.
              </p>
            )}
            <hr />
            <div className="w-100 d-flex justify-content-between align-items-center package-info-data-main">
              <div className="package-info-data">
                <p>Total</p>
              </div>
              <p>
                {props?.planData?.subscriptionPeriod === "year" ? (
                  <p>
                    {props?.planData?.subscriptionPeriod === "year" ? (
                      <span
                        style={{ fontSize: "15px" }}
                      >{`(${calculateMonthDifference(
                        props?.planData?.trialEndDate
                      )} months X $${Math.round(
                        Math.floor(
                          calculateDiscountedPrice(
                            props?.planData?.perSeatCharged,
                            props?.planData?.yearlyDiscountedPercentage
                          ) *
                            seat *
                            calculateMonthDifference(
                              props?.planData?.trialEndDate
                            )
                        ) /
                          calculateMonthDifference(
                            props?.planData?.trialEndDate
                          )
                      )})`}</span>
                    ) : (
                      ""
                    )}{" "}
                    ${" "}
                    {Math.floor(
                      calculateDiscountedPrice(
                        props?.planData?.perSeatCharged,
                        props?.planData?.yearlyDiscountedPercentage
                      ) *
                        seat *
                        calculateMonthDifference(props?.planData?.trialEndDate)
                    )}
                  </p>
                ) : (
                  <p>${Math.floor(props?.planData?.perSeatCharged * seat)}</p>
                )}
              </p>
            </div>
            <div className="choose-continue-btn choose-continue-btn-margin">
              <div className="next-button-wraper module-btn-wrapper">
                <button
                  onClick={() => {
                    if (!summery) {
                      setSummery(true);
                    } else {
                      handleSubmit({
                        price:
                          props?.planData?.subscriptionPeriod === "year"
                            ? Math.floor(
                                calculateDiscountedPrice(
                                  props?.planData?.perSeatCharged,
                                  props?.planData?.yearlyDiscountedPercentage
                                ) *
                                  seat *
                                  calculateMonthDifference(
                                    props?.planData?.trialEndDate
                                  )
                              )
                            : Math.floor(
                                props?.planData?.perSeatCharged * seat
                              ),
                      });
                    }
                  }}
                  disabled={loadbtn}
                  className="module-modal-btn module-main-btn remove-package-modal-btn"
                >
                  {loadbtn ? <Spinner animation="border" /> : "Buy Now"}{" "}
                </button>
              </div>
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default AddOnModal;

import React, { useEffect, useState } from "react";
import { useTimezoneSelect, allTimezones } from "react-timezone-select";
import Select, { components } from "react-select";
import { IoIosArrowDown } from "react-icons/io";
import API from "../../../services/ClientApi";
import { toast } from "react-toastify";

const Timezone = ({ user }) => {
  const [timezone, setTimezone] = useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );

  useEffect(() => {
    if (user?.userInfo?.timeZone) {
      setTimezone(user?.userInfo?.timeZone);
      parseTimezone(user?.userInfo?.timeZone);
    }
  }, [user]);

  const { options, parseTimezone } = useTimezoneSelect({
    timezones: allTimezones,
    defaultTimezone: timezone,
  });

  const handleChange = async (selectedOption) => {
    try {
      setTimezone(selectedOption.value);
      parseTimezone(selectedOption.value);
      const response = await API.post("users/update-profile", {
        userId: user?.userInfo?.userId,
        fieldName: "timeZone", // Add the field name for the specific input field
        fieldValues: selectedOption.value, // Add the corresponding state variable here
      });
      if (response?.status === 200) {
        localStorage.setItem("access_token", response?.data?.access_token);
        toast.success("Profile updated successfully", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
      }
    } catch (error) {
      toast.error("An unexpected error occurred", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    }
  };

  return (
    <div className="listing-info-profile-wrap lang-info-profile-wrap ps-2">
      <h4>Timezone</h4>
      <ul className="row password-row-info-main">
        <li className="col-md-9">
          <div className="overview-profile overview-language">
            <a>
              <div className="overview-profile-image">
                <svg
                  width="25"
                  height="25"
                  viewBox="0 0 11 9"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M0 5.16653C0 4.0821 0 3.53989 0.104468 3.0918C0.450287 1.60849 1.60849 0.450287 3.0918 0.104468C3.53989 0 4.0821 0 5.16653 0H6.31465L6.34753 2.61533e-05C8.54537 0.00797392 10.3251 1.7877 10.333 3.98554L10.3331 4.01842L10.333 4.0513C10.3251 6.24913 8.54537 8.02886 6.34753 8.03681L6.31465 8.03683H2.71045C2.44523 8.03683 2.19088 8.14219 2.00334 8.32972L1.62369 8.70938C1.02451 9.30856 0 8.8842 0 8.03683V5.16653ZM3.44436 2.37028C3.16821 2.37028 2.94436 2.59414 2.94436 2.87028C2.94436 3.14642 3.16821 3.37028 3.44436 3.37028H6.88871C7.16486 3.37028 7.38871 3.14642 7.38871 2.87028C7.38871 2.59414 7.16486 2.37028 6.88871 2.37028H3.44436ZM3.44436 4.66652C3.16821 4.66652 2.94436 4.89037 2.94436 5.16652C2.94436 5.44266 3.16821 5.66652 3.44436 5.66652H5.16653C5.44268 5.66652 5.66653 5.44266 5.66653 5.16652C5.66653 4.89037 5.44268 4.66652 5.16653 4.66652H3.44436Z"
                    fill="currentColor"
                  />
                </svg>
              </div>

              <div className="number-overview-wrap lang-info-num-wrap">
                <strong className="lang-strong">Choose Timezone:</strong>
                <Select
                  className="basic-single profile-single-select"
                  classNamePrefix="select"
                  isSearchable={true}
                  isDisabled={false}
                  isLoading={false}
                  isClearable={false}
                  isRtl={false}
                  options={options}
                  value={{ value: timezone, label: timezone }}
                  components={{ Control: CustomControl }}
                  onChange={handleChange}
                  menuPlacement="auto"
                />
              </div>
            </a>
          </div>
        </li>
      </ul>
    </div>
  );
};

const CustomControl = ({ children,menuIsOpen, ...props }) => (
  <components.Control {...props}>
    {children}
    <div className="select-icon me-1" style={{
      transform: menuIsOpen ? "rotate(180deg)" : "rotate(0deg)",
      transition: "transform 0.3s ease",
    }}>
      <IoIosArrowDown />
    </div>
  </components.Control>
);

export default Timezone;

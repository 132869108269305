import { PMBaseApi } from "../../services/PMBaseApi";
import { ProjectFormDataAPI } from "../../services/ClientApi";
import { projectFormListingAction } from "../../actions/projectActions";
import { teamsListAction } from "../../actions/teamsActions";

export const projectSlice = PMBaseApi.injectEndpoints({
  endpoints: (builder) => ({
    // endpoint for getting all projects
    getProjectListing: builder.query({
      query: (body) => ({
        url: `project/listing`, // API endpoint
        method: "POST",
        body: body,
      }),
      providesTags: ["projectListing"], // Tags used for cache management
      keepUnusedDataFor: 12 * 60 * 60 * 1000, // 12 hours
    }),
    // getProjectListing: builder.query({
    //   query: ({
    //     page = 1,
    //     limit = 20,
    //     orderBy = "",
    //     orderDirection = "",
    //     type,
    //     timeZone,
    //     projectPrivacy,
    //     status,
    //     overdue,
    //     memberFilter,
    //     memberIds,
    //   }) => ({
    //     url: `/project/pm-projects?timeZone=${timeZone}&page=${page}&limit=${limit}&orderBy=${orderBy}&orderDirection=${orderDirection}&type=${type}&projectPrivacy=${projectPrivacy}&status=${status}&overdue=${overdue}&memberFilter=${memberFilter}&memberIds=${memberIds}`,
    //     method: "GET",
    //   }),
    //   keepUnusedDataFor: 12 * 60 * 60 * 1000,

    //   providesTags: ["projectListing"],
    // }),

    // endpoint for getting all completed projects
    getProjectCompletedListing: builder.query({
      query: ({ page, limit, orderBy = "", orderDirection = "" }) => ({
        url: `/project/completed-list?page=${page}&limit=${limit}&orderBy=${orderBy}&orderDirection=${orderDirection}`,
        method: "GET",
      }),
      keepUnusedDataFor: 12 * 60 * 60 * 1000,

      providesTags: ["projectCompletedListing"],
    }),

    postUpdateProject: builder.mutation({
      query: (data) => ({
        url: "/project/pm-update-project",
        method: "POST",
        body: data,
      }),
      invalidatesTags: (result, error, formData) => {
        // Conditionally invalidate tags based on formData
        if (
          formData?.fieldName === "projectName" ||
          formData?.fieldName === "projectIcon" ||
          formData?.fieldName === "isPined" ||
          formData?.fieldName === "projectStatus"
        ) {
          return ["projectListing"];
        }
        return ["timelineActiveProjects", "projectCompletedListing"];
      },
      onQueryStarted: async (
        formData,
        { dispatch, queryFulfilled, getState }
      ) => {
        try {
          await queryFulfilled;

          dispatch(projectFormListingAction());
        } catch (error) {
          console.error("Error updating project cache:", error);
        }
      },
    }),

    // endpoint for project details
    postProjectDetails: builder.mutation({
      query: (data) => ({
        url: "/project/detail",
        method: "POST",
        body: data,
      }),
      // providesTags: ["ProjectDetail"],
    }),

    // endpoint for project update members
    postUpdateProjectMembers: builder.mutation({
      query: (data) => ({
        url: "/project/pm-update-project-members",
        method: "POST",
        body: data,
      }),
      invalidatesTags: [
        "projectListing",
        "timelineActiveProjects",
        "projectCompletedListing",
      ],
      // Hook to handle cache updates after the mutation is successful
      onQueryStarted: async (
        params,
        { dispatch, queryFulfilled, getState }
      ) => {
        try {
          const response = await queryFulfilled; // Await the response after mutation
          const updatedTask = response?.data?.data; // Extract updated task data
          const cache = getState()[projectSlice.reducerPath].queries; // Access the Redux cache

          if (!params.isCompletedProjectListing) {
            // Construct the cache key using predefined parameters
            const cachedKey = constructCacheKey({
              limit: params?.limit,
              page: params?.page,
            });
            const cachedData = cache[cachedKey]?.data?.data; // Get the cached data if available

            if (cachedData) {
              // Update the cached task if found
              updateCachedProject(
                cachedData,
                updatedTask,
                dispatch,
                projectSlice,
                {
                  limit: params?.limit,
                  page: params?.page,
                }
              );
            }
          } else {
            const cachedKey = constructCompletedCacheKey({
              limit: params?.limit,
              page: params?.page,
            });
            const cachedData = cache[cachedKey]?.data?.data; // Get the cached data if available

            if (cachedData) {
              // Update the cached task if found
              updateCachedProjectCompleted(
                cachedData,
                updatedTask,
                dispatch,
                projectSlice,
                {
                  limit: params?.limit,
                  page: params?.page,
                }
              );
            }
          }
        } catch (error) {
          console.error("Error updating task member data:", error); // Log error if mutation fails
        }
      },
    }),

    // Mutation to update bulk task data
    updateBulkProjectData: builder.mutation({
      query: (data) => ({
        url: `project/bulk-actions`, // API endpoint to update task
        method: "POST",
        body: data, // Data to be sent in the request body
      }),
      invalidatesTags: [
        "projectListing",
        "timelineActiveProjects",
        "projectCompletedListing",
      ],
      // Hook to handle cache updates after the mutation is successful
      onQueryStarted: async (formData, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled; // Await the response after mutation
          dispatch(projectFormListingAction());
          dispatch(teamsListAction());
        } catch (error) {
          console.error("Error creating task:", error); // Log error if mutation fails
        }
      },
    }),

    // endpoint for project delete
    deleteProjects: builder.mutation({
      query: (data) => ({
        url: "/project/bulk-delete",
        method: "POST",
        body: data,
      }),
      invalidatesTags: [
        "projectListing",
        "timelineActiveProjects",
        "projectCompletedListing",
        "phaseTasksListing",
        "taskListing",
        "completedTaskListing",
        "timelineOngoingTasks",
      ],
      onQueryStarted: async (formData, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled; // Await the response after mutation
          dispatch(projectFormListingAction());
          dispatch(teamsListAction());
        } catch (error) {
          console.error("Error creating task:", error); // Log error if mutation fails
        }
      },
    }),

    // Mutation to create a new task using form data
    createProjectData: builder.mutation({
      // Custom query function to handle the API request
      queryFn: async (formData, { dispatch }) => {
        try {
          // Use your original API call to post form data
          const response = await ProjectFormDataAPI.post(
            `project/pm-create-project`,
            formData
          );
          dispatch(projectFormListingAction());
          dispatch(teamsListAction());
          return { data: response.data };
        } catch (error) {
          return {
            error: { status: error.response?.status, data: error.message },
          };
        }
      },

      // Hook to handle cache updates after the mutation is successful
      onQueryStarted: async (formData, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled; // Await the response after mutation

          // Automatically re-fetch the task listing after a task is created
          dispatch(projectSlice.util.invalidateTags(["projectListing"]));
          dispatch(
            projectSlice.util.invalidateTags(["timelineActiveProjects"])
          );
        } catch (error) {
          console.error("Error creating task:", error); // Log error if mutation fails
        }
      },
    }),

    // endpoint for timeline active projects
    getTimelineActiveProjects: builder.query({
      query: () => ({
        url: `/timeline/active-projects`,
        method: "GET",
      }),
      keepUnusedDataFor: 12 * 60 * 60 * 1000,
      providesTags: ["timelineActiveProjects"],
    }),

    // endpoint for timeline active projects
    getDailyTaskReport: builder.query({
      query: ({
        filterType,
        selectedDateFilter,
        userId,
        startDate,
        endDate,
      }) => ({
        url: `/task/daily-summary?filterType=${filterType}${
          filterType === "date"
            ? `&selectedDate=${selectedDateFilter}`
            : filterType === "date_range"
            ? `&startDate=${startDate}&endDate=${endDate}`
            : ""
        }${userId ? `&userId=${userId}` : ""}`, // Construct the URL with query parameters
        method: "GET",
      }),
    }),
    // endpoint for timeline active projects
    getStaffSummaryReport: builder.query({
      query: ({
        filterType,
        selectedDateFilter,
        sendEmail,
        startDate,
        endDate,
      }) => ({
        url: `/task/daily-staff-summary?filterType=${filterType}${
          filterType === "date"
            ? `&selectedDate=${selectedDateFilter}`
            : filterType === "date_range"
            ? `&startDate=${startDate}&endDate=${endDate}`
            : ""
        }${sendEmail ? `&sendEmail=${sendEmail}` : ""}`, // Construct the URL with query parameters
        method: "GET",
      }),
    }),
  }),
});

export const {
  useGetProjectListingQuery,
  // useGetProjectListingMutation,
  usePostUpdateProjectMutation,
  usePostProjectDetailsMutation,
  usePostUpdateProjectMembersMutation,
  useDeleteProjectsMutation,
  useGetProjectCompletedListingQuery,
  useCreateProjectDataMutation,
  useGetTimelineActiveProjectsQuery,
  useUpdateBulkProjectDataMutation,
  // usePostUpdateDailyLogsMutation,
  useGetDailyTaskReportQuery,
  useGetStaffSummaryReportQuery,
} = projectSlice;

// Helper function to construct cache keys based on query parameters
const constructCacheKey = (params) =>
  `getProjectListing(${JSON.stringify(params)})`;

const constructCompletedCacheKey = (params) =>
  `projectCompletedListing(${JSON.stringify(params)})`;

// Function to update a specific task in the cached task listing
const updateCachedProject = (
  cachedData,
  updatedTask,
  dispatch,
  projectSlice,
  params
) => {
  // Find the index of the task to update in the cached data
  const taskIndex = cachedData.findIndex(
    (task) => task.projectId === updatedTask.id
  );
  let newUpdatedProject = {
    ...updatedTask,
    projectId: updatedTask.id,
  };
  if (taskIndex !== -1) {
    // If the task exists, update it in the cache using `updateQueryData`
    dispatch(
      projectSlice.util.updateQueryData(
        "getProjectListing", // The query endpoint to update
        params, // The parameters used to fetch the query data
        (draft) => {
          // Directly modify the draft to update the task
          draft.data[taskIndex] = newUpdatedProject;
        }
      )
    );
  }
};

const updateCachedProjectCompleted = (
  cachedData,
  updatedTask,
  dispatch,
  projectSlice,
  params
) => {
  // Find the index of the task to update in the cached data
  const taskIndex = cachedData.findIndex(
    (task) => task.projectId === updatedTask.id
  );
  let newUpdatedProject = {
    ...updatedTask,
    projectId: updatedTask.id,
  };
  if (taskIndex !== -1) {
    // If the task exists, update it in the cache using `updateQueryData`
    dispatch(
      projectSlice.util.updateQueryData(
        "projectCompletedListing", // The query endpoint to update
        params, // The parameters used to fetch the query data
        (draft) => {
          // Directly modify the draft to update the task
          draft.data[taskIndex] = newUpdatedProject;
        }
      )
    );
  }
};

/* eslint-disable */
/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import NotificationIcon from "../../assets/notification-modal.png";
import "./style.css";
import API from "../../services/ClientApi";
import { toast } from "react-toastify";
import { userProfilePage } from "../../actions/userActions";
import { useDispatch } from "react-redux";

const EnableNotificationModal = ({
  showConfirmModal,
  handleCloseConfirmModal,
  isDark,
  userProfile,
}) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  // Submit handler for enabling notification
  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      const response = await API.post("users/update-profile", {
        userId: userProfile?.userId,
        fieldName: "emailNotification", // Field name for the update
        fieldValues: "true", // Convert boolean to string ("true"/"false")
      });

      if (response.status === 200) {
        toast.success("Notifications enabled successfully", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
        handleCloseConfirmModal();
        dispatch(userProfilePage());
      }
    } catch (error) {
      toast.error("An unexpected error occurred", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      show={showConfirmModal}
      onHide={() => !isLoading && handleCloseConfirmModal()}
      className="main-confirm-bulk-modal"
      data-theme={isDark}
      centered
    >
      <Modal.Body className="d-flex align-items-center justify-content-center text-center flex-column update-modal-body">
        {/* Modal Icon */}
        <div className="bulk-modal-img-container">
          <img
            src={NotificationIcon}
            width={83}
            height={83}
            alt="bulk-modal-icon"
          />
        </div>
        {/* Modal Headings */}
        <h5>Never Miss Out!</h5>
        <p className="mt-4 enable-notification-text">
          Enable notifications to get real-time updates and stay informed!
        </p>
      </Modal.Body>
      {/* Footer Buttons */}
      <Modal.Footer>
        <div className="w-100 delete-modal-btn-wrapper">
          <button
            className="w-50 delete-btn-1"
            onClick={handleSubmit}
            disabled={isLoading}
          >
            {isLoading ? (
              <Spinner
                style={{ width: "1rem", height: "1rem" }}
                animation="border"
              />
            ) : (
              "Enable"
            )}
          </button>
          <button
            className="w-50 delete-btn-2"
            onClick={handleCloseConfirmModal}
            disabled={isLoading}
          >
            Cancel
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default EnableNotificationModal;

import React from "react";
import PerformanceSystemMain from "./components/PerformanceSystemMain";
import "../../styles/projectDetail.scss"
import "../../styles/projects.css"

const PerformanceSystem = ({ isDark }) => {
  return (
    <div data-theme={isDark} className="company-profile-main-wrapper">
      <PerformanceSystemMain isDark={isDark} />
    </div>
  );
};

export default PerformanceSystem;

const ProductHeader = () => {
    return (
      <div className="iffra-plugins-products-header text-center mb-4">
        <h1 className="iffra-plugins-main-title">iffra - Products</h1>
        <p className="iffra-plugins-main-description">
          Explore our range of powerful tools designed to enhance your experience, boost productivity, and simplify your
          workflow
        </p>
      </div>
    )
  }
  
  export default ProductHeader
  
  
import React from "react";
import Cookies from "js-cookie";
const clearAllCookies = () => {
  const cookies = document.cookie.split("; "); // Get all cookies

  cookies.forEach((cookie) => {
    const [name] = cookie.split("=");
    Cookies.remove(name, { path: "/", domain: process.env.REACT_APP_DOMAIN });
  });
};
function saveLocalStorageToCookies() {
  clearAllCookies();
  const cookieOptions = {
    path: "/",
  };
  // Only set domain for production
  if (window.location.hostname !== "localhost") {
    cookieOptions.domain = process.env.REACT_APP_DOMAIN; // Works on real domains
    cookieOptions.secure = true; // Ensures security on HTTPS
    cookieOptions.sameSite = "None"; // Required for cross-domain cookies
  }

  Cookies.set("logout_state", true, cookieOptions);

  const manageDomain = process.env.REACT_APP_AUTH_URL;

  window.location.href = `${manageDomain}/login`;
}

export default saveLocalStorageToCookies;

/* eslint-disable */
/* eslint-disable no-unused-vars */
import React, { useState, useMemo, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import Dropdown from "react-bootstrap/Dropdown";
import { useSelector } from "react-redux";
import CustomPagination from "../../../components/CustomPagination";
import { useDispatch } from "react-redux";
import "../styles/leadsNew.css";
import API from "../../../services/ClientApi";
import Eyes from "../../../assets/eyes.svg";
import Delete from "../../../assets/delete.svg";
import Edit from "../../../assets/edit.svg";
import InviteIcon from "../../../assets/add-invite.svg";
import { Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import Papa from "papaparse";
import Modal from "react-bootstrap/Modal";
import DeleteUserModal from "./DeleteUserModal";
import { v4 as uuidv4 } from "uuid";
import InviteModal from "../../../components/InviteModal/InviteModal";
import ReinviteModal from "./ReinviteModal";
import {
  userInviteeListingPage,
  userListingPage,
} from "../../../actions/companyOverviewActions";
import { capitalizeFirstLetter } from "../../../helper/capitalizeFirstLetter";
import { findObjectByContentTitle } from "../../../helper/getContentObject";
import SeeMoreModal from "../../../components/SeeMoreModal/SeeMoreModal";
import DropButton from "../../../components/DropButton/DropButton";
import { createPortal } from "react-dom";

const Portal = (props) => {
  return createPortal(props.children, document.body);
};

const TeamInvitations = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userInviteeList } = useSelector(
    (state) => state.userTeamInviteeListing
  );
  const [showModal, setShowModal] = useState(false);
  const [userId, setUserId] = useState(0);
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState(null);
  const [load, setLoad] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(50); // Default page size
  const [totalRows, setTotalRows] = useState(0);
  // State to track checked IDs
  const [checkedIds, setCheckedIds] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [roles, setRoles] = useState([]);
  const [showAddRoleModal, setShowAddRoleModal] = useState(false);
  const colors = ["#b0e57c", "#56baec", "#b4d8e7"];
  const [selectedRole, setSelectedRole] = useState(roles[0]?.roleId);
  const [selectedModule, setSelectedModule] = useState();
  const [selectedUser, setSelectedUser] = useState(null);
  const [rload, setRLoad] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [modal, setModal] = useState(false);
  const [email, setEmail] = useState("");
  const [search, setSearch] = useState("");
  const [id, setId] = useState(0);
  const [filteredData, setFiltersData] = useState(null);
  const [seeMore, setSeeMore] = useState(false);
  const { modalContents } = useSelector((state) => state?.modalData);

  const teamInvitationsModalContent = findObjectByContentTitle(
    "team_invitations",
    modalContents?.data
  );

  const gridRef = useRef(null);
  const [hoveredRow, setHoveredRow] = useState(null);
  const [showMore, setShowMore] = useState(false);
  const [gridApi, setGridApi] = useState(null);
  const [optionsColumnPosition, setOptionsColumnPosition] = useState({
    top: 0,
    left: 0,
  });
  const [xValue, setXValue] = useState(0);
  const [yValue, setYValue] = useState(0);
  const optionsDropdownRef = useRef(null);
  const [hoveredMenu, setHoveredMenu] = useState(null);
  const [showMenu, setShowMenu] = useState(false);
  const [hoveredRowData, setHoveredRowData] = useState(null);

  useEffect(() => {
    if (
      userInviteeList &&
      userInviteeList.length > 0 &&
      props?.filters &&
      !Object.values(props?.filters).every(
        (value) => value === "" || (Array.isArray(value) && value.length === 0)
      )
    ) {
      const filtered = userInviteeList.filter((item) => {
        // Apply filter conditions based on the filters object
        // Apply filter conditions based on the filters object
        if (
          props?.filters.inviteUserName &&
          props?.filters.inviteUserName.length > 0
        ) {
          const matchingUserNames = props?.filters.inviteUserName.some(
            (filter) => {
              return new RegExp(filter.label, "i").test(item.userName);
            }
          );
          if (!matchingUserNames) {
            return false;
          }
        }

        if (
          props?.filters.inviteUserEmail &&
          props?.filters.inviteUserEmail.length > 0
        ) {
          const matchingEmails = props?.filters.inviteUserEmail.some(
            (filter) => {
              return new RegExp(filter.label, "i").test(item.userEmail);
            }
          );
          if (!matchingEmails) {
            return false;
          }
        }

        if (
          props?.filters.inviteUserStatus &&
          item.userStatus?.toLowerCase() !==
            props?.filters.inviteUserStatus?.toLowerCase()
        ) {
          return false;
        }

        if (
          props?.filters.inviteJoinedDate &&
          !item.joinedDate.includes(props?.filters.inviteJoinedDate)
        ) {
          return false;
        }

        if (
          props?.filters.inviteRoles &&
          props?.filters.inviteRoles.length > 0
        ) {
          const matchingRoles = props?.filters.inviteRoles.some((role) => {
            return item.roles.some(
              (itemRole) =>
                itemRole?.toLowerCase() === role.label?.toLowerCase()
            );
          });

          if (!matchingRoles) {
            return false;
          }
        }

        if (
          props?.filters.inviteDepartments &&
          props?.filters.inviteDepartments.length > 0
        ) {
          const matchingDepartments = props?.filters.inviteDepartments.some(
            (department) => {
              return item.departments.some((dep) =>
                new RegExp(department.label, "i").test(dep.moduleName)
              );
            }
          );

          if (!matchingDepartments) {
            return false;
          }
        }

        return true; // Include item if it passed all filters
      });
      setFiltersData(filtered);
    } else {
      setFiltersData(userInviteeList);
    }
  }, [props?.filters]);

  useEffect(() => {
    userInviteeList && setFiltersData(userInviteeList);
  }, [userInviteeList]);

  useEffect(() => {
    const handleMouseOut = (event) => {
      // Get the bounding box of the grid and options dropdown
      const gridRect = gridRef?.current?.getBoundingClientRect();
      const optionsDropdownRect =
        optionsDropdownRef?.current?.getBoundingClientRect();

      // Check if the mouse has left the grid area
      if (
        gridRect &&
        (event.clientX > gridRect.right ||
          event.clientY < gridRect.top ||
          event.clientY > gridRect.bottom)
      ) {
        if (!showMore) {
          setHoveredRow(null);
          setShowMore(false);
        }
        // Only clear hoveredMenu if showMenu is false or mouse is not within options dropdown

        if (
          showMenu === false ||
          (optionsDropdownRect &&
            (event.clientX < optionsDropdownRect.left ||
              event.clientX > optionsDropdownRect.right ||
              event.clientY < optionsDropdownRect.top ||
              event.clientY > optionsDropdownRect.bottom))
        ) {
          setHoveredMenu(null);
          setShowMenu(false);
        }
      }
    };

    document.addEventListener("mouseout", handleMouseOut);

    return () => {
      document.removeEventListener("mouseout", handleMouseOut);
    };
  }, [showMenu]);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleClose = () => {
    setModal(false);
  };
  // CSV Related
  const convertToCSV = (data) => {
    const csv = Papa.unparse(data);
    return csv;
  };

  const downloadCSV = () => {
    const csvData = convertToCSV(users); // Replace with your actual listing data

    const blob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = "listing.csv";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    window.URL.revokeObjectURL(url);
  };

  // Function to handle checkbox change
  const handleCheckboxChange = (id) => {
    // Check if the ID is already in the array
    const isChecked = checkedIds.includes(id);

    // Update the array based on the checkbox state
    if (isChecked) {
      // If already checked, remove the ID
      setCheckedIds(checkedIds.filter((checkedId) => checkedId !== id));
    } else {
      // If not checked, add the ID
      setCheckedIds([...checkedIds, id]);
    }
  };

  const handleSelectAllChange = () => {
    setCheckedIds((prevIds) => {
      if (selectAll) {
        return [];
      } else {
        const allUserIds = users.map((user) => user.userId);
        return Array.from(new Set([...prevIds, ...allUserIds]));
      }
    });

    setSelectAll(!selectAll);
  };

  const validateInput = (event) => {
    const value = parseInt(event.target.value);

    // Check if the value is not a number or is less than or equal to 0
    if (isNaN(value) || value <= 0) {
      event.preventDefault(); // Prevent invalid input from being set
      return;
    }

    // Update the page size state with the valid value
    setPageSize(value);
  };

  const formattedDate = (originalDate) => {
    // Convert the string to a Date object
    const date = new Date(originalDate);

    // Check if the date is valid before formatting
    if (isNaN(date)) {
      return "Invalid Date";
    }

    // Format the date to "dd MMM, yyyy"
    const formattedDate = new Intl.DateTimeFormat("en-US", {
      day: "numeric",
      month: "short",
      year: "numeric",
    }).format(date);

    return formattedDate;
  };

  const handleAddRole = (user) => {
    setSelectedUser(user);
    handleSelectModule(user?.departments[0]?.moduleId);
    handleSelectRole(
      roles.find((role) => role.roleName === user?.departments[0]?.roleName)
        ?.roleId
    );
    // Logic to handle adding a role
    setShowAddRoleModal(true);
  };

  const handleCloseAddRoleModal = () => {
    setShowAddRoleModal(false);
  };

  const handleSelectRole = (role) => {
    setSelectedRole(role);
  };
  const handleSelectModule = (module) => {
    setSelectedModule(module);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const handleOpenDeleteModal = (user) => {
    setSelectedUser(user);
    setShowDeleteModal(true);
  };

  const handleSubmit = async () => {
    try {
      setRLoad(true);
      const response = await API.post("users/update-roles-modules", {
        roleId: selectedRole,
        modulesCategoriesId: selectedModule,
        userId: selectedUser.userId,
      });
      if (response?.data?.status === "success") {
        getAllUsersAfterUpdate();
        handleCloseAddRoleModal();
        toast.success("Role updated successfully", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
      }
    } catch (error) {
    } finally {
      setRLoad(false);
    }
  };

  const getAllUsersAfterUpdate = async () => {
    try {
      const user = await API.get("users/basic-info");
      if (user) {
        setUser(user?.data?.data);
        setUserId(user?.data?.data?.userId);
      }
      dispatch(userListingPage(search));
      const roleData = await API.get("company/roles");
      if (roleData?.data?.data?.roles) {
        setRoles(roleData?.data?.data?.roles);
      }
    } catch (error) {
    } finally {
    }
  };

  useEffect(() => {
    const getAllUsers = async () => {
      setLoad(true);
      try {
        dispatch(userInviteeListingPage());
      } catch (error) {
      } finally {
        setLoad(false);
      }
    };

    getAllUsers();
  }, []);

  const defaultColDef = useMemo(() => {
    return {
      editable: true,
      enablePivot: true,
      enableValue: true,
      sortable: true,
      resizable: true,
      filter: true,
      flex: 1,
      minWidth: 100,
      suppressMenu: true,
      lockPosition: true,
    };
  }, []);

  const popupParent = useMemo(() => {
    return document.querySelector("body");
  }, []);

  const changeTheOptionPosition = async (r) => {
    const hoveredRowElement = document?.querySelector(
      `.task-detail-table-${props.index} .ag-ltr .ag-row[row-id="${r}"]`
    );
    if (hoveredRowElement) {
      const rowRect = hoveredRowElement?.getBoundingClientRect();
      setOptionsColumnPosition({
        top: 71 + r * 55,
        left: -20, // Adjust the left position as needed
      });
    }
  };

  const gridReady = (params) => {
    setGridApi(params.api);
    var columnState = JSON.parse(localStorage.getItem("myColumnState"));
    if (columnState) {
      params.columnApi.applyColumnState({
        state: columnState,
        applyOrder: true,
      });
    }
  };

  const columns = [
    {
      minWidth: 190,
      headerName: "Email",
      field: "userEmail", // Make sure task_id field is included
      sortable: false,
      editable: false,
      headerClass: "first-header-cell",
      cellRenderer: (params) => {
        return (
          <div className="email-table-wrap ps-2">
            {" "}
            <a> {params.data.userEmail} </a>{" "}
          </div>
        );
      },
    },
    {
      minWidth: 150,
      headerName: "User Role",
      field: "roles", // Make sure task_id field is included
      sortable: false,
      editable: false,
      headerClass: "first-header-cell",
      cellRenderer: (params) => {
        return (
          <>
            {params.data?.roles?.length < 2 ? (
              capitalizeFirstLetter(params.data?.roles[0])
            ) : (
              <Dropdown className="roles-drop-down">
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  View roles...
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {params.data.roles.map((res, id) => (
                    <Dropdown.Item>{capitalizeFirstLetter(res)}</Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            )}
          </>
        );
      },
    },
    {
      minWidth: 190,
      headerName: "Departments",
      field: "departments", // Make sure task_id field is included
      sortable: false,
      editable: false,

      headerClass: "first-header-cell",
      cellRenderer: (params) => {
        return (
          <div className="teams-listing-wap">
            <UserListingDepartmentList
              departments={params.data?.departments}
              colors={colors}
            />
          </div>
        );
      },
    },
    {
      minWidth: 190,
      headerName: "Status",
      field: "userStatus", // Make sure task_id field is included
      sortable: false,
      editable: false,
      headerClass: "first-header-cell",
      cellStyle: (params) => {
        if (params.data.userStatus === "Active") {
          return { backgroundColor: "#74cc5233", color: "#333333 !important" };
        } else if (params.data.userStatus === "InActive") {
          return { backgroundColor: "#fcebeb" };
        } else if (params.data.userStatus === "Active") {
          return { backgroundColor: "#74cc5233" };
        } else if (params.data.userStatus === "Pending") {
          return { backgroundColor: "#91b54d1a" };
        }
      },

      cellRenderer: (params) => {
        return (
          <div>
            <div>
              {" "}
              {params.data.userStatus === "Pending" ? (
                <div className="status-table-wrap status-table-inactive-text">
                  Invitation pending
                </div>
              ) : (
                <div className="status-table-wrap status-table-inactive-text">
                  {params.data.userStatus}
                </div>
              )}{" "}
            </div>
          </div>
        );
      },
    },
    {
      minWidth: 110,
      headerName: "Invitation Date",
      field: "updatedAt", // Make sure task_id field is included
      sortable: false,
      editable: false,
      headerClass: "first-header-cell",
      cellRenderer: (params) => {
        return <div>{formattedDate(params.data?.updatedAt)}</div>;
      },
    },
    {
      minWidth: 110,
      headerName: "Invited By",
      field: "invitedBy", // Make sure task_id field is included
      sortable: false,
      editable: false,
      headerClass: "first-header-cell",
      cellRenderer: (params) => {
        return <div>{params.data.invitedBy}</div>;
      },
    },
  ];

  const renderCustomOptionsColumn = () => {
    if (hoveredMenu !== null) {
      const dropdownHeight = 70; // Adjust this value based on your dropdown height
      const availableSpaceBelow =
        window.innerHeight - (yValue + dropdownHeight);

      const dropdownPosition =
        availableSpaceBelow < 0
          ? {
              top: `${yValue - dropdownHeight - 26}px`,
              left: `${xValue - 20}px`,
            }
          : { top: `${yValue}px`, left: `${xValue - 20}px` };

      return (
        <div
          style={{
            position: "absolute",
            top: optionsColumnPosition.top,
            left: optionsColumnPosition.left,
            zIndex: 9999,
            height: "40px",
            paddingRight: "7px", // Ensure it's on top of the grid
          }}
          // onMouseEnter={(e) => setHoveredRow(true)}
        >
          <div className="position-relative mt-2">
            <DropButton
              showMore={showMenu}
              setShowMore={setShowMenu}
              setXValue={setXValue}
              setYValue={setYValue}
              xValue={xValue}
              yValue={yValue}
              id={0}
            />
            {showMenu && (
              <Portal>
                <div
                  data-theme={props.isDark}
                  ref={optionsDropdownRef}
                  style={{
                    position: "absolute",
                    left: dropdownPosition.left,
                    top: dropdownPosition.top,
                    listStyle: "none",
                    background: "#fff", // Set background color
                    // border: "1px solid #ccc",
                    fontSize: "14px",
                    fontWeight: 300,
                    minWidth: "150px",
                  }}
                  onMouseEnter={(e) => setShowMenu(true)}
                  onMouseLeave={() => setShowMore(false)}
                  className="dropdown-list user-permission-modules-dropdown px-1 d-flex flex-column align-items-start gap-2 justify-content-start bg-white rounded-2 border-1 border"
                >
                  {hoveredRowData?.userStatus === "Pending" ? (
                    <div>
                      <div
                        className="d-flex align-items-center m-2"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setEmail(hoveredRowData.userEmail);
                          setId(hoveredRowData?.userId);
                          setModal(true);
                        }}
                      >
                        <img
                          src={InviteIcon}
                          style={{
                            width: "15px",
                            height: "15px",
                            marginRight: "5px",
                          }}
                        />
                        Re-invite
                      </div>
                      {userId !== hoveredRowData?.userId &&
                        hoveredRowData?.roleName?.toLowerCase() !== "admin" && (
                          <>
                            <div
                              className="d-flex align-items-center m-2"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setId(hoveredRowData?.userId);
                                handleOpenDeleteModal(hoveredRowData);
                              }}
                            >
                              <img
                                style={{
                                  width: "15px",
                                  height: "15px",
                                  marginRight: "5px",
                                }}
                                src={Delete}
                              />
                              Delete
                            </div>
                          </>
                        )}
                    </div>
                  ) : (
                    <div>
                      <div
                        className="d-flex align-items-center m-2"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          navigate(
                            `/${localStorage.getItem(
                              "company"
                            )}/user-profile?id=${hoveredRowData.userId}`
                          );
                        }}
                      >
                        <img
                          style={{
                            width: "15px",
                            height: "15px",
                            marginRight: "5px",
                          }}
                          src={Eyes}
                        />
                        View
                      </div>
                      {userId !== hoveredRowData?.userId &&
                        hoveredRowData?.roleName?.toLowerCase() !== "admin" && (
                          <>
                            <div
                              className="d-flex align-items-center m-2"
                              style={{ cursor: "pointer" }}
                              onClick={() => handleAddRole(hoveredRowData)}
                            >
                              <img
                                style={{
                                  width: "15px",
                                  height: "15px",
                                  marginRight: "5px",
                                }}
                                src={Edit}
                              />
                              Edit role
                            </div>
                            <div
                              className="d-flex align-items-center m-2"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setId(hoveredRowData?.userId);
                                handleOpenDeleteModal(hoveredRowData);
                              }}
                            >
                              <img
                                style={{
                                  width: "15px",
                                  height: "15px",
                                  marginRight: "5px",
                                }}
                                src={Delete}
                              />
                              Delete
                            </div>
                          </>
                        )}
                    </div>
                  )}
                </div>
              </Portal>
            )}
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    userInviteeList?.length > 0 && (
      <div>
        <div className="team-list-header-main">
          <div className="row">
            <div className="col-md-6 leads-new-header-cols">
              <div className=" ms-4 team-invitation-heading">
                <h3>
                  {" "}
                  Team <span>Invitations</span>{" "}
                </h3>
              </div>
            </div>
          </div>
        </div>
        <div className="ms-4 col-md-12 mt-3">
          <p className="role-page-description">
            {teamInvitationsModalContent?.short_description}{" "}
            <span className="see-more-txt" onClick={() => setSeeMore(true)}>
              See More
            </span>
          </p>
        </div>

        <div className="position-relative">
          {gridApi && renderCustomOptionsColumn()}
        </div>
        <div className="mt-3 table-responsive ms-4 scroll-smooth custom-horizental-scrollbar">
          <div
            className={`ag-theme-alpine invite-ag-table task-detail-table-${props.index}`}
            ref={gridRef}
            style={{
              width: "100%",
              minWidth: "1470px",
            }}
          >
            <AgGridReact
              rowData={filteredData}
              defaultColDef={defaultColDef}
              domLayout={"autoHeight"}
              columnDefs={columns}
              singleClickEdit={true}
              rowHeight={55}
              animateRows={true}
              rowDragManaged={true}
              rowDragEntireRow={true}
              // popupParent={popupParent}
              stopEditingWhenCellsLoseFocus={true}
              suppressRowHoverHighlight={true}
              overlayLoadingTemplate={
                '<object style="position:absolute;top:50%;left:50%;transform:translate(-50%, -50%) scale(2)" type="image/svg+xml" data="https://ag-grid.com/images/ag-grid-loading-spinner.svg" aria-label="loading"></object>'
              }
              overlayNoRowsTemplate={
                "<span style=\"padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow\">This is a custom 'no rows' overlay</span>"
              }
              onGridReady={gridReady}
              onCellMouseOver={(params) => {
                if (params?.data?.userId !== hoveredRow) {
                  setShowMore(false);
                  setHoveredRow(params?.data?.userId);
                  // changeTheOptionPosition(params?.data?.userId);
                }
                if (params?.rowIndex !== hoveredMenu) {
                  setShowMenu(false);
                  setHoveredMenu(params?.rowIndex);
                  changeTheOptionPosition(params?.rowIndex);
                  setHoveredRowData(params.data);
                }
              }}
            />
          </div>
        </div>
        {users?.length > 20 && (
          <div className="record-pagination-wraper">
            <div className="row">
              <div className="col-md-6 lead-pagi-col">
                <div className="record-wrap">
                  Records Per Page{" "}
                  <input
                    // type="number"
                    disabled
                    value={pageSize}
                    onChange={validateInput}
                  />
                </div>
              </div>

              <div className="col-md-6 lead-pgi-right lead-pagi-col">
                {/* <div className="pagination-main-wrap"> */}
                <CustomPagination
                  totalRows={totalRows}
                  pageSize={pageSize}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  onPageChange={(page) => setCurrentPage(page)}
                />
              </div>
            </div>
          </div>
        )}
        {/* Add Role Modal */}
        <Modal
          show={showAddRoleModal}
          onHide={handleCloseAddRoleModal}
          className="main-update-modal"
          data-theme={props.isDark}
          centered
        >
          <Modal.Header
            className="update-modal-header"
            closeButton
          ></Modal.Header>
          <Modal.Body className="update-modal-body">
            <div className="w-100 modal-module-wrapper">
              <h6>Select Modules</h6>
              <div className="w-100 modules-opt-wrapper">
                {selectedUser &&
                  selectedUser?.departments?.map((res, id) => (
                    <div
                      key={id}
                      className={`${
                        selectedModule === res?.moduleId
                          ? "role-selected-modal"
                          : ""
                      }`}
                      onClick={() => {
                        handleSelectModule(res?.moduleId);
                        handleSelectRole(
                          roles.find((role) => role.roleName === res.roleName)
                            ?.roleId
                        );
                      }}
                    >
                      {res?.abbreviatedName}
                    </div>
                  ))}
              </div>
            </div>
            <div className="w-100 modal-role-wrapper">
              <h6>Assign Role</h6>
              <div className="w-100 roles-opt-wrapper">
                {roles?.map((rol, id) => (
                  <div
                    className={`${
                      selectedRole === rol?.roleId ? "role-selected-modal" : ""
                    }`}
                    key={id}
                    onClick={() => {
                      handleSelectRole(rol?.roleId);
                    }}
                  >
                    {rol.roleName}
                  </div>
                ))}
              </div>
            </div>
            <div className="w-100 modal-btn-wrapper">
              <button onClick={handleSubmit}>
                {rload ? (
                  <Spinner className="spinner-role" border="animation" />
                ) : (
                  "Update Role"
                )}
              </button>
            </div>
          </Modal.Body>
        </Modal>
        <DeleteUserModal
          showDeleteModal={showDeleteModal}
          handleCloseDeleteModal={handleCloseDeleteModal}
          user={selectedUser}
          isDark={props.isDark}
          userId={id}
          getAllUsersAfterUpdate={getAllUsersAfterUpdate}
        />
        <InviteModal
          isDark={props?.isDark}
          showModal={showModal}
          handleCloseModal={handleCloseModal}
          isAdmin={
            user?.canInvite === 1 &&
            user?.roles?.some((role) => role.roleType === "admin")
              ? true
              : false
          }
        />
        <ReinviteModal
          modal={modal}
          handleClose={handleClose}
          isDark={props?.isDark}
          email={email}
          userId={id}
          getAllUsersAfterUpdate={getAllUsersAfterUpdate}
        />
        <SeeMoreModal
          seeMore={seeMore}
          setSeeMore={setSeeMore}
          headTextOne="Team"
          headTextTwo=" Invitations"
          isDark={props.isDark}
          modalContent={teamInvitationsModalContent}
        />
      </div>
    )
  );
};

export const UserTooltip = ({ userName, roleName, position }) => {
  return (
    <div className="custom-tooltip">
      <p className="custom-tooltip-rolename mb-3">{userName}</p>
      <p className="custom-tooltip-rolename">{roleName}</p>
    </div>
  );
};

const UserListingDepartmentList = ({ departments, colors }) => {
  const [showMore, setShowMore] = useState(false);
  const [data, setData] = useState([]);
  const itemsToShow = 4; // Number of items to initially show
  const [showTooltip, setShowTooltip] = React.useState(false);

  const toggleShowAll = () => {
    setShowMore(!showMore);
  };

  useMemo(() => {
    if (departments) {
      setData(departments);
    }
  }, [departments]);

  const firstFourItems = data.length > 4 ? data.slice(0, 4) : data;

  const expandCollapseButton = (
    <button
      onClick={toggleShowAll}
      className="border-0 rounded-circle p-0 m-0 user-profile-modules-categories-expand"
    >
      {showMore ? `-${data.length - 4}` : `+${data.length - 4}`}
    </button>
  );

  const UserImageCircle = ({ colors, item, id }) => {
    const [showTooltip, setShowTooltip] = useState(false);
    const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
    const uniqueId = uuidv4();
    return (
      <React.Fragment key={id}>
        <li
          onMouseEnter={(event) => {
            setShowTooltip(true);
            const rect = event.target.getBoundingClientRect();
            setTooltipPosition({ x: rect.x, y: rect.y });
          }}
          onMouseLeave={() => setShowTooltip(false)}
          style={{
            backgroundColor: colors[id % colors.length],
          }}
          className="position-relative"
          data-tooltip-id={`my-tooltip-${uniqueId}`}
        >
          {item.abbreviatedName}
          {showTooltip && (
            <UserTooltip
              userName={item.abbreviatedName}
              roleName={
                item.roleName.charAt(0).toUpperCase() + item.roleName.slice(1)
              }
              position={{ x: tooltipPosition.x, y: tooltipPosition.y }}
            />
          )}
        </li>
      </React.Fragment>
    );
  };

  return (
    <ul>
      {showMore
        ? data.map((item, id) => (
            <UserImageCircle item={item} id={id} colors={colors} />
          ))
        : firstFourItems.map((item, id) => (
            <UserImageCircle item={item} id={id} colors={colors} />
          ))}
      {data.length > 4 && (
        <li style={{ zIndex: 10 }}>{expandCollapseButton}</li>
      )}
    </ul>
  );
};

export default TeamInvitations;

/* eslint-disable */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { toast } from "react-toastify";
import { useDeleteCompanyWorkingHoursMutation } from "../../../slices/user/userSlice";

function useDeleteScheduleModal(handleCloseModal) {
  const [deleteCompanyWorkingHours, { isLoading: isDeleting }] =
    useDeleteCompanyWorkingHoursMutation();

  const handleDeleteSchedule = async (id) => {
    try {
      // Perform delete mutation
      const response = await deleteCompanyWorkingHours(id).unwrap();

      // Handle success response
      if (response?.status === "success") {
        toast.success(response?.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 300,
          hideProgressBar: true,
          closeButton: false,
        });
        handleCloseModal();
      } else {
        toast.error(response?.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 300,
        });
      }
    } catch (error) {
      // Handle error response
      toast.error(error.message || "Failed to delete the schedule", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 300,
      });
    } finally {
    }
  };

  return {
    isDeleting,
    handleDeleteSchedule,
  };
}

export default useDeleteScheduleModal;

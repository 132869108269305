/* eslint-disable */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Modal, Spinner } from "react-bootstrap";
import { Icon } from "@iconify/react/dist/iconify.js";

import "./style.css";
import "cropperjs/dist/cropper.css";
import EditWhite from "../../../assets/edit-white.svg";
import userProfileBlank from "../../../assets/blank_profile.svg";
import useEditCompanyInfoModal from "../services/useEditCompanyInfoModal";

const EditCompanyInfoModal = ({
  show,
  isDark,
  handleCloseModal,
  companyProfile,
}) => {
  // data is managing in the business layer in services folder
  const {
    formik,
    showCropper,
    setShowCropper,
    selectedImage,
    imageRef,
    handleFileChange,
    handleCrop,
    isUpdateCompanyProfileLoading,
    industryList,
    setSelectedImage,
  } = useEditCompanyInfoModal({
    handleCloseModal: handleCloseModal,
    companyProfile: companyProfile,
  });

  return (
    <Modal
      show={show}
      data-theme={isDark}
      centered
      dialogClassName="create-task-modal-main"
    >
      {/* Custom Header */}
      <div className="create-task-modal-header">
        <p className="mb-0">
          Edit <span>Company’s Info</span>
        </p>
        <button className="custom-close-button" onClick={handleCloseModal}>
          <Icon icon="maki:cross" />
        </button>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <Modal.Body>
          {/* Logo Selector */}
          <div className="company-profile-edit-btn-profile d-flex align-items-center justify-content-center">
            <div className="position-relative w-auto">
              <a
                disabled={isUpdateCompanyProfileLoading}
                onClick={() =>
                  document.getElementById("profileImageModal").click()
                }
              >
                <img src={EditWhite} alt="edit-icon" />
              </a>
              <img
                className="company-profile-dash-image"
                src={
                  selectedImage
                    ? selectedImage
                    : formik.values.logo ||
                      companyProfile?.companyLogo ||
                      userProfileBlank
                }
                alt="User Profile"
                onClick={() =>
                  document.getElementById("profileImageModal").click()
                }
                onError={(e) => {
                  e.target.src = userProfileBlank;
                }}
              />
            </div>
          </div>
          <input
            type="file"
            id="profileImageModal"
            style={{ display: "none" }}
            accept=".jpg, .jpeg, .png"
            disabled={isUpdateCompanyProfileLoading}
            onChange={handleFileChange}
          />

          {/* Form Section */}
          <div className="company-edit-modal-form mt-4">
            {/* Row 1: Company Name and Phone Number */}
            <div className="company-edit-modal-row">
              <div className="company-edit-modal-column">
                <label>Company Name</label>
                <input
                  type="text"
                  name="companyName"
                  className={`company-edit-modal-input form-control ${
                    formik.touched.companyName && formik.errors.companyName
                      ? "is-invalid"
                      : ""
                  }`}
                  placeholder="Enter company name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.companyName}
                />
                {formik.touched.companyName && formik.errors.companyName && (
                  <div className="invalid-feedback">
                    {formik.errors.companyName}
                  </div>
                )}
              </div>
              <div className="company-edit-modal-column">
                <label>Phone Number</label>
                <input
                  type="text"
                  name="phoneNumber"
                  className={`company-edit-modal-input form-control ${
                    formik.touched.phoneNumber && formik.errors.phoneNumber
                      ? "is-invalid"
                      : ""
                  }`}
                  placeholder="Enter phone number"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.phoneNumber}
                />
                {formik.touched.phoneNumber && formik.errors.phoneNumber && (
                  <div className="invalid-feedback">
                    {formik.errors.phoneNumber}
                  </div>
                )}
              </div>
            </div>

            {/* Row 2: Email Address and Industry */}
            <div className="company-edit-modal-row">
              <div className="company-edit-modal-column">
                <label>Email Address</label>
                <input
                  type="email"
                  name="email"
                  className={`company-edit-modal-input form-control ${
                    formik.touched.email && formik.errors.email
                      ? "is-invalid"
                      : ""
                  }`}
                  placeholder="Enter email address"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                />
                {formik.touched.email && formik.errors.email && (
                  <div className="invalid-feedback">{formik.errors.email}</div>
                )}
              </div>
              <div className="company-edit-modal-column">
                <label>Industry</label>
                <select
                  name="industry"
                  className={`company-edit-modal-input company-edit-modal-select form-control ${
                    formik.touched.industry && formik.errors.industry
                      ? "is-invalid"
                      : ""
                  }`}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.industry}
                >
                  <option value="" disabled>
                    Select Industry
                  </option>
                  {industryList?.data?.industries.map((res, id) => (
                    <option value={res?.id}>{res?.industryName}</option>
                  ))}
                </select>
                {formik.touched.industry && formik.errors.industry && (
                  <div className="invalid-feedback">
                    {formik.errors.industry}
                  </div>
                )}
              </div>
            </div>

            {/* Row 3: About */}
            <div className="company-edit-modal-row">
              <div className="company-edit-modal-full">
                <label>About company</label>
                <textarea
                  name="about"
                  className={`company-edit-modal-input-area form-control ${
                    formik.touched.about && formik.errors.about
                      ? "is-invalid"
                      : ""
                  }`}
                  rows="4"
                  placeholder="Write about the company"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.about}
                />
                {formik.touched.about && formik.errors.about && (
                  <div className="invalid-feedback">{formik.errors.about}</div>
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="edit-profile-modal-footer">
          <button
            className="edit-profile-modal-footer-button-2"
            onClick={handleCloseModal}
            disabled={isUpdateCompanyProfileLoading}
          >
            Cancel
          </button>
          <button
            className="edit-profile-modal-footer-button-1"
            onClick={formik.handleSubmit}
            disabled={isUpdateCompanyProfileLoading}
          >
            Update Profile{" "}
            {isUpdateCompanyProfileLoading && (
              <Spinner className="ms-2" size="sm" />
            )}
          </button>
        </Modal.Footer>
        {/* Cropper Modal */}
        <Modal
          show={showCropper}
          onHide={() => {
            setShowCropper(false);
            setSelectedImage(null);
          }}
          centered
          size="lg"
          data-theme={isDark}
        >
          <Modal.Header closeButton>
            <h3>
              <span>Crop</span> Image
            </h3>
          </Modal.Header>
          <Modal.Body style={{ height: "400px" }}>
            <div className="w-100 h-100">
              {selectedImage && (
                <img
                  ref={imageRef}
                  alt="Crop"
                  className="w-100 h-100 object-fit-contain"
                />
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="crop-btn"
              onClick={handleCrop}
              // disabled={isUploading}
            >
              Crop
            </button>
          </Modal.Footer>
        </Modal>
      </form>
    </Modal>
  );
};

export default EditCompanyInfoModal;

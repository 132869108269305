import React, { useEffect } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { calculateDiscountedPrice } from "../../../helper/getDiscountedPrice";
import Icon1 from "../../../assets/sub-modal-icon-1.svg";
import Icon2 from "../../../assets/sub-modal-icon-2.svg";
import Icon3 from "../../../assets/sub-modal-icon-3.svg";
import Icon4 from "../../../assets/sub-modal-icon-4.svg";
import Icon5 from "../../../assets/sub-modal-icon-5.svg";
import SubLogo from "../../../assets/task-sub-logo.svg";
import SubUser from "../../../assets/sub-select-user-icon.svg";
import DownArrow from "../../../assets/arrow-down.svg";
import BackIcon from "../../../assets/back.svg";
import { dateFormatter } from "../../../helper/dateFormatter";
import SelectUserModal from "../../../components/SelectUserModal/SelectUserModal";
import PackageCard from "./PackageCard";
import { IoIosArrowDown } from "react-icons/io";
import Select, { components } from "react-select";

const OverviewSubscriptionModal = ({
  showPackageModal,
  handleClosePackageModal,
  summery,
  userModal,
  isDark,
  selectedUser,
  setUserModal,
  setUserPro,
  setSummery,
  userpro,
  applicationName,
  setSeat,
  seat,
  seats,
  minSeats,
  selectedPlan,
  packageData,
  fullPackage,
  packageName,
  selectedPackage,
  setPackageName,
  setPackageDataOverview,
  packageDataOverview,
  handleReSubscribe,
  openModal,
  load,
  getPrice,
  inter,
  billingDetail,
  setSelectedPackage,
  setSelectedName,
  setMinSeats,
  setOpenContact,
  userCount,
  selectedPackageDisplayOrder,
  setNewDisplayOrder,
  setFullPackage,
  show,
  handleShowTab,
  setSelectedPlan,
  setInter,
  loadbtn,
  selectedName,
  sumLoad,
  setSelectedUser,
  selectedUserIds,
  setSelectedUserIds,
  removedUserIds,
  setRemovedUserIds,
  setUsers,
  limit,
  setLimit,
  users,
  planData,
  amt,
  setAmt,
}) => {
  const currentDate = new Date();
  const nextYearDate = new Date(currentDate);
  nextYearDate.setFullYear(currentDate.getFullYear() + 1);

  const nextMonthDate = new Date(currentDate);
  nextMonthDate.setMonth(currentDate.getMonth() + 1);

  // Generate options for the dropdown
  const options = Array.from({ length: 98 }, (_, i) => i + 2).map((num) => ({
    value: num,
    label: `${num < 10 ? `0${num}` : num} ${num === 1 ? "user" : "users"}`,
  }));

  // Convert userCount (number) to the format React Select expects
  const getDefaultValue = () => {
    return {
      value: seat,
      label: `${seat < 10 ? `0${seat}` : seat} ${
        seat === 1 ? "user" : "users"
      }`,
    };
  };
  console.log("Get Val : ", getDefaultValue());
  // Handle selection change
  const handleChange = (selectedOption) => {
    // Extract the value (number) from the selected option
    setSeat(selectedOption.value);
  };

  // Seat select
  const customStyles = {
    control: (provided) => ({
      ...provided,
      border: "1px solid #394B84", // Set border color
      background: "#FFF", // Set background color
      borderRadius: "6px", // Set border radius
      width: "100% !important", // Ensure full width
      height: "40px", // Adjust height
      marginBottom: "18px",
      boxShadow: "none",
      "&:hover": {
        borderColor: "#394B84",
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      fontSize: "14px",
      fontWeight: 300,
      color: "#394B84",
    }),
    input: (provided) => ({
      ...provided,
      fontSize: "14px",
      fontWeight: 300,
    }),
    placeholder: (provided) => ({
      ...provided,
      fontSize: "14px",
      fontWeight: 300,
    }),
    menuPortal: (base) => ({
      ...base,
      zIndex: 9999,
    }),
    option: (provided, state) => ({
      ...provided,
      fontSize: "14px",
      fontWeight: 300,
      textAlign: "left", // Ensure text alignment starts at the beginning
      backgroundColor: state.isSelected
        ? "#007bff"
        : state.isFocused
        ? "#f0f0f0"
        : "white",
      color: state.isSelected ? "white" : "black",
      "&:hover": {
        backgroundColor: "#f0f0f0",
      },
    }),
  };

  const CustomSeatEditorControl = ({ children, menuIsOpen, ...props }) => {
    return (
      <components.Control {...props}>
        {props.hasValue ? (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              className="d-flex align-items-center"
              style={{ color: "blue !important" }}
            >
              <img src={SubUser} className="ms-1" alt="sub-user-icon" />{" "}
              {children}
            </div>
          </div>
        ) : (
          children
        )}
        <div
          className="select-icon me-1"
          style={{
            transform: menuIsOpen ? "rotate(180deg)" : "rotate(0deg)",
            transition: "transform 0.3s ease",
          }}
        >
          <IoIosArrowDown />
        </div>
      </components.Control>
    );
  };

  // useEffect(() => {
  //   if (packageDataOverview) {
  //     setSeat(packageDataOverview?.seatCount || 3);
  //     setInter(packageDataOverview?.interval === "month" ? "Month" : "Year");
  //   }
  // }, [packageDataOverview]);

  console.log(packageDataOverview);
  return (
    <Modal
      show={showPackageModal}
      onHide={handleClosePackageModal}
      className={`package-modal ${
        summery ? "update-package-summery-modal add-sum-modal" : ""
      } ${userModal ? "user-select-modal" : ""}`}
      data-theme={isDark}
      animation={false}
      centered
    >
      {/* Your Modal content here */}
      <Modal.Header closeButton className="update-modal-header">
        {!summery && !userModal ? (
          <div className="modal-heading-wrap">
            {/* <h3>
              {" "}
              <span>Select A Plan</span> For Your Team{" "}
            </h3> */}
          </div>
        ) : (
          <div className="d-flex align-items-center add-on-popup-wraper">
            {summery && (
              <>
                {planData?.currentStatus !== "Trial" && (
                  <div
                    className="go-back"
                    onClick={() => {
                      if (selectedUser?.length > 0) {
                        setUserModal(true);
                        setUserPro(true);
                        setSummery(false);
                      } else {
                        setSummery(false);
                      }
                    }}
                  >
                    <img src={BackIcon} alt="back-icon" />
                  </div>
                )}
                <h2>
                  {" "}
                  Plan <span>Summary</span>{" "}
                </h2>
              </>
            )}
            {userModal && (
              <>
                <div
                  className="go-back"
                  onClick={() => {
                    if (userpro === true) {
                      setUserPro(false);
                    } else {
                      setUserModal(false);
                    }
                  }}
                >
                  <img src={BackIcon} alt="back-icon" />
                </div>
                {userpro ? (
                  <h2>
                    {" "}
                    Selected <span>Team</span>{" "}
                  </h2>
                ) : (
                  <h2>
                    {" "}
                    Downgrade <span>Package</span>{" "}
                  </h2>
                )}
              </>
            )}
          </div>
        )}
      </Modal.Header>
      <Modal.Body className="update-modal-body">
        {/* Your Modal body content here */}
        {/* For example, the content you provided */}
        {summery && <hr style={{ marginTop: "5px" }} />}
        {!summery && !userModal ? (
          <div className="iffra-subscription-body text-center p-4">
            <div className="iffra-subscription-header mb-3">
              <img
                src={SubLogo}
                alt="Task Pro"
                className="iffra-subscription-logo mb-2"
                width={230}
              />
            </div>

            <Select
              // className="users_dropdown"
              value={getDefaultValue()} // Convert userCount to the expected format
              onChange={handleChange}
              options={options}
              placeholder="Select number of users"
              isSearchable={true} // Enable search functionality
              menuPlacement="auto" // Adjust dropdown placement
              styles={customStyles}
              components={{ Control: CustomSeatEditorControl }}
            />
            <div className="iffra-subscription-pricing d-flex justify-content-center gap-2 py-2 flex-wrap">
              <div
                className={`iffra-subscription-plan ${
                  selectedPlan === 1 ? "selected" : ""
                }`}
                onClick={() => {
                  setSelectedPlan(1);
                  setInter("Month");
                }}
              >
                <p>Monthly</p>
                <p className="text-sub-interval-price">
                  $
                  {parseFloat(
                    (packageData[0]?.packagePrice * seat).toFixed(2)
                  ).toFixed(2)}
                </p>
                <p>Billed monthly</p>
              </div>
              <div
                className={`iffra-subscription-plan ${
                  selectedPlan === 2 ? "selected" : ""
                }`}
                onClick={() => {
                  setSelectedPlan(2);
                  setInter("Year");
                }}
              >
                <p>Yearly</p>
                <div className="w-100 d-flex align-items-center justify-content-between gap-2">
                  <p className="text-sub-interval-price">
                    $
                    {parseFloat(
                      (
                        calculateDiscountedPrice(
                          packageData[0]?.packagePrice,
                          packageData[0]?.yearlyDiscountedPercentage
                        ) * seat
                      ).toFixed(2)
                    ).toFixed(2)}
                  </p>
                  <span className="text-discount-sub">Save 20%</span>
                </div>

                <p>Billed yearly</p>
              </div>
            </div>

            <ul className="iffra-subscription-features list-unstyled mx-auto mb-3">
              <li>
                <img
                  src={Icon1}
                  alt="Task Management"
                  className="feature-icon"
                />
                Task Management
              </li>
              <li>
                <img
                  src={Icon2}
                  alt="Project Management"
                  className="feature-icon"
                />
                Project Management
              </li>
              <li>
                <img
                  src={Icon3}
                  alt="Performance Matrix"
                  className="feature-icon"
                />
                Performance Matrix
              </li>
              <li>
                <img src={Icon4} alt="More Features" className="feature-icon" />
                More Features
              </li>
              <li>
                <img
                  src={Icon5}
                  alt="Mobile App Inclusive"
                  className="feature-icon"
                />
                Mobile App Inclusive
              </li>
            </ul>

            <button
              // variant="primary"
              className="iffra-subscription-btn w-100"
              onClick={async () => {
                setFullPackage(packageData[0]);
                const newSeat =
                  parseInt(seat) < parseInt(packageData[0]?.minSeats)
                    ? parseInt(packageData[0]?.minSeats)
                    : parseInt(seat);
                setFullPackage(packageData[0]);
                setAmt(
                  inter === "Month"
                    ? parseFloat(
                        (packageData[0]?.packagePrice * newSeat).toFixed(2)
                      ).toFixed(2)
                    : parseFloat(
                        (
                          calculateDiscountedPrice(
                            packageData[0]?.packagePrice,
                            packageData[0]?.yearlyDiscountedPercentage
                          ) * newSeat
                        ).toFixed(2)
                      ).toFixed(2)
                );
                setPackageDataOverview({
                  ...packageDataOverview,
                  packageId: packageData[0]?.id,
                  stripeProductId: packageData[0]?.stripeProductId,
                  totalAmount:
                    inter === "Month"
                      ? parseFloat(
                          (packageData[0]?.packagePrice * newSeat).toFixed(2)
                        ).toFixed(2)
                      : parseFloat(
                          (
                            calculateDiscountedPrice(
                              packageData[0]?.packagePrice,
                              packageData[0]?.yearlyDiscountedPercentage
                            ) * newSeat
                          ).toFixed(2)
                        ).toFixed(2),

                  interval: inter.toLowerCase(),
                  seatCount:
                    parseInt(seat) < parseInt(packageData[0]?.minSeats)
                      ? parseInt(packageData[0]?.minSeats)
                      : parseInt(seat),
                });
                setSelectedName(packageData[0]?.packageName);
                // props.setName(packageData[0]?.packageName);
                setNewDisplayOrder(packageData[0]?.displayOrder);

                if (
                  userCount >
                  (parseInt(seat) < parseInt(packageData[0]?.minSeats)
                    ? parseInt(packageData[0]?.minSeats)
                    : parseInt(seat))
                ) {
                  setUserModal(true);
                } else {
                  setSummery(true);
                }
              }}
            >
              Subscribe Now!
            </button>
          </div>
        ) : (
          <>
            {summery && (
              <div className="add-on-popup-wraper">
                <div className="w-100 d-flex justify-content-between align-items-center summery-wrapper">
                  <div className="w-100 package-info-data">
                    <div className="w-100 d-flex justify-content-between plan-wrap">
                      <p>Package</p>
                      <p>{selectedName}</p>
                    </div>
                    <div className="w-100 d-flex justify-content-between plan-wrap">
                      <p>Seats</p>
                      <p>{seat}</p>
                    </div>
                    <div className="w-100 d-flex justify-content-between plan-wrap">
                      <p>Duration</p>
                      <p>{packageDataOverview.interval}</p>
                    </div>
                    <div className="w-100 d-flex justify-content-between plan-wrap">
                      <p>Activation Date</p>
                      <p>{dateFormatter(new Date())}</p>
                    </div>
                    <div className="w-100 d-flex justify-content-between plan-wrap">
                      <p>Expiary Date</p>
                      <p>
                        {dateFormatter(
                          packageDataOverview?.interval === "year"
                            ? nextYearDate
                            : nextMonthDate
                        )}
                      </p>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="w-100 d-flex justify-content-between align-items-center package-info-data-main">
                  <div className="package-info-data">
                    <p>Total</p>
                  </div>
                  <p>
                    {packageDataOverview.interval === "year" ? (
                      <span
                        style={{ fontSize: "15px" }}
                      >{`(12 months X $${Math.round(amt / 12)})`}</span>
                    ) : (
                      ""
                    )}{" "}
                    ${amt}
                  </p>
                </div>
                <div
                  className={`${
                    planData?.currentStatus === "Trial"
                      ? "case-trial-btn-wrap"
                      : ""
                  } choose-continue-btn choose-continue-btn-margin`}
                >
                  {planData?.currentStatus === "Trial" && (
                    <div className="back-button-wraper module-btn-wrapper">
                      <button onClick={() => setSummery(false)}>
                        Choose Plan
                      </button>
                    </div>
                  )}
                  <div className="next-button-wraper module-btn-wrapper">
                    <button
                      onClick={async () => {
                        if (billingDetail?.length > 0) {
                          await handleReSubscribe(
                            packageDataOverview?.packageId,
                            packageDataOverview?.stripeProductId,
                            packageDataOverview?.totalAmount,
                            packageDataOverview?.interval,
                            seat,
                            selectedUser
                          );
                          handleClosePackageModal();
                        } else {
                          handleClosePackageModal();
                          openModal();
                        }
                      }}
                      disabled={sumLoad}
                      className="module-modal-btn module-main-btn remove-package-modal-btn"
                    >
                      {/* {loadbtn ? <Spinner animation="border" /> : "Continue"}{" "} */}
                      {sumLoad ? (
                        <Spinner animation="border" />
                      ) : billingDetail?.length > 0 ? (
                        "Buy Now"
                      ) : (
                        "Continue"
                      )}
                    </button>
                  </div>
                </div>
              </div>
            )}
            {userModal && (
              <SelectUserModal
                seat={seat}
                userpro={userpro}
                setUserPro={setUserPro}
                setSummery={setSummery}
                setUserModal={setUserModal}
                setSelectedUser={setSelectedUser}
                selectedUserIds={selectedUserIds}
                setSelectedUserIds={setSelectedUserIds}
                removedUserIds={removedUserIds}
                setRemovedUserIds={setRemovedUserIds}
                setUsers={setUsers}
                limit={limit}
                setLimit={setLimit}
                users={users}
              />
            )}
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default OverviewSubscriptionModal;

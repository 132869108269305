/* eslint-disable */
/* eslint-disable no-unused-vars */
import { Modal, Spinner } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useState } from "react";
import NoInternetIcon from "../../assets/day-end-modal-icon.png";
import DeleteIcon from "../../assets/cross.png";

import "../DayTimeStartModal/style.css";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { usePostUpdateDailyLogsMutation } from "../../slices/user/userSlice";
import { userProfilePage } from "../../actions/userActions";
import { useDispatch } from "react-redux";
import { ProjectAPI } from "../../services/ClientApi";

function DayTimeEndModal({ isDark }) {
  const dispatch = useDispatch();
  const [isShow, setIsShow] = useState(false);
  const [updateDailyLogs, { isLoading }] = usePostUpdateDailyLogsMutation();
  const { userProfile, loading } = useSelector((state) => state?.profile);

  const checkConditionAndShowModal = () => {
    const currentDate = new Date();
    const currentHour = currentDate.getHours();
    const currentDay = currentDate.toISOString().split("T")[0]; // Get the current date in YYYY-MM-DD format
    const currentDayName = currentDate.toLocaleString("en-US", {
      weekday: "long",
    }); // Get the current day as a string

    // Your condition to check if the modal should be shown
    const lastShownDate =
      userProfile?.dayEndTime?.length > 0
        ? userProfile?.dayEndTime?.split("T")[0]
        : null;
    const hasWoundUp = localStorage.getItem("hasWoundUpDayEnd");

    console.log("dayendmodal", [
      currentHour >= 15 &&
        currentHour <= 19 &&
        lastShownDate !== currentDay &&
        userProfile &&
        userProfile?.dayStartTime?.length > 0 &&
        !userProfile?.dayEndTime?.length > 0,
    ]);
    if (
      userProfile?.shiftStartTime &&
      userProfile?.shiftEndTime &&
      userProfile?.workingDays
    ) {
      const workingDaysArray = userProfile?.workingDays?.split(",") || [];
      if (
        currentHour >= parseInt(userProfile?.shiftEndTime?.split(":")[0]) - 1 &&
        currentHour <= parseInt(userProfile?.shiftEndTime?.split(":")[0]) &&
        lastShownDate !== currentDay &&
        userProfile &&
        userProfile?.dayStartTime?.length > 0 &&
        workingDaysArray.includes(currentDayName) &&
        !loading &&
        !hasWoundUp
      ) {
        console.log("dayendmodal", "inside the condition");
        setIsShow(true); // Show the modal
        // Update the last shown date
        // Set a timeout to check again in 10 minutes
        setTimeout(checkConditionAndShowModal, 60 * 1000); // 10 minutes in milliseconds
      }
    }
  };

  useEffect(() => {
    // Start the check when the component mounts
    checkConditionAndShowModal();

    // Cleanup function to clear the timeout
    return () => {
      // Clear any pending timeouts if necessary
    };
  }, [userProfile]);

  const handleClose = () => {
    setIsShow(false);
  };

  const handleButtonClick = async () => {
    try {
      const currentDate = new Date().toISOString(); // Get the current date in YYYY-MM-DD format

      const response = await updateDailyLogs({ dayEnd: currentDate }); // Call your API

      if (response.error) {
        throw new Error(response.error?.data?.message); // Handle error response
      }
      if (response) {
        const currentDate = new Date();
        const currentDay = currentDate.toISOString().split("T")[0];
        localStorage.setItem("lastShownEndDate", currentDay);
        toast.success(
          response?.data?.message || "Daily logs updated successfully!"
        ); // Show success toast
        setIsShow(false); // Close the modal
        const res = await ProjectAPI.get(
          `/task/daily-summary?filterType=${"today"}&sendEmail=true`
        );
        await dispatch(userProfilePage());

        localStorage.setItem("hasWoundUpDayEnd", "true");
      }
    } catch (error) {
      toast.error(`${error.message || "Failed to update daily logs."}`); // Show error toast
    }
  };

  return (
    <Modal
      show={isShow}
      className="day-time-start-modal"
      data-theme={isDark}
      centered
    >
      <Modal.Body className="d-flex align-items-center justify-content-center text-center flex-column update-modal-body position-relative">
        <img
          src={DeleteIcon}
          alt="delete-icon"
          className="day-time-end-modal-delete-icon"
          disabled={isLoading}
          onClick={handleClose}
        />
        <div className="del-modal-img-container">
          <img
            src={NoInternetIcon}
            alt="no-internet-icon"
            width={220}
            height={190}
          />
        </div>
        <h5 className="day-time-start-modal-heading mt-4 mb-2 ps-3 pe-3">
          Workday finished, time to wind up!
        </h5>

        <div className="w-100 mt-3 delete-modal-btn-wrapper gap-3 d-flex">
          <button
            disabled={isLoading}
            className="delete-btn-2 rounded-3 flex-1"
            onClick={handleClose}
          >
            Not Now
          </button>
          <button
            onClick={handleButtonClick}
            disabled={isLoading}
            className="delete-btn-1 rounded-3 flex-1"
          >
            Ok, Wind up {isLoading && <Spinner size="sm" />}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default DayTimeEndModal;

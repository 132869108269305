import { getRetryModal } from "../components/RetryModal/RetryContext";

export const retryMiddleware = (store) => (next) => (action) => {
  const showRetryModal = getRetryModal(); // Use context access function

  if (action.type.endsWith("_FAIL") || action.type.endsWith("_FAILURE")) {
    action.meta.retryAction &&
      showRetryModal(() => store.dispatch(action.meta.retryAction)); // Retry the same action
  }

  return next(action);
};

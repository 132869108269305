/* eslint-disable */
/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import API from "../../../services/ClientApi";
import { Spinner } from "react-bootstrap";
import { toast } from "react-toastify";

const ReinviteModal = (props) => {
  const [rload, setRLoad] = useState(false);
  const initialValues = {
    email: props.email,
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
  });

  const handleSubmit = async (values, { setSubmitting, setFieldError }) => {
    // You can perform any additional submission logic here
    // If there are no validation errors

    if (values.email) {
      try {
        setRLoad(true);
        // const checkDuplicate = await API.post(
        //   "company/email-duplication-validator",
        //   { email: values.email }
        // );
        // if (checkDuplicate?.data?.status === "success") {
        const response = await API.post("users/resent-invitation", {
          userId: props.userId,
          emailAddress: values.email,
        });
        if (response?.data?.status === "success") {
          props.getAllUsersAfterUpdate();
          props.handleClose();
          toast.success("Invitation sent successfully", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
          });
        }
        // } else {
        //   setFieldError("email", "Email already exists");
        // }
      } catch (error) {
      } finally {
        setRLoad(false);
      }
    }

    // Set submitting to false to allow the form to be submitted again
    setSubmitting(false);
  };

  return (
    <Modal
      show={props.modal}
      onHide={props.handleClose}
      className="main-update-modal"
      data-theme={props.isDark}
      centered
    >
      <Modal.Header className="update-modal-header" closeButton></Modal.Header>
      <Modal.Body className="update-modal-body">
        <div className="col-md-12 choose-industry-columns ">
          <div>
            <div className="form-section-wrap form-modal-wrap role-modal">
              <h4>
                {" "}
                Re-invite <span style={{ fontWeight: 300 }}>user</span>
              </h4>

              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ values, errors, touched }) => (
                  <Form>
                    <div className="form-group">
                      <label>Email*</label>
                      <Field
                        type="email"
                        name="email"
                        className={`form-control ${
                          touched.email && errors.email ? "is-invalid" : ""
                        }`}
                        placeholder="Add new role"
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>

                    <div className="btn-group-signup">
                      <button
                        type="submit"
                        className="d-flex align-items-center justify-content-center"
                        disabled={rload}
                      >
                        {rload ? <Spinner animation="border" /> : "Invite"}
                      </button>
                    </div>

                    {/* <div className="or-text-signup">
                        <span>OR</span>
                      </div> */}
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ReinviteModal;

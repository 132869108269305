const ProductCard = ({ icon, title, description, isComingSoon }) => {
  return (
    <div className="iffra-plugins-product-card d-flex flex-column ali p-4">
      <div className="flex-1">
        <div className="d-flex align-items-center mb-3">
          <img
            src={icon || "/placeholder.svg"}
            alt=""
            className="iffra-plugins-product-icon me-3"
          />
          <h2 className="iffra-plugins-product-title mb-0">{title}</h2>
        </div>
        <p className="iffra-plugins-product-description mb-4">{description}</p>
      </div>
      <button
        className={`w-100 ${
          isComingSoon
            ? "iffra-plugins-btn-coming-soon"
            : "iffra-plugins-btn-try-free"
        }`}
      >
        {isComingSoon ? "Coming Soon!" : "Try It for Free!"}
      </button>
    </div>
  );
};

export default ProductCard;

/* eslint-disable */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useLayoutEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AddIcon from "../../assets/add.svg";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import API from "../../services/ClientApi";
import { toast } from "react-toastify";
import {
  userInviteeListingPage,
  userListingPage,
} from "../../actions/companyOverviewActions";
import { registeredUserCountAction } from "../../actions/registeredUserCountActions";

const InviteModalMember = (props) => {
  const { userProfile } = useSelector((state) => state?.profile);
  const [load, setLoad] = useState(false);
  const [memberLoad, setMemberLoad] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [memberOptions, setMemberOptions] = useState([]);
  const [departmentData, setDepartmentData] = useState([]);
  const [memL, setMemL] = useState("");
  const [members, setMembers] = useState([]);
  const [lastAddedIndex, setLastAddedIndex] = useState(members.length - 1);
  const [invalidIndices, setInvalidIndices] = useState([]);
  const [duplicateIndices, setDuplicateIndices] = useState([]);
  const [deptIndices, setDeptIndices] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [remainingInviteUsers, setRemainingInviteUsers] = useState(0);

  const handleEmailChange = (index, value) => {
    setInvalidIndices(invalidIndices.filter((item) => item !== index));
    setDuplicateIndices(duplicateIndices.filter((item) => item !== index));
    setMemL("");
    const newMembers = [...members];
    newMembers[index].email = value;
    setMembers(newMembers);
  };

  // Get role name by id
  const getRoleNameById = (id) => {
    const role = memberOptions.find((role) => role.id === id);
    return role ? role.name : null; // Return null if no matching role is found
  };

  const handleRoleChange = (index, value) => {
    const newMembers = [...members];
    newMembers[index].role = value;
    setMembers(newMembers);
    if (getRoleNameById(value) !== "admin") {
      setDeptIndices((prevDeptIndices) => {
        // Check if the index is not already present in invalidIndices
        if (!prevDeptIndices.includes(index)) {
          return [...prevDeptIndices, index];
        }
        return prevDeptIndices; // No change if the index is already present
      });
    } else if (getRoleNameById(value) === "admin") {
      setDeptIndices((prevDeptIndices) => {
        // Remove the index if it exists in the array
        const updatedDeptIndices = prevDeptIndices.filter(
          (deptIndex) => deptIndex !== index
        );
        return updatedDeptIndices;
      });
    }
  };

  const handleAddMember = () => {
    if (remainingInviteUsers > 0) {
      setMembers([
        ...members,
        { email: "", role: memberOptions[0]?.id, department: [] },
      ]);
      setRemainingInviteUsers(remainingInviteUsers - 1);
      setLastAddedIndex(members.length);
    }
  };

  const handleRemoveMember = (index, e) => {
    const newMembers = members.filter((member, i) => i !== index);
    setMembers(newMembers);
    setRemainingInviteUsers(remainingInviteUsers + 1);
  };

  const checkInvalid = () => {
    // Assume all emails are valid initially
    let allEmailsValid = true;

    members.forEach((member, index) => {
      if (member.email !== "") {
        if (!/\S+@\S+\.\S+/.test(member.email)) {
          // Use the functional form of the state updater to ensure the latest state
          setInvalidIndices((prevInvalidIndices) => {
            // Check if the index is not already present in invalidIndices
            if (!prevInvalidIndices.includes(index)) {
              return [...prevInvalidIndices, index];
            }
            return prevInvalidIndices; // No change if the index is already present
          });

          allEmailsValid = false;
        }
      }
    });
    return allEmailsValid;
  };

  const checkDuplicate = async () => {
    let allDepartmentValid = true;
    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token") || ""}`,
      },
    };

    // Use Promise.all to wait for all requests to complete
    await Promise.all(
      members.map(async (member, index) => {
        if (member.email !== "") {
          try {
            await axios.post(
              `${process.env.REACT_APP_BACKEND_API}company/email-duplication-validator`,
              { email: member.email },
              config
            );
          } catch (error) {
            allDepartmentValid = false;
            setDuplicateIndices((prevInvalidIndices) => {
              if (!prevInvalidIndices.includes(index)) {
                return [...prevInvalidIndices, index];
              }
              return prevInvalidIndices;
            });
          }
        }
      })
    );

    return allDepartmentValid;
  };

  const handleInvite = async () => {
    const transformedArray = members.map((item) => ({
      ...item,
      department: item.department.map((dept) => dept.value),
    }));
    // Check if all members have an empty email value
    const isAllEmpty = transformedArray.every((member) => member.email === "");
    const checkEmail = checkInvalid();
    // const checkInvalidDepartment = checkDepartment();
    const checkDup = await checkDuplicate();
    if (isAllEmpty || !checkEmail || !checkDup) {
      setLoad(false);
      if (isAllEmpty) {
        setMemL("Please add at least one team member");
        return; // Prevent further execution
      } else {
        return;
      }
    } else {
      try {
        setLoad(true);
        const config = {
          headers: {
            "Content-type": "application/json",
          },
        };
        const original = transformedArray.filter(
          (obj) => obj.email !== "" && obj.email !== null
        );
        const transformedArrayFinal = original.map((originalObject) => {
          const {
            department: modulesCategoriesIds,
            email: invitedEmailAddress,
            role: invitationRoleId,
            ...rest
          } = originalObject;
          return {
            modulesCategoriesIds,
            invitedEmailAddress,
            invitationRoleId,
            ...rest,
          };
        });
        // Find the Task Management module
        const taskManagementModule = departmentData.find(
          (module) => module.label === "Task Management"
        );

        const updatedMembers = transformedArrayFinal.map((member) => {
          return {
            ...member,
            modulesCategoriesIds: taskManagementModule
              ? [taskManagementModule.value]
              : [],
          };
        });
        const data = await API.post(
          `${process.env.REACT_APP_BACKEND_API}company/user-invitation`,
          updatedMembers,
          config
        );
        if (data === undefined) {
          toast.error("Seat count is filled.", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
          });
        }
        if (data.data.status === "success") {
          dispatch(userListingPage(""));
          dispatch(userInviteeListingPage());
          await dispatch(registeredUserCountAction());
          toast.success("Invitations sent successfully", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
          });
          setMembers([
            { email: "", role: "", department: [] },
            { email: "", role: "", department: [] },
            { email: "", role: "", department: [] },
          ]);
          setDeptIndices([]);
        }
      } catch (error) {
      } finally {
        setLoad(false);
        props.close();
      }
    }
  };

  const getMembers = async () => {
    setMemberLoad(true);
    try {
      const response = await API.get("company/members-roles");
      const user = userProfile;
      if (user?.canInvite === 1) {
        if (!user?.roles?.some((role) => role.roleType === "admin")) {
          const transformedArray = response?.data?.data?.companyRoles?.map(
            (item) => ({
              id: item.roleId,
              name: item.roleName,
              type: item.roleType,
            })
          );
          setMemberOptions(transformedArray);
          const updatedMembers = members.map((member, index) => ({
            ...member,
            role: transformedArray[0]?.id || "", // Assign the role from the response data, or an empty string if not available
            // You can update other properties as needed
          }));
          // Update the state with the modified members array
          setMembers(updatedMembers);
          const responseDept = await API.get("company/modules-categories");
          const transformedDeptArray =
            responseDept?.data?.data?.companyModules?.map((item) => ({
              label: item.categoryName,
              value: item.moduleCategoryId,
            }));
          setDepartmentData(transformedDeptArray);
        } else {
          // navigate(`/${localStorage.getItem("company")}/invite-team-member`);
        }
      } else {
        navigate(`/${localStorage.getItem("company")}/dashboard`);
      }
      const registeredUsersResponse = await API.post(
        "company/registered-user-count"
      );
      if (registeredUsersResponse?.data?.status === "success") {
        const registeredUserCount =
          registeredUsersResponse?.data?.registeredUserCount || 0;
        if (registeredUserCount === user?.packageDetails?.seatCount) {
          props.close();
          props.setShowAddModal(true);
        }
        if (registeredUserCount < user?.packageDetails?.seatCount) {
          let remainingUsers =
            user?.packageDetails?.seatCount - registeredUserCount;

          let updatedLocalMembers = [];
          if (remainingUsers === 1) {
            updatedLocalMembers = [{ email: "", role: "", department: [] }];
            remainingUsers = remainingUsers - 1;
          } else if (remainingUsers === 2) {
            updatedLocalMembers = [
              { email: "", role: "", department: [] },
              { email: "", role: "", department: [] },
            ];
            remainingUsers = remainingUsers - 2;
          } else if (remainingUsers >= 3) {
            updatedLocalMembers = [
              { email: "", role: "", department: [] },
              { email: "", role: "", department: [] },
              { email: "", role: "", department: [] },
            ];
            remainingUsers = remainingUsers - 3;
          }
          const transformedArray = response?.data?.data?.companyRoles?.map(
            (item) => ({
              id: item.roleId,
              name: item.roleName,
              type: item.roleType,
            })
          );
          setMemberOptions(transformedArray);

          const updatedMembers = updatedLocalMembers.map((member, index) => ({
            ...member,
            role: transformedArray[0]?.id || "", // Assign the role from the response data, or an empty string if not available
            // You can update other properties as needed
          }));
          // Update the state with the modified members array
          setMembers(updatedMembers);
          setRemainingInviteUsers(remainingUsers);
        }
      }
      setMemberLoad(false);
    } catch (error) {
      setMemberLoad(false);
    }
  };

  useLayoutEffect(() => {
    if (userProfile) {
      if (!localStorage.getItem("access_token")) {
        const manageDomain = process.env.REACT_APP_AUTH_URL;
        window.location.href = `${manageDomain}/login`; // This reloads the page
      }

      getMembers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userProfile]);

  return (
    <div className="col-md-12 choose-industry-columns">
      <div className="invite-team-wraper invite-app-team-wrapper role-modal">
        {memberLoad ? (
          <div className="w-100 d-flex justify-content-center global-spinner">
            <Spinner animation="border" />
          </div>
        ) : (
          <>
            <h4>
              {" "}
              Invite <span className="invite-modal-span">Members</span>
            </h4>
            <p>Your staff or partners</p>
            {members.map((member, index) => (
              <>
                <div
                  key={index}
                  className={`form-group relative-position member-form-group ${
                    deptIndices.includes(index) ? "no-dept-margin" : ""
                  }`}
                >
                  <input
                    type="email"
                    className={`form-control ${memL ? "err-email" : ""} ${
                      invalidIndices.includes(index) ||
                      duplicateIndices.includes(index)
                        ? "err-email"
                        : ""
                    }`}
                    placeholder="Add email here"
                    value={member.email}
                    onChange={(e) => handleEmailChange(index, e.target.value)}
                    autoComplete="off"
                  />
                  <div
                    className={`select-control invite-select-control ${
                      memL ? "err-select-email" : ""
                    } ${
                      invalidIndices.includes(index) ||
                      duplicateIndices.includes(index)
                        ? "err-select-email"
                        : ""
                    }`}
                  >
                    <select
                      className="invite-role-select"
                      value={member.role}
                      onChange={(e) => handleRoleChange(index, e.target.value)}
                    >
                      {memberOptions?.map((res, id) => (
                        <option key={id} value={res.id}>
                          {res.name.charAt(0).toUpperCase() + res.name.slice(1)}
                        </option>
                      ))}
                    </select>
                  </div>

                  {index === lastAddedIndex && memL && (
                    <div className="text-error">
                      Please enter atleast one email
                    </div>
                  )}
                  {invalidIndices.includes(index) && (
                    <div className="text-error">Invalid email address</div>
                  )}
                  {duplicateIndices.includes(index) && (
                    <div className="text-error">Email already exists</div>
                  )}
                  {index > 2 && (
                    <div
                      className="remove-module"
                      onClick={(e) => handleRemoveMember(index, e)}
                    >
                      -
                    </div>
                  )}
                </div>

                {/* <div className="multi-select-wrapper">
                  <MultiSelect
                    className={`${
                      invalidDepartments.includes(index) ? "dept-err" : ""
                    }`}
                    key="id"
                    options={departmentData}
                    onChange={(e) => handleDepartmentSelect(index, e)}
                    value={members[index].department}
                    isSelectAll={true}
                    menuPlacement={"bottom"}
                  />
                  {invalidDepartments.includes(index) && (
                    <div className="text-error-department">
                      Please select atleast one department
                    </div>
                  )}
                </div> */}
              </>
            ))}
          </>
        )}
        {!memberLoad && (
          <>
            {remainingInviteUsers > 0 ? (
              <div className="d-flex justify-content-between align-items-center buton-invite-wraper">
                <button
                  disabled={remainingInviteUsers === 0}
                  onClick={handleAddMember}
                >
                  {" "}
                  <img src={AddIcon} alt="add" /> Add Another Member{" "}
                </button>
              </div>
            ) : (
              <div className="w-100 invite-warn-msg">
                <p className="m-0 p-0">
                  Note:
                  <span>{` Upgrade your plan for more member to invite.`}</span>
                </p>
              </div>
            )}
            <div className="choose-continue-btn">
              <div className="row">
                <div className="col-md-6"></div>
                <div className="col-md-12">
                  <div className="next-button-wraper invite-btn-margin">
                    <button
                      className="team-invite-button"
                      onClick={handleInvite}
                      disabled={load}
                    >
                      {" "}
                      {load ? (
                        <Spinner animation="border" />
                      ) : (
                        "Invite Your Team"
                      )}{" "}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default InviteModalMember;
